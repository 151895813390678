.convolo-agents-wrapper {
   margin:  2rem;
  .btns.MuiButtonBase-root .mdi{
    padding: 3px;
    border-radius: 100%;
  }
  .btns.MuiButtonBase-root.theme-transparent .MuiButton-label
  {
    color :white ; 

  }

}
