
.client-segmentation-wrapper {
    margin: .25rem;
    max-height: calc(100vh - 150px);
  //  max-height: 100%;
    scrollbar-width: thin;
    overflow: auto;
   // width: 100%;
   border: 1px solid #aeaeb2;
   border-radius: 0.5rem ; 

   .searchButtonPart{
    display: flex ; 
    justify-content: flex-end ; 
    margin: 1rem ; 
   }
  
   
    @include d-flex-column;
    .client-segmentation-item-wrapper {
      @include d-flex;
     /// flex-wrap: wrap;
     // position: relative;
     
     display:  inline-grid ;
     grid-template-columns:  130px 170px 280px 250px 280px 130px 50px; 
      margin-bottom: 0.5rem;
     // box-shadow: 0px .5px 2px #00000029;
      .client-segmentation-section {
        @include d-inline-flex;
      //  justify-content: flex-start;
        margin-bottom: .5rem;
        padding: 0 0.5rem;
        margin-top: .5rem;
      //  width: 14.22%;
        @include phones-max {
          width: 100%;
        }
     .rangeField{
         margin-left: 0.25rem;
         margin-right: 0.25rem;
       }
     
    }
    .client-segmentation-operator-section {
      @include d-inline-flex;
      align-items: center;
      margin-bottom: .5rem;
      margin-top: .5rem;
      padding: 0 0.5rem;
      @include phones-max {
        width: 100%;
      }
    }
  }

  // .table-columns-filter-wrapper {
  //   justify-content: flex-start;

  // }


   .table-Columns-div {
    @include d-flex;
    flex-wrap:nowrap;
    position: relative;
    margin-bottom: 0.5rem;
   .table-Columns-div-section {
      @include d-inline-flex;
     justify-content: flex-start;
      margin-bottom: .5rem;
      padding: 0 0.5rem;
      @include phones-max {
        width: 100%;
      }
   
  }
  }
  }

  .filterByDate{
      margin: .5rem;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;

      
  .CenterDate {
    display: flex;
    align-items: center;
    justify-content: center;
    .datePickerReprt {
      .date-range-picker-component .display-wrapper {
        display: flex;
        flex: 1 1 100%;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0.25rem 0.5rem;
        min-width: 100px;
        border-radius: 0rem;
        border: aliceblue !important;
      }}
  }
  }

 .excelPart{
    margin: .5rem;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
      .excelImage1 {
        min-width: 20px;
        height: 37px;
        width: 37px;
        border: 1px solid #dedede;
        border-radius: 100%;
        padding: 0.5rem;
        background-color:white ;
    
      }
  }

  .searchTable{
    margin: .5rem;
    overflow: auto;
    margin: 1rem;
    scrollbar-width: thin;
    overflow-y: auto;
    max-height: 100vh;
  }

  


  