.buttonbase{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.transaction-contact-update-dialog, .DialogManagementViewComponent-wrapper{ 
    .input-fieldset {
        border: 1px solid #ededed;
        border-radius: 8px;
        min-height: 38px;
        padding: 10px;
    }    
    .input-fieldset-legend {
        width: fit-content;
        margin: 0;
        padding: 0px 4px;
        font-size: 12px;
        line-height: inherit; 
        border: 0; 
        color: #000000;
    }

    .GalleryComponent{
        padding: 1rem;
    }
}
.buttons-part
{
    width: 100%;
    display: flex;
    justify-content: flex-start;



}

