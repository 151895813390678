.leads-cards-wrapper {
  @include d-flex-h-center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 0.5rem;
  position: relative;

  .blue {
    background: var(--c-primary);
  }
  .blue:after {
    border-color: transparent var(--c-primary);
  }
  .bulk-ribbon {
    position: absolute;
    cursor: pointer;
    z-index: 2;
    padding: 0px 4px;
    margin-right: 2.4%;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    font-size: $fz-10px;
    line-height: 18px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    zoom: 1;
    top: 3.3%;
    right: -5%;
    min-width: 10%;
    height: 18px;
  }
  .blue {
    background: #3b72d9;
  }
  .blue:after {
    border-color: transparent #214280;
  }
  .bulk-ribbon:after {
    content: '';
      position: absolute;
      z-index: -20;
      top: 100%;
      left: auto;
      border-style: solid;
      border-width: 0 0 10px 10px;
      right: 0;
  }
  .leads-card-wrapper {
    .card-checkbox-wrapper {
      // background-color: red;
      top: 1.1rem;
      right: 0.6rem
    }
    .cards-wrapper {
      @include d-flex-column;
      position: relative;
      border: 1px solid $c-gray-secondary;
      justify-content: space-between;
      border-radius: 1.1rem;
      color: $c-black;
      .cards-header-wrapper {
        @include d-flex-v-center;
        padding: 0.5rem 0.5rem 0;
      }
      .cards-body-wrapper {
        @include d-flex;
        width: 100%;
        padding: 0.5rem;
        .cover-image {
          background-color: $c-gray-lighter;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          height: 50px;
          min-height: 50px;
          width: 50px;
          border-radius: 0.5rem;
          margin: 0 0 0.5rem;
        }
        .body-title-wrapper {
          @include d-flex-column;
          padding: 0.7rem 0.5rem 0.5rem;
          width: 100%;
          .body-title {
            color: $c-black;
            font-size: $fz-16px;
            font-weight: $fw-simi-bold;
            max-width: 12rem;
            max-height: 40px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .lead-id {
            display: flex;
            justify-content: space-between;
            .id {
              // margin: auto;
              textarea {
                width: 0;
                height: 0;
                padding: 0;
                opacity: 0;
              }
              .mdi::before {
                margin-left: 0.5rem;
                margin-right: 0.5rem;
                font-size: $fz-14px;
                cursor: pointer;
                color: $c-primary;
              }
            }
          }
        }
        .body-card-tooltip-wrapper {
          @include d-flex-v-center;
          // flex-wrap: wrap;
        }
        .flat-contents-wrapper {
          @include d-flex-v-center;
          width: 100%;
          flex-wrap: wrap;
          .flat-content-item {
            @include d-inline-flex-v-center;
            margin: 0 0.25rem 0.5rem;
            padding: 0.1rem 0.5rem;
            border-radius: 1rem;
            background-color: $c-gray-lighter;
            color: $c-black;
          }
        }
        .actions-wrapper {
          @include d-flex-column-v-center;
          height: 100%;
          width: 100%;
          padding: 0.5rem 0.5rem 0;
          .btns-icon {
            margin-bottom: 1rem;
          }
        }
        .card-body-section:first-child {
          @include d-flex-column-v-center;
          min-width: 50px;
          max-width: 60px;
        }
        .card-body-section:nth-child(2) {
          // @include d-flex-column;
          width: 100%;
          .details-item-wrapper {
            @include d-flex;
            width: 100%;
            padding: 0.5rem 0;
            margin-bottom: 0.1rem;
            min-width: 200px;
            max-height: 35px;
            overflow: hidden;
            text-overflow: ellipsis;
            align-self: flex-start;
          }
          .details-item {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            @supports (display: -webkit-box) {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
          .details-text-style {
            overflow: hidden;
            width: 250px;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: $fw-bold;
          }
          .details-text {
            padding: 0.15rem;
            // white-space: nowrap;
            white-space: break-spaces;
            textarea {
              resize: none;
            }
          }
          .details-icon {
            vertical-align: bottom;
          }
          .details-icon,
          .details-text {
            font-weight: $fw-bold;
            white-space: break-spaces;
          }
          &.is-expanded {
            flex-direction: row;
            flex-wrap: wrap;
            .body-item-wrapper,
            .details-item-wrapper {
              width: 33.3333%;
              max-width: 33.3333%;
              @include phones-max {
                width: 100%;
                max-width: 100%;
              }
            }
          }
        }
        .body-item-wrapper {
          @include d-flex-v-center;
          padding: 0 0.5rem;
          margin-bottom: 0.1rem;
        }
      }
      .cards-progress-wrapper {
        width: 100%;
      }
      .cards-footer-wrapper {
        @include d-flex-v-center;
        flex: 1 1 auto;
        overflow: hidden;
        // border-radius: 0 0 1.1rem 1.1rem;
        width: 100%;
        min-height: 40px;
        padding: 0;
        border-top: 1px solid $c-gray-secondary;
        .edit-power-icon {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          @include rtl {
            left: 0;
            right: initial;
          }
        }
        .btns {
          position: relative;
          border-radius: 0;
          height: 100%;
          min-height: 40px;
          min-width: initial;
          color: $c-black;
          width: 100%;
          // max-width: 49.3333%;
          .mdi:before {
            font-size: $fz-18px;
          }
          &:not(:last-child) {
            border-right: 1px solid $c-gray-secondary;
            @include rtl {
              border-left: 1px solid $c-gray-secondary;
              border-right: none;
            }
          }
        }
      }
      .cards-footer-wrapper {
        // @include d-flex-v-center-h-between;
        flex: 1 1 auto;
        overflow: hidden;
        display: flex;
        justify-content: flex-end;
        border-radius: 0 0 1.1rem 1.1rem;
        width: 100%;
        min-height: 40px;
        padding: 0.2rem 0;
        border-top: 1px solid $c-gray-secondary;
        .footer-item {
          display: flex;
          justify-content: flex-end;
          padding: 0 0.5rem;
          .view-more {
            color: var(--c-primary);
          }
        }
      }
    }
    &.is-open {
      .cards-wrapper {
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.25);
        color: $c-secondary;
        .cards-footer-wrapper {
          .btns {
            color: $c-secondary;
          }
        }
      }
    }
    position: relative;
    @include d-inline-flex-column;
    padding: 0 0.5rem;
    min-width: 355px;
    @include phones-max {
      min-width: 290px;
    }
    margin-bottom: 1rem;
    width: 20%;
    max-width: 380px;
    @include laptops-xl-max {
      width: 25%;
    }
    @include laptops-l-max {
      width: 33.3333%;
    }

    @include laptops-max {
      width: 50%;
    }

    @include phones-max {
      width: 100%;
      max-width: initial;
    }
    &.is-expanded {
      width: 50%;
      max-width: initial;
      // @include laptops-xl-max {
      //   width: 33.3333%;
      // }

      // @include laptops-l-max {
      //   width: 50%;
      // }

      @include laptops-max {
        width: 100%;
      }
    }
  }
  .hide-textarea {
    textarea {
      width: 0;
      height: 0;
      padding: 0;
      opacity: 0;
    }
    .mdi::before {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      font-size: $fz-14px;
      cursor: pointer;
      color: $c-primary;
    }
  }
}
.Clone-bbt {
  .MuiFab-sizeSmall {
    width: 28px !important;
    height: 28px !important;
  }
  .MuiFab-root {
    min-height: 20px !important;
    box-shadow: none;
  }
}
