.property-rating-view {
  background-color: #fcfcfd;
  height: 100%;

  .fz-30 {
    font-size: 30px;
  }

  .b-bottom {
    border-bottom: 1px solid #eaecf0;
  }

  .text-primary {
    color: #101828;
  }

  .property-rating-container {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    margin: 0.5rem;
    border-radius: 12px;
    .rating-percentage-view {
      .rating-percentage-section {
        display: grid;
        grid-template-columns: 30% auto;
        color: #344054;
        line-height: 1.25rem;
        padding: 0.5rem 0;
      }
    }
    .geographic-criteria-view {
      .geographic-criteria-section {
        display: grid;
        grid-template-columns: 15% 85%;
        gap: 3.125rem;
        font-size: 0.875 rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.25 rem;
        .location-type {
          display: inline-flex;
          justify-content: flex-start;
          .autocomplete-wrapper {
            max-width: 130px;
            min-width: 120px;
          }
          @include laptops-l-max {
            display: flex;
            flex-direction: column;
            .autocomplete-wrapper {
              min-width: 270px;
            }
          }
          @include laptops-max {
            display: flex;
            flex-direction: column;
            .autocomplete-wrapper {
              min-width: 270px;
            }
          }
          @include tablets-max {
            display: flex;
            flex-direction: column;
            .autocomplete-wrapper {
              min-width: 270px;
            }
          }
          @include phones-s-max {
            display: flex;
            flex-direction: column;
            .autocomplete-wrapper {
              min-width: 270px;
            }
          }
        }
        @include laptops-l-max {
          display: flex;
          flex-direction: column;
          .autocomplete-wrapper {
            min-width: 270px;
          }
        }

        @include laptops-max {
          display: flex;
          flex-direction: column;
          .autocomplete-wrapper {
            min-width: 270px;
          }
        }
        @include tablets-max {
          display: flex;
          flex-direction: column;
          .autocomplete-wrapper {
            min-width: 270px;
          }
        }
        @include phones-max {
          display: flex;
          flex-direction: column;
          .autocomplete-wrapper {
            min-width: 270px;
          }
        }
        .sores-part {
          overflow-y: auto;
          overflow-x: hidden;
          top: 0;
          padding: 0 .5rem;
          .city {
            max-height: auto;
          }
          .district {
            max-height: 350px;
          }
          .community {
            max-height: 400px;
          }
          .sub-community {
            max-height: 400px;
          }
          .search-filter {
            width: 60%;
            display: grid;
            grid-template-columns: 37% auto;
          }
          .section {
            width: 75%;
            display: grid;
            grid-template-columns: 30% 70%;
            padding: 0.5rem;
            .address {
              display: inline-flex;
              align-items: center;
            }
          }
        }
      }
    }
    .key-nearby-view {
      .radios {
        .MuiSlider-root {
          color: #7f6944;
        }
      }
      .filter-funnel {
        position: relative;
        width: 20px;
        height: 20px;
        line-height: 0;
        display: flex;
        padding: 10px 14px;
        justify-content: center;
        align-items: center;
        gap: var(--spacing-xs, 4px);
      }

      .key-nearby-section {
        display: grid;
        grid-template-columns: 15% 85%;
        gap: 3.125rem;
        color: var(--colors-text-text-secondary-700, #344054);
        font-size: 0.875 rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.25 rem;

        .key-nearby-landmarks {
          // overflow-y: auto;
          // overflow-x: hidden;
          // max-height: 700px;
          padding: 1rem;
          .first-section {
            display: grid;
            grid-template-columns: 95% 5%;
          }

          ::-webkit-scrollbar {
            background: darkgrey;
            border: 0px none #ffffff;
            border-radius: 10rem;
          }

          .key-nearby-number {
            display: grid;
            grid-template-columns: 45% 10% 45%;
            .arrow-part {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }

    .developer-view {
      .developer-section {
        display: grid;
        grid-template-columns: 15% 85%;
        gap: 3.125rem;
        color: var(--colors-text-text-secondary-700, #344054);
        font-size: 0.875 rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.25 rem;
        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
        }
        .filter-section {
          display: grid;
          grid-template-columns: 50% 50%;
          gap: 3.125rem;
          color: var(--colors-text-text-secondary-700, #344054);
          font-size: 0.875 rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.25 rem;
          .filter-button {
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
        }
        .developers {
          overflow-y: auto;
          overflow-x: hidden;
          max-height: 1200px;
        }
        .developer-rating {
          display: grid;
          grid-template-columns: 35% 65%;
          //gap: 3.125rem;
          padding: 0.5rem;
          color: var(--colors-text-text-secondary-700, #344054);
          font-size: 0.875 rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.25 rem;
          @include laptops-max {
            display: grid;
            grid-template-columns: 10% auto;
          }
          @include tablets-max {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          @include phones-s-max {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          @include phones-max {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }
    .general-property-criteria {
      .general-property-section {
        display: grid;
        grid-template-columns: 15% auto;
        gap: 1rem;
        color: var(--colors-text-text-secondary-700, #344054);
        font-size: 0.875 rem;
        font-style: normal;
        font-weight: 600;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 200px;
        padding: 0 1rem;

        .general-property-card {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          gap: 1rem;
          padding: 0.8rem;
          @include laptops-xl-max {
          }

          @include laptops-l-max {
            display: flex;
            flex-direction: row;
          }

          @include laptops-max {
            display: flex;
            flex-direction: column;
          }
          @include tablets-max {
            display: flex;
            flex-direction: column;
          }
          @include phones-s-max {
            display: flex;
            flex-direction: column;
          }
          @include laptops-l-max {
            display: flex;
            flex-direction: column;
          }

          @include laptops-max {
            display: flex;
            flex-direction: column;
          }
          @include tablets-max {
            display: flex;
            flex-direction: column;
            padding: 0 1rem;
          }
          @include phones-max {
            display: flex;
            flex-direction: column;
            padding: 0 1rem;
          }
        }
      }
      .from-to-part {
        //display: grid;
        //grid-template-columns: 45%  10% 45% ;
        display: inline-flex;
        flex-direction: row;
        gap: 1rem;

        .arrow-part {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .tower-units-finishing-view {
      .tower-units-finishing-section {
        display: grid;
        width: 70%;
        grid-template-columns: 15% auto;
        gap: 1rem;
        .units-finishing {
          overflow-y: auto;
          overflow-x: hidden;
          max-height: 230px;
          padding: 0 1rem;
          .from-to-part {
            display: inline-flex;
            flex-direction: row;
            gap: 1rem;

            .arrow-part {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .tower-units-finishing-card {
            gap: 1rem;
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 150px;
            padding: 1rem;
            display: grid;
            text-wrap: nowrap;

            grid-template-columns: 30% 70%;
            @include laptops-l-max {
              display: flex;
              flex-direction: column;
            }

            @include laptops-max {
              display: flex;
              flex-direction: column;
            }
            @include tablets-max {
              display: flex;
              flex-direction: column;
            }
            @include phones-s-max {
              display: flex;
              flex-direction: column;
            }
            @include laptops-l-max {
              display: flex;
              flex-direction: column;
            }

            @include laptops-max {
              display: flex;
              flex-direction: column;
            }
            @include tablets-max {
              display: flex;
              flex-direction: column;
              padding: 0 1rem;
            }
            @include phones-max {
              display: flex;
              flex-direction: column;
              padding: 0 1rem;
            }
          }
        }
      }
    }
    .parking-distribution-view {
      .parking-distribution-section {
        display: grid;
        width: 70%;
        grid-template-columns: 20% auto;
        gap: 1rem;
        @include laptops-xl-max {
        }

        @include laptops-l-max {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
        }

        @include laptops-max {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
        }
        @include tablets-max {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
        }
        @include phones-s-max {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
        }
        @include laptops-l-max {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
        }

        @include laptops-max {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
        }
        @include tablets-max {
          display: flex;
          flex-direction: column;
          padding: 0 1rem;
          align-items: center;
          width: 100%;
        }
        @include phones-max {
          display: flex;
          flex-direction: column;
          padding: 0 1rem;
          align-items: center;
          width: 100%;
        }
        .parking-distribution {
          overflow-y: auto;
          overflow-x: hidden;
          max-height: 230px;
          padding: 0 1rem;

          .parking-distribution-card {
            gap: 1rem;
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 150px;
            padding: 1rem;
            display: grid;
            grid-template-columns: 10% 90%;
            @include laptops-l-max {
              display: flex;
              flex-direction: row;
            }

            @include laptops-max {
              display: flex;
              flex-direction: column;
            }
            @include tablets-max {
              display: flex;
              flex-direction: column;
            }
            @include phones-s-max {
              display: flex;
              flex-direction: column;
            }
            @include laptops-l-max {
              display: flex;
              flex-direction: column;
            }

            @include laptops-max {
              display: flex;
              flex-direction: column;
            }
            @include tablets-max {
              display: flex;
              flex-direction: column;
              padding: 0 1rem;
            }
            @include phones-max {
              display: flex;
              flex-direction: column;
              padding: 0 1rem;
            }
          }
        }
      }
    }

    .property-views-view {
      .property-views-view-section {
        display: grid;
        grid-template-columns: 15% auto;
        gap: 1rem;
        .property-views {
          padding: 0 1rem;
          overflow-y: auto;
          overflow-x: hidden;
          max-height: 500px;

          .property-views-item {
            border-bottom: 1px solid #eaecf0;
            padding: 0.25rem 1rem;
            display: grid;
            grid-template-columns: 30% 70%;
            .property-views-name {
              border-right: 1px solid #eaecf0;
              height: 50px;
            }

            @include laptops-l-max {
              display: flex;
              flex-direction: column;
              .property-views-name {
                border-right: none;
              }
            }

            @include laptops-max {
              display: flex;
              flex-direction: column;
              .property-views-name {
                border-right: none;
              }
            }
            @include tablets-max {
              display: flex;
              flex-direction: column;
              .property-views-name {
                border-right: none;
              }
            }
            @include phones-s-max {
              display: flex;
              flex-direction: column;
              .property-views-name {
                border-right: none;
              }
            }
            @include laptops-l-max {
              display: flex;
              flex-direction: column;
              .property-views-name {
                border-right: none;
              }
            }

            @include laptops-max {
              display: flex;
              flex-direction: column;
              .property-views-name {
                border-right: none;
              }
            }
            @include tablets-max {
              display: flex;
              flex-direction: column;
              padding: 0 1rem;
              .property-views-name {
                border-right: none;
              }
            }
            @include phones-max {
              display: flex;
              flex-direction: column;
              padding: 0 1rem;
              .property-views-name {
                border-right: none;
              }
            }
          }
        }
      }
    }
    .facilities-amenities-criteria {
      .facilities-amenities-section {
        display: grid;
        grid-template-columns: 15% auto;
        gap: 1rem;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 300px;
        padding: 0 1rem;
        .facilities-amenities-card {
          display: grid;
          grid-template-columns: 30% 70%;
          @include laptops-l-max {
            display: flex;
            flex-direction: column;
          }

          @include laptops-max {
            display: flex;
            flex-direction: column;
          }
          @include tablets-max {
            display: flex;
            flex-direction: column;
          }
          @include phones-s-max {
            display: flex;
            flex-direction: column;
          }
          @include laptops-l-max {
            display: flex;
            flex-direction: column;
          }

          @include laptops-max {
            display: flex;
            flex-direction: column;
          }
          @include tablets-max {
            display: flex;
            flex-direction: column;
            padding: 0 1rem;
          }
          @include phones-max {
            display: flex;
            flex-direction: column;
            padding: 0 1rem;
          }
        }
      }
    }
    .life-style-criteria {
      .life-style-section {
        display: grid;
        grid-template-columns: 15% auto;
        gap: 1rem;
        color: var(--colors-text-text-secondary-700, #344054);
        font-size: 0.875 rem;
        font-style: normal;
        font-weight: 600;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 200px;
        padding: 1rem 1rem;

        .life-style-card {
          display: grid;
          padding: 1rem 1rem;
          margin: 1rem;
          grid-template-columns: 30% 70%;
          @include laptops-l-max {
            display: flex;
            flex-direction: column;
          }

          @include laptops-max {
            display: flex;
            flex-direction: column;
          }
          @include tablets-max {
            display: flex;
            flex-direction: column;
          }
          @include phones-s-max {
            display: flex;
            flex-direction: column;
          }
          @include laptops-l-max {
            display: flex;
            flex-direction: column;
          }

          @include laptops-max {
            display: flex;
            flex-direction: column;
          }
          @include tablets-max {
            display: flex;
            flex-direction: column;
            padding: 0 1rem;
          }
          @include phones-max {
            display: flex;
            flex-direction: column;
            padding: 0 1rem;
          }
        }
      }
    }
    .surroundings-criteria {
      .surroundings-section {
        display: grid;
        grid-template-columns: 15% auto;
        padding: 1rem 1rem;
        .sub-title {
          color: #475467;
        }
        .surroundings-card {
          display: grid;
          padding: 1rem 1rem;
          margin: 0.5rem;
          grid-template-columns: 30% 70%;
          @include laptops-l-max {
            display: flex;
            flex-direction: column;
          }

          @include laptops-max {
            display: flex;
            flex-direction: column;
          }
          @include tablets-max {
            display: flex;
            flex-direction: column;
          }
          @include phones-s-max {
            display: flex;
            flex-direction: column;
          }
          @include laptops-l-max {
            display: flex;
            flex-direction: column;
          }

          @include laptops-max {
            display: flex;
            flex-direction: column;
          }
          @include tablets-max {
            display: flex;
            flex-direction: column;
            padding: 0 1rem;
          }
          @include phones-max {
            display: flex;
            flex-direction: column;
            padding: 0 1rem;
          }
        }
      }
    }
  }
  .checkbox-groups-wrapper .i-unchecked {
    height: 16px;
    width: 16px;
    border: 1px solid #d0d5dd !important;
  }

  .customDatePicker {
    .description-text,
    .mdi-chevron-down,
    .c-secondary {
      color: #7f6944 !important;
    }
  }
}
.add-task-dialog {
  .fw-simi-bold {
    font-weight: 600;
  }
  .form-builder-wrapper .stepper-wrapper {
    display: none;
  }

  .form-builder-item-wrapper {
    &.MuiGrid-grid-xl-4 {
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  .font-b {
    font-weight: 600;
  }

  .reminder-controls {
    width: fit-content;
    display: flex;
    margin-right: 30px;
  }

  .pointer {
    cursor: pointer;
  }

  .dialog-title-icon,
  &.dialog-title-icon {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .dialog-content,
  &.dialog-content {
    max-height: 50vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }

  .xCloseIcon img {
    width: 24px;
    height: 24px;
  }

  &.task-details {
    .container-frame {
      border-radius: 12px;
      border: 1px solid #eaecf0;
      background: #fff;
      padding: 15px;
    }

    .details-container {
      display: grid;
      grid-template-columns: repeat(3, 160px);
      column-gap: 1rem;

      .details-title {
        color: #475467;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 0px;
      }

      .details-value {
        color: #344054;
        font-size: 14px;
        font-weight: 600;
      }

      .details-value,
      .details-title {
        max-width: 140px;
        overflow-wrap: break-word;
      }
    }
  }
}
