

.filter-section-wrapper {
    margin: 16px 0;
    padding: 0 16px;
  }
  
  .filter-section-title {
    font-weight: bold;
    margin-bottom: 8px;
    font-size: 14px;
    color: #333;
  }
  
  .values-row {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .radio-item-filter {
    background-color: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #D0D5DD;
    color: #667085;
    font-size: 14px;
    text-align: center;
    padding: 8px 12px;
    min-width: 36px;
    min-height: 36px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  
    &.selected {
      background-color: #84693f;
      color: #fff;
      border-color: #84693f;
    }
  }
 
  
