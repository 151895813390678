.parameter-wrapper {
  margin: 15px;

  legend {
    width: fit-content;
    margin: 0;
    padding: 0px 4px;
    font-size: $fz-default;
    line-height: inherit;
    border: 0;
    color: $c-black;
  }

  .parameter-fieldset {
    display: flex;
    border: 1px solid #ededed;
    border-radius: 8px;
    min-height: 38px;
    padding: 10px;
    margin: auto;
    align-items: center;
  }

  fieldset {
    margin: 0px 10px;
  }

  .parameters-button-wrapper {
    border-left: 1px solid #dddddd;
    display: flex;
    flex-direction: column-reverse;
    padding: 10px;
    border-radius: 5px;
  }
}
