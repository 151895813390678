.release-note-managament-form {
    .uplode-ved {
        .custom-file-upload {
          display: inline-block;
          padding: 10px 15px;
          cursor: pointer;
          background-color: #3498db;
          color: #fff;
          border: none;
          border-radius: 5px;
          transition: background-color 0.3s ease;
        }
    
        .custom-file-upload:hover {
          background-color: #2980b9;
        }
    
        .custom-file-upload span {
          font-size: 16px;
        }
      }
    display: grid;
    grid-template-rows: 1fr;
    height: inherit;

    .product-form-container {
        padding: 1rem;
        border: 1px solid #D0D5DD;
        border-radius: 8px;
        margin-top: 2rem;
        margin-bottom: 3rem;

        .form-two-inputs {
            display: grid;
            grid-template-columns: 1fr 2fr;
            grid-column-gap: 1.5rem;
        }

        .form-three-inputs {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 1.5rem;
        }

        

        .date-picker-wrapper .MuiInputBase-root {
            max-height: 30px;
            min-height: 30px;
        }
    }

    .dragged-release-note {
        background: #e1744e !important;
        color: white !important;
        margin: 5px 0px;
        border-radius: 3px;
        box-shadow: 0px 0px 5px 0px #a1a1a1bf;
    }

    .disabled-note-item{
        color: #a7a4a4;
    }
    button {
        padding: 10px 18px;
        margin-right: 1rem;
        margin-left: 1rem;
        width: 89px;
        height: 44px;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        font-weight: 600;
        font-size:  $fz-16px;
        line-height: 24px;
        cursor: pointer;
    }

    .form-btns {
        width: fit-content;
        margin: auto;
    }
    .form-btns {
        width: fit-content;
        margin: auto;
    }

    .main-form-btns {
        display: flex;
        justify-content: flex-end;
    }

    button.primary-btn {
        background: #E0592A;
        border: 1px solid #E0592A;
        color: #FFFFFF;
        transition: .5s;

        &:hover {
            background: #CA5026;
            border: 1px solid #CA5026;
        }
    }

    button.outlined-btn {
        background: #FFFFFF;
        border: 1px solid #D0D5DD;
        color: #344054;
    }


    .title {
        font-weight: 600;
        font-size:  $fz-18px;
        line-height: 28px;
    }



    .note-type-wrapper {
        margin-bottom: 2rem;
        cursor: pointer;


        .note-type-title-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .note-type-title-wrapper .switch-control-wrapper {
            display: block;
            flex: unset;
            width: unset;
        }

        .note-type-title-wrapper .MuiSwitch-thumb,
        .Mui-checked .MuiSwitch-thumb,
        .Mui-checked+.MuiSwitch-track {
            background-color: #E0592A !important;
        }

        .added-notes-container {
            margin-top: 1rem;
            margin-bottom: 1rem;

            .active-note{
                background: #efefef;
                border-bottom: 3px solid #e0592a;
                transform: translateX(8px);
            }
        }

        .note-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.5rem;
            border-bottom: 3px solid #cfcfcf;
            margin-bottom: 4px;
            box-shadow: 0px 0px 5px 0px rgba(231, 231, 231, 0.75);
            border-radius: 5px;
            background: #F9FAFB;
            font-weight: 400;
            font-size: $fz-14px;
            line-height: 20px;
            color: #475467;
            transition: transform .2s;
        }

        .note-item:hover{
            transform: scale(1.01);
        }

        .note-item .mdi::before {
            color: #E0592A;
            cursor: pointer;
        }

        .btns-container {
            display: flex;
            justify-content: space-between;
            margin-top: 2rem;
            margin-bottom: 2rem;
        }

        .btns-container .release-btn {
            display: flex;
            align-items: center;
            font-size: $fz-14px;
            font-weight: 600;
            line-height: 20px;
            color: #475467;
            cursor: pointer;

            .mdi::before {
                margin-right: .5rem;
                font-size: $fz-22px;
            }
        }
    }
}


.release-note-sunEditor {

    
    .sun-editor {
        border: none;
        margin-bottom: 1rem;
        margin-top: 1rem;
        

    .se-toolbar {
        background: none;
    }

    .se-wrapper {
        display: grid;
        grid-template-columns: 100%;
    }

    .se-btn:enabled.active {
        color: #E0592A;
        outline: 0 none;
        background: #F5CCBD;
    }

    .se-wrapper-inner {
        height: 180px;
        padding: 12px 14px;
        border: 1px solid #D0D5DD;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
    }

    .se-resizing-bar {
        display: none;
    }

    .se-toolbar-sticky {
        position: static !important;
    }

    .se-toolbar-sticky-dummy {
        display: none !important;
    }
}

.sun-editor, .sun-editor-editable{
    font-family: Poppins, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}
}

.release-confirm-text{
    visibility: hidden;
}


.release-note-sunEditor.accordion-sunEditor {
    .se-controller, .se-line-breaker, .se-resizing-back,
    .se-toolbar, .se-line-breaker-component, 
    .se-resizing-bar, .se-dialog, .se-toolbar-sticky-dummy {
        display: none !important;
    }
    .sun-editor .se-wrapper-inner{
     border: none;
     padding: 0px;
    }
}
