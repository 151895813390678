.unit-Uploader-wrapper {
  .unit-Uploader {
    height: 210px;
    text-align: center;
    border: 1pt dashed;
    border: 1pt dashed #c7c7cc;
    span {
      display: grid;
      margin: 20px;
    }
    .inputfile {
      display: none;
    }
    .inputButton1 {
      margin: auto;
      width: 130px;
      min-height: 32px;
      height: 32px;
      background-color: var(--c-primary);
      border-radius: 1rem;
      color: white;
      font-size: $fz-default;
      white-space: nowrap;
    }
    .MuiButton-root:hover {
      background-color: var(--c-primary);
    }
  }
}
