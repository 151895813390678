.Transaction-Configuration-view-wrapper-add {
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 0;
  }
  width: fit-content;
  margin: 2rem;
  .remove-c {
    margin: 21px;
    padding-bottom: 22px;
    font-size: 14px;
    font-weight: 900;
    color: #d9403b;
    border-bottom: 1px solid #c0abab;
  }
  .add-c {
    margin: 21px;
    font-size: 14px;
    font-weight: 900;
    color: var(--c-primary);
  }
  .box-v-container {
    max-height: 17rem;
    overflow: auto;
    overflow-x: hidden;
    margin-top: 26px;
  }
  .box-title {
    margin: 1rem;
    font-size: 14px;
    font-weight: 600;
  }
  .section-1 {
    border: 1px solid #e6dddd99;
    padding: 19px 19px 39px 19px;
    border-radius: 16px;
    margin-top: 2rem;
  }
  .Remove-bbt {
    color: red;
    .MuiButtonBase-root {
      border-radius: 18px;
    }
  }
  .email-wer {
    background-color: #ebebeb8c;
    padding: 7px 15px 11px 12px;
    border-radius: 6px;
    margin: 5px 4px 9px 3px;
  }
  .item-input {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .item-input-wraper {
    margin-bottom: 3rem;
  }

  .bulk-header-section {
    font-size: 20px;
    font-weight: 800;
  }
  .bulk-header--sub {
    font-size: 16px;
    font-weight: 800;
    margin-top: 2rem;
  }
  .select-text {
    font-size: 13px;
    color: #797878;
    padding: 9px;
  }

  .filter-section {
    display: inline-flex;
    @include laptops-l-max {
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
    grid-gap: 20px;
    gap: 9;
  }
}

.Transaction-Configuration-view-wrapper {
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 0;
  }
  @include d-flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  .section-seperator {
    &.mdi::before {
      @include rtl {
        transform: rotate(180deg);
      }
      position: absolute;
      top: 15%;
      font-size: $fz-24px;
    }
  }
  .bulk-section-wrapper {
    @include d-flex-column;
    @include d-inline-flex-column;
    min-width: 355px;
    width: 33%;
    max-width: 380px;
    @include laptops-xl-max {
      width: 25%;
    }
    @include laptops-l-max {
      width: 33.3333%;
    }
    @include laptops-max {
      width: 50%;
    }
    @include tablets-max {
      width: 100%;
    }
    @include phones-max {
      width: 100%;
      max-width: initial;
    }
    .bulk-header-section {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: $fz-18px;
      font-weight: 400;
    }
    .bulk-sub-header-section {
      color: $c-gray;
      font-size: $fz-14px;
    }
    .bulked-units-section {
      overflow-y: auto;
      height: calc(100vh - 270px);
      padding-right: 1rem;
      @include rtl {
        padding-right: 0;
        padding-left: 1rem;
      }
      .body-item-wrapper {
        display: flex;
      }
      .cover-image {
        background-color: $c-gray-lighter;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 3.9rem;
        width: 105px;
        border-radius: 0.5rem;
      }
      .bulked-unit-item {
        cursor: pointer;
        overflow-y: auto;
        display: flex;
        position: relative;
        padding: 0.5rem;
        width: 100%;
        height: 5rem;
        background-color: $c-white;
        border-radius: 10px;
        border: 1px solid #4972d2;
        &:hover {
          background-color: #f6f6f6;
        }
        &.is-search {
          border: 2px solid $c-primary;
          background-color: $c-secondary-light;
        }
        .item-name {
          font-weight: bold;
        }
        .item-ref-no {
          font-size: $fz-14px;
          font-weight: bold;
        }
      }
      .success-bulked-unit-item {
        cursor: default;
        overflow-y: auto;
        display: flex;
        position: relative;
        padding: 0.5rem;
        width: 100%;
        height: 5rem;
        background-color: $c-green-light;
        border-radius: 10px;
        border: 2px solid $c-green-dark;
        &:hover {
          background-color: $c-green-light;
        }
        .checkbox-groups-wrapper.theme-default .checkbox-wrapper.Mui-checked .mdi,
        .checkbox-groups-wrapper.theme-default .checkbox-wrapper.MuiCheckbox-indeterminate .mdi {
          background-color: $c-gray-light;
          color: $c-black;
        }
        &.is-search {
          border: 2px solid $c-primary;
          background-color: $c-secondary-light;
        }
        .item-name {
          font-weight: bold;
        }
        .item-ref-no {
          font-size: $fz-14px;
          font-weight: bold;
        }
      }
      .failed-bulked-unit-item {
        cursor: pointer;
        overflow-y: auto;
        display: flex;
        position: relative;
        padding: 0.5rem;
        width: 100%;
        height: 5rem;
        background-color: $c-warning-light;
        border-radius: 10px;
        border: 2px solid $c-warning;
        &.is-search {
          border: 2px solid $c-primary;
          background-color: $c-secondary-light;
        }
        .item-name {
          font-weight: bold;
        }
        .item-ref-no {
          font-size: $fz-14px;
          font-weight: bold;
        }
      }
      .unit-fields-item {
        cursor: pointer;
        overflow-y: auto;
        display: flex;
        position: relative;
        padding: 0.5rem;
        width: 100%;
        height: 3rem;
        display: flex;
        justify-content: center;
        background-color: #efefef;
        border-radius: 10px;
        text-align: center;
        &:hover {
          background-color: #f6f6f6;
        }
        &.is-search {
          border: 2px solid $c-primary;
          background-color: $c-secondary-light;
        }
        &.is-cheked {
          background-color: $c-primary;
          color: $c-white;
        }
        .item-name {
          font-weight: bold;
          text-align: center;
        }
        .item-ref-no {
          font-size: $fz-14px;
          font-weight: bold;
        }
      }
    }
  }
  .unit-fields-card-checkbox-wrapper {
    position: absolute;
    left: 0.5rem;
    margin-top: -0.2rem;
    .checkbox-groups-wrapper.theme-default .checkbox-wrapper.Mui-checked .mdi,
    .checkbox-groups-wrapper.theme-default .checkbox-wrapper.MuiCheckbox-indeterminate .mdi {
      background-color: $c-white;
      color: $c-black;
    }
  }
  .new-value-section {
    .label-wrapper {
      color: var(--c-primary);
      font-size: $fz-16px;
      font-weight: 500;
      margin-left: -1rem;
      @include rtl {
        margin-left: 0;
        margin-right: -1rem;
      }
    }
  }
}
.bulk-actions-wrapper {
  display: flex;
  justify-content: space-between;
}
