.AutoCorrespondence-wrapper {
  .min
  {
    min-width: 20rem;
  }
  .action-box
  {
    min-width: 20rem;
  }
  .Seaction1-main-tilte {
    margin-left: 51px;
    margin-bottom: 21px;
  }
  .border-head {
    border: 1px solid #8e8e93;
    border-radius: 14px;
    padding: 13px;
  }
  .main-page-title {
    padding: 8px 26px 9px 43px;
    .main-page-title-text-1 {
      font-weight: 900;
      font-size: 18px;
      margin-bottom: 11px;
    }
    .main-page-title-text-2 {
    }
  }
  padding: 0rem 3rem 4rem 3rem;
  .form-item {
    margin-top: 1rem;
  }
  .line {
    height: 1px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    background-color: #b6afaf;
  }
  .Seaction1,
  .Seaction2,
  .Seaction4,
  .Seaction3 {
    display: flex;
    flex: 1 1 100%;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    max-width: 48rem;
    .title-box {
      max-width: 18rem;
      min-width: 18rem;
    }
  }
}
