.contacts-card-detaild-wrapper {
  padding-top: 2rem;

  .details-img {
    width: 100px;
    height: 100px;
    border-radius: 1rem;
    background-color: #f2f2f2;
  }

  .slider-data {
    overflow-y: auto;
    height: calc(100vh - 450px);
  }

  .schedual-wrapper {
    overflow-y: auto;
    height: calc(100vh - 434px);

    .calender-wrapper {
      .selectedDay {
        .MuiBadge-badge {
          min-width: 5px;
          height: 5px;
          position: absolute;
          right: 16px;
          top: 19px;
          background-color: $c-white;
        }
      }

      .Badge-dot {
        .MuiBadge-badge {
          min-width: 5px;
          height: 5px;
          position: absolute;
          right: 16px;
          top: 19px;
        }
      }

      .MuiButtonBase-root {
        width: 25px;
        height: 25px;
      }
    }

    .contact-Activities {
      display: flex;
      padding: 0 3%;
      justify-content: space-between;
      flex-direction: column;

      .activity-hour {
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10 px;
        text-transform: initial;
        font-size: $fz-13px;
        white-space: nowrap;
        background: var(--c-primary);
        color: #f2f2f2;
        height: 25px;
        width: 70px;
      }

      .activity-item {
        display: flex;
        align-content: center;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        // border-bottom: 1px;
        border-bottom: 1px solid #ebe8e8;

        .dot {
          background-color: var(--c-primary);
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 100%;
          margin: 0 4% 0 0;
        }

        .activity-item-details {
          line-height: 22px;

          .lead-ref {
            color: var(--c-primary);
          }
        }
      }
    }
  }

  .items-title {
    color: $c-primary;
    font-weight: bold;
    font-size: $fz-16px;
  }

  .priority-types {
    display: flex;
    justify-content: center;
    align-items: center;

    .priority-type {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $c-gray;
    }
  }

  .high-badge {
    background-color: #ff827c;
    width: 0.5rem;

    height: 0.5rem;
    border-radius: 100%;
  }

  .medium-badge {
    background-color: var(--c-primary);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
  }

  .low-badge {
    background-color: #31c7aa;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
  }

  .calender-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;

    .calender-type-name {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $fz-14px;
    }

    .low-badge-time {
      background-color: #31c7aa;
      width: 3rem;
      height: 1rem;
      border-radius: 3rem;
      color: white;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .high-badge-time {
      background-color: #ff827c;
      width: 3rem;
      height: 1rem;
      border-radius: 3rem;
      color: white;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .medium-badge-time {
      background-color: var(--c-primary);
      width: 3rem;
      height: 1rem;
      border-radius: 3rem;
      color: white;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .badge-info {
      display: flex;
      flex-direction: column;

      .badge-name {
        font-size: $fz-14px;
      }

      .badge-time {
        font-size: $fz-default;
        color: $c-gray;
      }
    }
  }

  .data-schedule-button {
    display: grid;
    border-radius: 16px;
    background-color: #fafafa;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    align-items: center;
    align-content: center;
    justify-items: center;

    .MuiButton-root {
      padding: 0.5rem;
      color: $c-gray-dark;
    }

    .data-file-button {
      &.selected {
        .MuiButton-label {
          color: $c-primary !important;
          font-weight: bold;
        }

        .btns.MuiButtonBase-root.theme-outline {
          border: 1px solid $c-primary;
        }
      }
    }

    .schedule-button {
      &.selected {
        .MuiButton-label {
          color: $c-primary !important;
          font-weight: bold;
        }

        .btns.MuiButtonBase-root.theme-outline {
          border: 1px solid $c-primary;
        }
      }
    }

    .details-button {
      &.selected {
        .MuiButton-label {
          color: $c-primary !important;
          font-weight: bold;
        }

        .btns.MuiButtonBase-root.theme-outline {
          border: 1px solid $c-primary;
        }
      }
    }
  }

  .properety-plan {
    font-size: $fz-14px;
    margin: 1rem;
    font-weight: bold;
  }

  .side-menu-actions {
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px -2px 6px #00000029;
    width: 310px;
    padding: 1rem;
    background-color: $c-white;
    z-index: 99;
    position: fixed;
    bottom: 0;

    .MuiButtonBase-root {
      padding: 0.5rem;
    }
  }

  .archive-bbt {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    position: absolute;
    right: 0;

    .btns-icon.MuiButtonBase-root.theme-solid {
      background-color: #3b72d9;
      color: #fff;
    }

    .btns-icon.MuiButtonBase-root {
      height: 30px;
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      border-radius: 100%;
      padding: 0.2rem;
    }
  }

  .id-right-side {
    .mdi {
      cursor: pointer;
    }
  }
}