.date-range-picker-component {
  @include d-flex-column;
  max-width: 17rem;
  .display-wrapper {
    @include d-flex-v-center-h-between;
    width: 100%;
    padding: 0.25rem 0.5rem;
    min-width: 100px;
    border-radius: 0.5rem;
    &.wide-ovel {
      min-height: 32px;
      border-radius: 1rem;
    }
    border: 1px solid $c-gray-light;
    &.has-error {
      border-color: $c-danger;
      .mdi,
      .description-text {
        color: $c-danger !important;
      }
      .start-date-error,
      .end-date-error {
        color: $c-danger;
      }
    }
  }
  .error-wrapper {
    color: $c-danger;
  }
}
.rdrDateRangePickerWrapper {
  @include rtl {
    direction: ltr;
  }
}
.picker-wrapper {
  &.MuiFormControl-root {
    margin-bottom: 0;
    margin-top: 0;
  }

  .MuiButtonBase-root {
    padding: 0.25rem;
  }

  &.hide-button {
    .MuiButtonBase-root {
      display: none;
    }
  }
}
.rdrSelected,
.rdrInRange {
  background-color: #e2eaf9 !important;
}
.rdrStartEdge,
.rdrEndEdge {
  border-radius: 50% !important;
  width: 2.6rem;
  height: 2.6rem;
  margin-top: -0.5rem;
}
.rdrStartEdge {
  border-radius: 50% !important;
}
.rdrEndEdge {
  border-radius: 50% !important;
}
.rdrDateDisplayWrapper{
  display: none;
}
.rdrDayNumber {
  right: 0.5rem;
}
.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: $c-black !important;
}
