.PageHeading {

  .fz-30 {
    font-size: 30px;
    line-height: 22px;
    font-family: inherit;
  }

  .fz-18 {
    font-size: 18px;
    line-height: 22px;
  }
  
  .fz-16 {
    font-size: 16px;
    line-height: 22px;
  }

  .fz-20 {
    font-size: 20px;
    line-height: 22px;
  }

  .fz-12 {
    font-size: 12px;
    line-height: 20px;
  }
  .text-tertiary {
    color: #475467;
  }

  .title-with-chip {
    display: flex;
    justify-content: flex-start;
    .header-title 
    {
      font-size: 30px;
      line-height: 32px;
      font-family: inherit;

    }
    .title-chip {
      margin: 0 1rem;
      display: flex;
      padding: 0.5rem 1rem;
      align-items: center;
      background: var(
        --Component-colors-Utility-Brand-utility-brand-50,
        #f4f1ed
      );
      color: #635235;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      border-radius: 42%;
      height: 10%;
      border: 1px solid #cabfac;
    }
  }
}
