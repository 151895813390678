.content-wrapper {
  // margin-bottom: 70px;
  @include d-flex-column;
  justify-content: space-between;
  flex: 1 1 auto;
  // padding-left: 4.5rem;
  width: calc(100% - 80px);
  min-width: 280px;
  position: relative;
  @include rtl {
    padding-left: 0;
    padding-right: 0;
  }
  transition-property: width;
  transition-duration: 0.18s;
  transition-timing-function: linear;
  // float: right;
  &.is-open,
  &.is-open-side-menu {
    width: calc(100% - 310px);
  }
  &.is-open-side-menu.is-open {
    width: calc(100% - 620px);
  }
}
