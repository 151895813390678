.developers-view {
  margin: 1rem;
  .developers-filters-1 {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .filter-part1 {
      // width:  25%;
      margin: 0 0.25rem;
    }
    .autocomplete-wrapper {
      max-width: 220px;
    }
  }
}
