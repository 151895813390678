.UnitAddEditRemarkDialog {
  .Requierd-Color::after {
    content: ' *';
    color: $c-red;
  }
  .MuiDialogTitle-root {
    border-bottom: 1px solid #8e8e93 !important;
  }

  .MuiInput-underline:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-bottom: 0px solid #3f51b5;
    pointer-events: none;
  }
  .AddDialogRemarkDialog {
    .input-wrapper.MuiFormControl-root {
      margin-bottom: 2.5rem;
    }
    .MuiDialogTitle-root {
      flex: 0 0 auto;
      margin: 0;
      padding: 16px 24px;
      border-bottom: 1px solid #8e8e93 !important;
    }
  }
  .MuiDialogContent-root {
    flex: 1 1 auto;
    padding: 50px 50px !important;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .radio-groups-wrapper.theme-line {
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  .select-wrapper.MuiFormControl-root.theme-underline.select-filled .selects .MuiSelect-select {
    color: var(--c-primary);
    min-height: 32px;
    width: 100%;
    background-color: $c-white;
    border: 1px solid #f0f0f0;
    color: #3a3a3c;
    border-radius: 0.5rem;
  }
}
