.view-wrapper .unit-Invoice-wrapper {
  .header-view{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
    .header-view-sale {
      display: flex;
      padding: 0 2rem;
      align-items: center;
      gap: 10px;
  
      .payment-Plans-component,
      .start-date-component,
      .insert-button {
        margin-right: .3rem;
      }
  
      .insert-button {
        margin-top: 1.3rem !important;
      }
    }
  
    .invoice-payments-table{
      margin-top: 1rem;
    }
  }
  
  
