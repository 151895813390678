.images-gallery-photos-card-wrapper {
  @include d-flex-v-center;
  width: 100%;
  .images-gallery-photos-item-wrapper {
    @include d-inline-flex;
    padding: 0 0.5rem;
    margin-bottom: 1rem;
    max-width: 25%;
    min-width: 200px;
    @include phones-max {
      max-width: 100%;
    }
    .images-gallery-photos-item {
      position: relative;
      overflow: hidden;
      border-radius: 0.5rem;
      .images-gallery-photos-image {
        border-radius: 0.5rem;
        min-width: 150px;
        min-height: 170px;
      }

      .images-gallery-tooltip-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.3);
        @include d-flex-column;
        justify-content: flex-end;
        align-items: flex-start;
        overflow: hidden;
        height: 0;
        width: 100%;
        transition: height 0.25s linear;
      }
      &:hover {
        .images-gallery-tooltip-wrapper {
          height: 100%;
        }
      }
    }
    .Checkbox-wraper {
      .MuiFormControl-root {
        position: absolute;
        z-index: 10;
      }
    }
  }
}
.photo-tooltip-wrapper {
  padding: 0 1rem;
  margin-bottom: 0.5rem;
  color: $c-white;
  .photo-tooltip-item {
    @include d-flex-v-center;
    flex-wrap: wrap;
    margin-bottom: 0.25rem;
    text-align: left;
    @include rtl {
      text-align: right;
    }
    &:last-child {
      font-weight: $fw-bold;
    }
  }
}
