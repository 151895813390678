.no-permission {
  width: 100%;
  display: flex;
  justify-content: center;

  .permission-denied {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;

    .permission-denied-area {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      align-self: stretch;
      margin: 0.2rem;
      padding: 0.2rem;

      .PermissionDeniedText1 {
        color: var(--gray-900, #101828);
        text-align: center;
        font-size: 2rem;
        font-weight: 400;
        line-height: 2rem;
        letter-spacing: -0.07rem;
        font-family: Poppins;
      }

      .PermissionDeniedText2 {
        color: var(--gray-600, #475467);
        text-align: center;
        font-size: 1.25rem;
        line-height: 1.875rem;
        font-family: Poppins;
      }
      .backButtonArea {
        // width: 40%;
        display: flex;
        padding: 0.7rem 1rem;
        justify-content: center;
        align-items: center;
        gap: 0.2rem;
        border-radius: 0.5rem;
        border: 1px solid var(--gray-300, #d0d5dd);
        background: var(--base-white, #fff);
        font-size: 1.25rem;
      }

      .no-data-img {
        padding-top: 4rem;
        position: relative;
        width: 100%;
      }
    }
  }
}
