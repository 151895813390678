.receipts-view-wrapper {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 1rem;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 1rem;

  .title-section {
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 1.5rem;
      color: #333;

      .btns {
          &:hover {
              background-color: #8e8e93;
          }
      }
  }

  .section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 1rem;

      .btns {
          &:hover {
              background-color: #2a56a4;
          }

          .mdi-plus {
              margin-right: 0.5rem;
          }
      }
  }

  .tables-wrapper {
      margin-top: 1rem;
  }

  .invoice-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;

      .section2-invoice, .section1-invoice {
          display: flex;
          flex-direction: column;
          padding: 10px;
          width: 100%;
          margin: 0 auto;

          .header-item, .value-item {
              display: flex;
              justify-content: space-between;
              padding: 10px;

              &:not(:last-child) {
                  margin-bottom: 10px;
              }

              span {
                  flex: 1;
                  text-align: center;
              }

              &.header-item {
                  background-color: #f9f9f9;
                  .header-title {
                      font-weight: bold;
                      font-size: 0.9em;
                      color: #555;
                  }
              }

              &.value-item {
                  background-color: #f5f5f5;
                  .value-name {
                      font-size: 1em;
                      color: #000;
                  }
              }
          }
      }
  }

  .remaining-balance {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      margin-top: 1rem;

      .title {
          margin-right: 0.5rem;
          color: #333;
      }

      span {
          background-color: #f9f9f9;
          padding: 0.5rem 1rem;
          border-radius: 4px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
  }
}

