$i-bell: url('../../../../assets/images/Propxicon/bell.svg');
$i-Aannotation-question: url('../../../../assets/images/Propxicon/annotation-question.svg');
$i-bell: url('../../../../assets/images/Propxicon/bell.svg');
$i-calendar: url('../../../../assets/images/Propxicon/calendar.svg');
$i-file: url('../../../../assets/images/Propxicon/file.svg');
$i-currency-dollar-circle: url('../../../../assets/images/Propxicon/currency-dollar-circle.svg');
$i-home: url('../../../../assets/images/Propxicon/home.svg');
$i-home-line: url('../../../../assets/images/Propxicon/home-line.svg');
$i-image: url('../../../../assets/images/Propxicon/image.svg');
$i-key: url('../../../../assets/images/Propxicon/key.svg');
$i-list: url('../../../../assets/images/Propxicon/list.svg');
$i-marker-pin: url('../../../../assets/images/Propxicon/marker-pin.svg');
$i-Percentage-discount: url('../../../../assets/images/Propxicon/Percentage-discount.svg');
$i-Property-owner: url('../../../../assets/images/Propxicon/Property-owner.svg');
$i-Rent-home: url('../../../../assets/images/Propxicon/Rent-home.svg');
$i-Sale-home: url('../../../../assets/images/Propxicon/Sale-home.svg');
$i-settings: url('../../../../assets/images/Propxicon/settings.svg');
$i-settings-Cogwheel: url('../../../../assets/images/Propxicon/settings-Cogwheel.svg');
$i-trumpet: url('../../../../assets/images/Propxicon/trumpet.svg');
$i-user: url('../../../../assets/images/Propxicon/user.svg');
$i-users-edit: url('../../../../assets/images/Propxicon/users-edit.svg');
$i-reverse-right: url('../../../../assets/images/Propxicon/reverse-right.svg');
$i-switch-horizontal: url('../../../../assets/images/Propxicon/switch-horizontal-02.svg');
$i-settings-04: url('../../../../assets/images/Propxicon/settings-04.svg');
$i-clock: url('../../../../assets/images/Propxicon/clock.svg');
$i-bed: url('../../../../assets/images/Propxicon/bed.svg');
$i-parking: url('../../../../assets/images/Propxicon/parking.svg');
$i-x-close-dialog: url('../../../../assets/images/Propxicon/x-close-dialog.svg');
$i-bath: url('../../../../assets/images/Propxicon/bath.svg');
$i-unit-size: url('../../../../assets/images/Propxicon/unit-size.svg');
$i-copy-03: url('../../../../assets/images/Propxicon/copy-03.svg');
$i-help-circle: url('../../../../assets/images/Propxicon/help-circle.svg');
$i-search-lg: url('../../../../assets/images/Propxicon/search-lg.svg');
$i-check: url('../../../../assets/images/Propxicon/check.svg');
$i-plus: url('../../../../assets/images/Propxicon/plus.svg');
$i-x-close: url('../../../../assets/images/Propxicon/x-close.svg');
$i-user-chevron-right: url('../../../../assets/images/Propxicon/user-chevron-right.svg');
$i-user-square: url('../../../../assets/images/Propxicon/user-square.svg');
$i-users-voice: url('../../../../assets/images/Propxicon/users-voice.svg');
$i-Agent-luggage: url('../../../../assets/images/Propxicon/Agent-luggage.svg');
