.uploader-wrapper {
  .add-btn:disabled,
  .add-btn[disabled]{
    border: 1px solid #ffffff !important;
    background-color: #ffffff !important;
    color: #000000 !important;
  }
  .tab-wrapper {
    max-width: 15%;
  }
  .uploader-type {
    max-width: 80.5%;
  }
}
