.loading-wave {

  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  width: 100%;
  height: 75%;
  background: #eaeaea00;
}
.loading-wave2 {

  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  width: 50%;
  height: 50%;
  background: #eaeaea00;
}

.loading-bar {
  width: 20px;
  height: 10px;
  margin: 0 5px;
  background-color:#7F6944;
  border-radius: 5px;
  animation: loading-wave-animation 0.7s ease-in-out infinite;
}
.loading-barBlue {
  width: 20px;
  height: 10px;
  margin: 0 5px;
  background-color:#0F3D6E;
  border-radius: 5px;
  animation: loading-wave-animation 0.7s ease-in-out infinite;
}

.loading-bar:nth-child(2) {
  animation-delay: 0.1s;
}

.loading-bar:nth-child(3) {
  animation-delay: 0.2s;
}

.loading-bar:nth-child(4) {
  animation-delay: 0.3s;
}


.loading-barBlue:nth-child(2) {
  animation-delay: 0.1s;
}

.loading-barBlue:nth-child(3) {
  animation-delay: 0.2s;
}

.loading-barBlue:nth-child(4) {
  animation-delay: 0.3s;
}

@keyframes loading-wave-animation {
  0% {
    height: 10px;
  }

  50% {
    height: 50px;
  }

  100% {
    height: 10px;
  }
}
