.units-profile-wrapper {

  .disable-style {
    background-color: #c7c7cc !important;
    cursor: initial;
    color: #ffffff !important;
  }
  
  .leadOwner{
    margin: .8rem ;
    font-size: .9rem ; 
    color : var(--c-primary) ; 
    .leadName {
      color : #8e8e93 ; 

    }
   }
  
  padding: 0 0.5rem;
  .childs-wrapper {
    border-radius: 1rem;
    padding: 0.5rem 0.5rem 1rem;
    border: 1px solid $c-gray-secondary;
    width: 100%;
    &.b-0 {
      border-width: 0;
    }
    .title-section {
      padding: 0 0.5rem;
      font-weight: $fw-simi-bold;
      font-size: $fz-16px;
      margin-bottom: 1rem;
    }
  }
  .form-item {
    @include d-inline-flex;
    width: 25%;
    @include laptops-xl-max {
      width: 33.3333%;
    }
    @include tablets-max {
      width: 50%;
    }
    @include phones-s-max {
      width: 100%;
    }
    padding: 0 0.5rem;
    margin-bottom: 1rem;
  }
  .tabs-content-wrapper {
    padding: 1rem 0.5rem 0;
    @include d-flex-h-center;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .filter-sections-wrapper {
    @include d-flex-v-center;
    flex-wrap: wrap;
    .filter-section-item {
      @include d-inline-flex-v-center;
      margin-bottom: 0.5rem;
    }
  }

  .unit-profile-payables-wrapper {
    max-width: 700px;
    width: 100%;
  }
  .pigeon-attribution {
    display: none;
  }
  .UnitProfileOpenHouseseaction {
    display: flex;
    flex: 1 1 100%;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    //    padding: 1rem;
    @include phones-s-max {
      justify-content: center;
    }
  }
  .btns.MuiButtonBase-root.c-success-light:disabled {
    background-color:  $c-success-light  !important;
  }
  
  .btns.MuiButtonBase-root.c-primary:disabled {
    background-color:  $c-primary !important;
  }
  
  .btns.MuiButtonBase-root.c-black-light:disabled {
    background-color: $c-black-light!important;
  }

  .btns.MuiButtonBase-root.c-warning-light:disabled {
    background-color: $c-warning-light!important;
  }
}
