.contact-profile-wrapper {
  padding: 0 0.5rem;
  .childs-wrapper {
    border-radius: 1rem;
    padding: 0.5rem 0.5rem 1rem;
    border: 1px solid $c-gray-secondary;
    &:not(.contacts-information-wrapper) {
      margin: 1rem 0.5rem;
    }
    &.b-0 {
      border-width: 0;
    }
    .title-section {
      padding: 0 0.5rem;
      font-weight: $fw-simi-bold;
      font-size: $fz-16px;
      margin-bottom: 1rem;
    }
  }

    .contact-units-wrapper {
  
      .title-section {
        padding: 0 0.5rem;
        font-weight: $fw-simi-bold;
        font-size: $fz-16px;
        margin-bottom: 1rem;
      }
  
      .units-cards-wrapper.childs-wrapper {
        border: none;
      }
    }
    
  .form-item {
    @include d-inline-flex;
    width: 25%;

    .information-open-file-1 {

      display: flex;

      //flex: 1 1 100%;

      justify-content: flex-end;

      align-items: center;

      margin: .3rem;

      color: var(--c-primary) ;

    }

    .helper_rangField
       {
         display: flex;
         justify-content: space-between;
         width: 100%;
         margin: .2rem  .5rem ; 
         .InformationIcon { 
          color: var(--c-primary) ;
          margin-left: 0.5rem;
          margin-right: 0.5rem;
         } 
       }



    .information-open-file-2 {

      display: flex;

     // flex: 1 1 100%;

      justify-content: center;

      align-items: center;

      margin: .3rem;

      color: var(--c-primary) ;

    }

    @include laptops-xl-max {
      width: 33.3333%;
    }
    @include tablets-max {
      width: 50%;
    }
    @include phones-s-max {
      width: 100%;
    }
    padding: 0 0.5rem;
    margin-bottom: 1rem;
  }
  .tabs-content-wrapper {
    padding: 1rem 0.5rem 0;
    align-items: flex-start;
  }
  .filter-sections-wrapper {
    @include d-flex-v-center;
    flex-wrap: wrap;
    .filter-section-item {
      @include d-inline-flex-v-center;
      margin-bottom: 0.5rem;
    }
  }
}
.contact-profile-document-management-dialog {
  .dialog-item {
    width: 50%;
    margin-bottom: 1rem;
    @include phones-max {
      width: 100%;
    }
  }
}

.associated{
  display: flex;
  justify-content: space-between;
  width: 100%;
  .iconDelete{
    cursor: pointer;
  }
}
// .contact-profile-wrapper{
//   .tabs-wrapper{
//     width: 100%;
//     padding: 0;
//     display: flex;
//     flex-wrap: wrap;
//     flex-direction: row;
//     margin-left: 45px;
//     gap: 10px;
//     .w-24{
//       width: 24rem !important;
//     }
//   }
// }
