.release-note-view-wrapper {
  display: grid;
  grid-template-columns: 35.5% auto;
  margin-right: 5rem;
  margin-left: 5rem;
  padding: 2rem;

  .side-panel {
    padding-right: 1rem;
    border-right: 1px solid #eaecf0;

    .side-panel-title {
      font-weight: 600;
      font-size: $fz-18px;
      line-height: 28px;
      color: #101828;
      padding-bottom: 1rem;
    }

    .add-btn.MuiButtonBase-root {
      width: 100%;
      padding: 0.75rem 0;
      margin: 1rem 0;
      border: 1px solid #d0d5dd;
      border-radius: 8px;
      box-shadow: 0px 1px 2px 0px #1018280d;
    }
    .add-btn.MuiButtonBase-root span {
      font-weight: 600;
      font-size: $fz-16px;
      line-height: 24px;
      color: #344054;
    }

    .add-btn.MuiButtonBase-root .mdi::before {
      font-size: $fz-21px;
    }
  }

  .release-note-content {
    height: 100%;
    padding-right: 0.5rem;
    padding-left: 2rem;
    overflow-y: auto;
  }

  .release-notes-container {
    height: 61.25rem;
    overflow-y: auto;
    padding-right: 0.75rem;
    margin-right: 0.25rem;
  }

  .release-note-item-wrapper {
    position: relative;

    .popover-button {
      position: absolute;
      z-index: 10;
      top: 1rem;
      right: 1rem;
      cursor: pointer;

      .mdi:before {
        line-height: 100%;
        font-size: $fz-20px;
        color: #98a2b3;
      }
    }
  }

  .release-note-item {
    height: 5rem;
    padding: 1rem;
    border-bottom: 1px solid #eaecf0;

    .item-header,
    .item-body {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .item-header span {
      font-weight: 700;
      font-size: $fz-16px;
      line-height: 24px;
      color: #101828;
      white-space: nowrap;
      width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .item-header:last-child {
      color: #98a2b3;
    }

    .item-body span {
      margin-top: 0.25rem;
      font-weight: 400;
      font-size: $fz-14px;
      line-height: 20px;
      color: #475467;
    }
  }

  .active-release-note {
    background: #f9fafb;
    border-bottom: 2px solid #e0592a;
  }

  .release-note-item:hover {
    cursor: pointer;
    transition: 0.5s;
    background: #f9fafb;
  }

  .release-details-wrapper {
    padding-right: 0.5rem;
    padding-left: 0.5rem;

    .version-title {
      font-weight: 600;
      font-size: $fz-16px;
      line-height: 24px;
      color: #e0592a;
    }

    .x-large-text {
      font-weight: 600;
      font-size: $fz-36px;
      line-height: 44px;
      letter-spacing: -0.02em;
      color: #101828;
      margin-top: 0.75rem;
      margin-bottom: 1rem;
    }

    .description {
      font-weight: 400;
      font-size: $fz-20px;
      line-height: 30px;
      color: #475467;
      margin-bottom: 2rem;
    }

    .alert-box {
      height: fit-content;
      padding: 1rem;
      margin-bottom: 2rem;
      background: #f9fafb;
      border-radius: 12px;
      color: #475467;
    }

    .box-item-1 {
      font-weight: 400;
      font-size: $fz-14px;
      line-height: 20px;
    }

    .box-item-2 {
      margin-top: 1rem;
      font-weight: 600;
      font-size: $fz-16px;
      line-height: 24px;
      color: #30374f;
      text-transform: uppercase;
      word-break: break-word;
    }

    .box-item-3 {
      font-weight: 400;
      font-size: $fz-16px;
      line-height: 24px;
      color: #364152;
      word-wrap: break-word;
    }
  }

  .accordion-item{

   .sun-editor .se-wrapper .se-wrapper-wysiwyg {
      min-height: unset !important;
    }
  
    .sun-editor .se-wrapper .se-wrapper-code {
      min-height: unset !important;
    }
  }
}

.release-note-popover {
  .MuiPaper-root {
    display: block;
    width: 240px;
    padding: 0.25rem;
    margin-top: 6px;
    margin-left: -112px;
    border-radius: 8px;
  }

  .MuiPaper-elevation8 {
    box-shadow: none;
    border: 1px solid #f1f1f1;
    // box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  }
  
  .popover-loader{
    display: block;
    width: 16px;
    height: 16px;
  }
  .popover-item {
    display: flex;
    align-items: center;
    padding: 0.75rem;
    cursor: pointer;

    .option-name {
      font-size: $fz-14px;
      font-weight: 500;
      line-height: 20px;
      margin-left: 10px;
    }
    .mdi:before {
      color: #4d5869;
    }
  }

  .popover-item:hover {
    background: #f9fafb;
  }
}
