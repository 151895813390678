.form-builder-wrapper {
  .form-builder-items-wrapper {
    padding: 0 0.5rem;
    padding-bottom: 3rem;

    .form-builder-item-wrapper {
      padding: 0 0.5rem;
      margin-bottom: 1rem;

      .helper_rangField {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .InformationIcon {
          color: var(--c-primary);
          margin-left: 0.5rem;
          margin-right: 0.5rem;
        }
      }

      .helper-Information {
        display: flex;
        flex: 1 1 100%;

        .information {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          margin: 0.3rem;
          color: var(--c-primary);
        }

        .information2 {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin: 0.3rem;
          color: var(--c-primary);
        }
      }
    }
  }

  .form-actions-wrapper {
    flex: 0 1 auto;
    position: absolute;
    width: 97.5%;
    padding: 0 1rem;
    bottom: 0;
    z-index: 8;
    .form-builder-footer-wrapper {
      background-color: #ffffff;
      padding: 0.6rem;
      padding-left: 1rem;
      padding-right: 1rem;
      border: 2px solid #cccccc;
      box-shadow: 0px -2px 6px #00000029;
      border-radius: 20px 20px 0 0;
      &.is-dialog {
        position: relative;
      }
    }
  }

  .form-item {
    display: inline-flex;
    flex: 0 1 auto;
    width: 100%;
    flex-wrap: wrap;
  }
}
