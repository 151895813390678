.payment-Banner {
    display: grid;
    grid-template-columns: repeat(4, minmax(240px, 1fr));
    max-width: 68vw;
    overflow-x: auto;

    &::-webkit-scrollbar {
        width: 0px;
        height: 4px;
    }

    .Method-card {
        display: flex;
        height: 140px;
        padding: 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 12px;


        &.primary{
            cursor: pointer;
            border: 1px solid #EAECF0;
            background: #FFF;

            &:hover {
                border: 1px solid #7F6944;
                transition: border .8s, transform .2s;
                transform: scale(.98);
            }
        }

        &.active{
            cursor: pointer;
            border: 1px solid #7F6944;
            background: #FFF;
        }

        &.disabled {
            border: 1px solid #EAECF0;
            background:  #FCFCFD;
            color: #667085;
            img {
                filter: invert(.8);
            }
        }

    }
}