.OwnerRotationView {
}
.OwnerRotationView-DialogComponent {
  .Control-Leads-wraper
  {
    .radio-groups-wrapper.theme-default.MuiFormControl-root {
      padding: 0 0rem 0 0.5rem;
  }
  .Stepper-Stepper {
    width: 24rem;
  }
}
}
.schema-setting-wrapper {
  .search-bar {
    display: -webkit-box;
    position: relative;
    max-width: 80rem;
    align-items: baseline;

    @include laptops-l-max {
      flex-wrap: wrap;
    }
    @include laptops-max {
      flex-wrap: wrap;
    }
    @include phones-max {
      flex-wrap: wrap;
    }
  }

  .the-cap-based-on {
    text-align: left;
    margin: 1rem 1rem 6px 1rem;
    font-family: 'Poppins-Regular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
  }

  .frame-3,
  .frame-3 * {
    box-sizing: border-box;
  }

  .frame-3 {
    background: #ffffff;
    border-radius: 10px;
    border-style: solid;
    border-color: #e5e5ea;
    border-width: 1px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 60px;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.1);
  }

  .frame-6 {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    position: relative;
  }

  .frame-1 {
    padding: 0px 0px 0px 10px;
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
  }

  .one-by-one-distribution {
    color: #000000;
    text-align: left;
    font-family: 'Poppins-Bold', sans-serif;
    font-size: 15px;
    font-weight: 700;
    position: relative;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: var(--c-primary);
  }
  /* Figma Styles of your File */
  :root {
    /* Colors */
    --_3-b-72-d-9: var(--c-primary);
    --_000000: #000000;
    --ffffff: #ffffff;
    --_2153-b-1: var(--c-primary);
    --c-7-c-7-cc: #c7c7cc;
    --_8-e-8-e-93: #8e8e93;
    --db-504-a: #db504a;
    --f-0-f-0-f-0: #f0f0f0;
    --e-0-e-0-e-0: #e0e0e0;
    --a-6-a-6-a-6: #a6a6a6;
    --e-5-e-5-ea: #e5e5ea;
    --_2-d-3-e-50: #2d3e50;

    /* Fonts */
    /* Effects */
  }

  .checkbox-group-item,
  .checkbox-group-item * {
    box-sizing: border-box;
  }
  .checkbox-group-item {
    margin: 0rem 1rem 1rem 1rem;
    background: var(--ffffff, #ffffff);
    border-radius: var(--radius-xl, 12px);
    border-style: solid;
    border-color: var(--e-0-e-0-e-0, #e0e0e0);
    border-width: 1px;
    padding: var(--spacing-xl, 16px);
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: flex-start;
    justify-content: center;
    flex-shrink: 0;
    width: 200px;
    position: relative;
  }
  .input {
    padding: var(--spacing-xxs, 2px) 0px 0px 0px;
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: absolute;
    left: 16px;
    top: 16px;
  }
  .frame-8 {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
  }
  .rectangle-7 {
    background: var(--ffffff, #ffffff);
    border-radius: 11px;
    border-style: solid;
    border-color: var(--_8-e-8-e-93, #8e8e93);
    border-width: 1.5px;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    position: relative;
  }
  .content {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-lg, 12px);
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
  }
  .text-and-supporting-text {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
  }
  .monitor-03 {
    flex-shrink: 0;
    width: 49px;
    height: 49px;
    position: relative;
    overflow: visible;
  }
  .text-and-subtext {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-sm, 6px);
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
  }
  .text {
    color: var(--_000000, #000000);
    text-align: left;
    font-family: var(--textmd-medium-font-family, 'Inter-Medium', sans-serif);
    font-size: var(--textmd-medium-font-size, 16px);
    line-height: var(--textmd-medium-line-height, 24px);
    font-weight: var(--textmd-medium-font-weight, 500);
    position: relative;
  }
}
.OwnerRotation-inputs
{
  display: inline-flex;
  flex-wrap: wrap;
  width: 40rem;
  .radio-groups-wrapper.theme-default.MuiFormControl-root {
    padding: 0;
}
}
.Control-Leads
{
  margin: 10px 10px -1px 10px;
  font-size: 11px;
}
