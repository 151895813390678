.UnitsAdd-Sale-View-wrapper {
  width: 100%;

  .form-PriceAndPercentage {
    display: inline-flex;
    flex-wrap: wrap;

    .form-item {
      width: 24rem;
      padding-left: 71px !important;
      @include rtl {
        padding-right: 71px;
        padding-left: 0px;
      }
    }

    .form-subitem {
      width: 12.781rem;
      @include rtl {
        padding-right: 71px;
        padding-left: 0px;
      }
    }

    .without-label-Value {
      padding-top: 10px;
    }
  }

  .form-rent_price_fees {
    display: flex;
    flex-wrap: nowrap;
    .form-subitem {
      width: 40% !important;
      @include laptops-l-max {
        width: 44% !important;
      }
    }
    @include tablets-max {
      display: inline-block;
    }
    @include laptops-l-max {
      width: 64rem;
    }
    @include laptops-max {
      display: inline-block;
    }
  }

  .UnitsAdd-section-title {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    font-size: $fz-14px;
    font-weight: bold;
    margin-top: 11px;
  }
  .row-Units-section-num-1 {
    @include flex-column;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    justify-content: flex-start;
    padding-left: 71px;
    @include phones-s-max {
      padding-left: 0px;
    }
    .form-item {
      padding-right: 95px;
      width: 24rem;
      @include tablets-max {
        width: 100%;
        padding-top: 22px;
      }
      @include phones-s-max {
        width: 100%;
        padding-right: 0px;
        padding-top: 22px;
      }
      @include laptops-l-max {
        width: 32rem;
      }
      @include laptops-max {
        //width: 29rem;
        width: 47rem;
      }
      @include phones-max {
        width: 40rem;
      }
    }
    .First-section {
      @include d-inline-flex-v-center;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 1rem;
      justify-content: flex-start;
      .form-item {
        .Requierd-Color::after {
          content: '*';
          color: $c-red;
        }
      }
    }
    .scaned-section {
      @include d-inline-flex-v-center;
      flex-wrap: wrap;
      margin-top: 1rem;
      width: 100%;
      justify-content: flex-start;
      .form-item {
        .Requierd-Color::after {
          content: '*';
          color: $c-red;
        }
      }
    }
  }

  .row-Units-section-num-2 {
    @include flex-column;
    justify-content: flex-start;
    padding-left: 50px;
    @include phones-s-max {
      padding-left: 0px;
    }
    flex-wrap: wrap;
    .form-item {
      padding-right: 95px;
      width: 24rem;
      @include tablets-max {
        width: 100%;
      }
      @include phones-s-max {
        width: 100%;
        padding-top: 22px;
        //   padding-left: 22px;
        padding-right: 0px;
      }

      @include laptops-l-max {
        width: 32rem;
      }
      @include laptops-max {
        width: 47rem;
      }
      @include phones-max {
        width: 40rem;
      }
    }
    .form-item-inline {
      padding-right: 95px;
      display: inline-flex;
      width: 33%;
      @include laptops-max {
        width: 47rem;
      }
      .room {
        padding-right: 11px;
        @include rtl {
          padding-right: 0px;
          padding-left: 11px;
        }
      }
      @include laptops-max {
        width: 29rem;
        display: block;
      }
      @include tablets-max {
        width: 100%;
        display: block;
      }
      @include phones-s-max {
        width: 100%;
        display: block;
        padding-right: 0px;
      }
      @include laptops-l-max {
        width: 47rem;
      }
      @include phones-max {
        width: 40rem;
      }

      .size {
        padding-left: 9px;
        @include rtl {
          padding-right: 9px;
          padding-left: 0px;
        }
      }
    }
    .First-section {
      @include d-inline-flex-v-center;
      flex-wrap: wrap;
      width: 100%;
      justify-content: flex-start;
    }
    .scaned-section {
      @include d-inline-flex-v-center;
      flex-wrap: wrap;
      margin-top: 0.5rem;
      width: 100%;
      justify-content: flex-start;
    }
  }
  .form-item-v2 {
    width: 76%;
    display: flex;
  }
  .form-item-v3 {
    width: 100%;
    display: flex;
    @include tablets-max {
      display: block;
    }
    @include phones-s-max {
      display: block;
    }
    @include phones-max {
      width: 34rem;
    }
    .Agencyfee-form {
      padding-right: 9px;
      @include rtl {
        padding-right: 0px;
        padding-left: 9px;
      }
    }
  }
  .space-input {
    padding-right: 9px;
    @include rtl {
      padding-right: 0px;
      padding-left: 9px;
    }
  }
  .w-33 {
    width: 33%;
  }
  .w-55 {
    width: 50%;
  }
  .Raido-Management .MuiTypography-body1 {
    font-size: 0.7rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
  }
}
.DialogComponent-add-new-units {
  border-bottom: 1px solid #000000;
  color: $c-black;
  width: 100% !important;
  font-size: $fz-16px;
  padding: 0 0.5rem 0.5rem;
  margin-bottom: 0.5rem;
}
.actions-form {
  display: flex;
  padding-top: 1.5rem;
  .bbt-delete {
    color: #ff827c;
  }
}
.wrapperClasses-add-new-units {
  .dialog-title-text {
    border-bottom: 0px solid #000000 !important;
    color: #000000 !important;
    font-size: $fz-16px !important;
    font-weight: bold !important;
    padding: 1rem 2rem 0.5rem !important;
    margin-bottom: 0.5rem;
  }
}
.AutocompleteComponentBedrooms {
  padding-right: 6px;
  @include rtl {
    padding-right: 0px;
    padding-left: 6px;
  }
  .autocomplete-wrapper {
    display: inline-flex;
    flex: 0 1 auto;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 139px;
  }
}
