.slideruser {
  .item-wrapper {
    color: #121212;
    font-weight: 600;
    padding-top: 3px;
  }
  .cards-wrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    cursor: default;
    border-radius: 0rem;
    border: 0px solid #ffffff !important;
    color: #121212;
    -webkit-border-radius: 0rem;
    -moz-border-radius: 0rem;
    -ms-border-radius: 0rem;
    -o-border-radius: 0rem;
  }

  .CardContenater {
    padding-left: 15px;
    padding-right: 15px;
  }

  .Cardgrid {
    text-align: center;
    width: 100%;
    height: 90px;
    background-color: #f0f0f0;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }

  .icon {
    color: #3a3a3c;
    padding-top: 8px;
  }
  .textcard {
    color: #3a3a3c;
    font-size: 12px;
  }
  .textcardsmoll {
    color: #58acc1;
    font-weight: 500;
    font-size: 13px;
  }

  .iconbig {
    font-size: 35px;
    color: $c-white;
    margin-left: -22px;
    @include rtl {
      margin-left: 0px;
    }
  }

  .textcardbig {
    color: $c-white;
    font-size: 15px;
    padding-top: 4px;
  }
  .textcardsmollbig {
    text-align: left !important;
    padding-top: 10px;
    padding-left: 28px;
    color: $c-white;
    font-size: 13px;
    padding-bottom: 10px;
  }

  .textcardsmollbig2 {
    color: $c-white;
    font-size: 13px;
  }

  .Contenater-icon {
    margin-top: 10px !important;
  }

  .state-online {
    background: rgb(66, 183, 42);
    border-radius: 50%;
    display: inline-block;
    height: 6px;
    margin-left: 4px;
    width: 6px;
    @include rtl {
      margin-right: 10px;
    }
  }
  .state-offline {
    background: rgb(201, 37, 15);
    border-radius: 50%;
    display: inline-block;
    height: 6px;
    margin-left: 4px;
    width: 6px;
  }
}

.time-left {
  display: flex;
  justify-content: center;
  color: #ff827c;
  font-size: 12px;
}
.text-Glopel {
  text-align: center;
}
.textcard {
  color: #3a3a3c;
  font-size: 12px;
  padding-top: 6px;
}
.textcard-vlaue {
  color: #3a3a3c;
  font-size: 12px;
}
.statues {
  background: #31c7aa 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  width: 56px;
  height: 18px;
  text-align: center;
  color: white;
  font-weight: 300;
}
.no-num {
  font-size: 16px;
  color: #3a3a3c;
  padding-left: 10px;
  padding-right: 28px;
  font-weight: 800;
  @include rtl {
    padding-left: 28px;
    padding-right: 10px;
  }
}
.Image-conteaner {
  display: inline-flex;
  .Image {
    width: 88px;
    height: 76px;
    border-radius: 10px;
    padding: 3px;
  }
}
.Ref {
  padding-top: 6px;
}
