.activities-management-dialog-wrapper {
  .uploader-chip {
    margin-right: 2px;
    margin-left: 2px;
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .related-to-wrapper {
    width: 100% !important;

    legend {
      width: fit-content;
      margin: 0;
      padding: 0px 4px;
      font-size: $fz-default;
      line-height: inherit;
      border: 0;
      color: $c-black;
    }

    legend::after {
      content: '*';
      color: $c-red;
    }

    fieldset {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 10px;
      border: 1px solid #ededed;
      border-radius: 8px;
      min-height: 38px;
      padding: 10px;
    }

    .autocomplete-wrapper {
      display: block;
    }

    .full-width-wrapper {
      display: block;
    }
  }

  .createdBy {
    text-align: flex-end;
    float: right;
  }
  .MuiDialogTitle-root {
    border-bottom: 1px solid $c-gray-dark !important;
    padding-left: 2rem;
  }
  .MuiDialog-paper {
    min-width: 50rem;
  }
  .MuiDialogContent-root {
    padding: 1rem;
    min-width: 30rem;
  }
  .dialog-content-wrapper {
    width: 100%;
    @include d-flex;
    flex-wrap: wrap;
    .dialog-content-item {
      width: 50%;
      margin-bottom: 0.5rem;
      @include phones-max {
        width: 100%;
      }
      padding: 0 0.5rem;
      .Requierd-Color::after {
        content: '*';
        color: $c-red;
      }
    }
    .title-wrapper {
      @include d-flex;
      margin-bottom: 0.5rem;
      padding: 0 0.5rem;
      .title-text {
        font-weight: $fw-simi-bold;
      }
      .Requierd-Color::after {
        content: ' *';
        color: $c-red;
      }
    }
    .reminder-wrapper {
      @include d-flex-column;
      .reminder-item-wrapper {
        @include d-flex;
        flex-wrap: wrap;
        .reminder-section {
          @include d-inline-flex;
          align-items: flex-start;
          margin-bottom: 1rem;
          padding: 0 0.5rem;
          width: 33.3333%;
          @include phones-max {
            width: 100%;
          }
        }
      }
      .reminder-inputs {
        display: grid;
        grid-template-columns: 20% 23% 25% 20% 15%;
        width: 100%;
        .autocomplete-wrapper {
          min-width: unset;
        }
      }
      .without-disable-style .MuiSelect-select.Mui-disabled {
        background-color: unset !important;
        color: unset !important;
      }
      .reminder-input {
        margin: 0.4rem;
      }
    }
  }
  .installment-container {
    height: 18.75rem;
    min-height: 18.75rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .payment-payment-display {
    width: fit-content;
    border-radius: 5px;
    margin: 0px 10px;
    margin-bottom: 15px;
    padding: 2px 10px;
    color: white;
    text-align: center;
    cursor: default;
  }

  .blue-display {
    background-color: #829cc7 !important;
  }
  .red-display {
    background-color: #db504a !important;
  }
  .green-display {
    background-color: #8cc63e !important;
  }
}
