.spinner-wrapper-dahboard {
  position: fixed;
  // z-index: 9999;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 100%;
  font-size: 50px;

  color: white;
  transform: translate(-50%, -50%);
  &.is-absolute {
    position: absolute;
  }
  .app-spinner-dahboard {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #989898;
    height: 100%;
    width: 100%;
    min-width: 25px;
    min-height: 25px;
    position: absolute;
    top: 0;
    z-index: 999999;
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s, opacity 0.3s linear;

    &.hide-dahboard {
      visibility: hidden;
      opacity: 0;
    }

    span {
      // margin-top: 10px;
      // letter-spacing: 0.02em;
      color: #595959;
      opacity: 0;
      animation: loading-text-opacity 2s linear 0s infinite normal;
    }
  }
  &.is-small {
    .spinner-img-dahboard {
      max-width: 32px;
    }
  }
}
@keyframes loading-text-opacity {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
