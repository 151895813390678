.Leasing-images-gallery-card-wrapper {
  @include d-flex-h-center;
  margin-top: 20px;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 0.5rem;
  .Leasing-album-card-wrapper {
    @include d-inline-flex;
    padding: 0 0.5rem;
    margin-bottom: 1rem;
    width: 20%;
    min-height: 120px;
    min-width: 280px;
    max-width: 380px;
    @include laptops-xl-max {
      width: 25%;
    }
    @include laptops-l-max {
      width: 33.3333%;
    }

    @include laptops-max {
      width: 50%;
    }

    @include phones-max {
      width: 100%;
      max-width: initial;
    }
    .Leasing-cards-wrapper {
      @include d-flex-column;
      border: 1px solid $c-gray-secondary;
      border-radius: 1.1rem;
      .Leasing-header-body-wrapper {
        @include d-flex-v-center-h-between;
        flex: 1 1 auto;
        padding: 0.5rem;
        .Leasing-albums-header-wrapper {
          padding: 0 0.5rem;
          font-weight: $fw-bold;
          font-size: $fz-14px;
        }
      }
      .cards-body-wrapper {
        width: 100%;
        height: 100%;
        margin-bottom: 0.5rem;
        .albums-body-wrapper {
          @include d-flex-column;
          width: 100%;
          padding: 0 0.5rem;
          .albums-body-item {
            padding: 0 0.5rem;
            font-size: $fz-14px;
            &.entered-item {
              margin-top: 0.5rem;
              font-size: $fz-default;
              color: $c-gray-dark;
            }
          }
        }
      }
      .footer-body-wrapper {
        width: 100%;
        .albums-footer-wrapper {
          @include d-flex-v-center-h-between;
          flex: 1 1 auto;
          flex-wrap: wrap;
          width: 100%;
          padding: 0.5rem;
          .images-more-wrapper {
            @include d-inline-flex;
            padding: 0 0.5rem;
            .slide-images {
              max-width: 70px;
              margin-bottom: 0;
              //   min-width: 45px;
              height: 40px;
              .slide-image {
                border-radius: 100%;
                border: 1px solid $c-white;
                height: 40px;
                width: 40px;
                // video{
                //   border-radius: 100% !important;
                //   border: 1px solid #ffffff !important;
                //   height: 40px !important;
                //   width: 40px !important;
                // }
              }
            }
            .images-more {
              z-index: 4;
              border-radius: 100%;
              border: 1px solid $c-white;
              background-color: $c-gray-secondary;
              color: $c-white;
              @include d-inline-flex-center;
              height: 40px;
              min-width: 40px;
              width: 40px;
            }
          }
        }
      }
    }
  }
}
