.DragDropBuilderComponent {
  width: 350px;
  .Title-header {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .sms-template-from {
    .header-section {
      .Merge-fields-wraper {
        padding: 7px 0px 8px 0px;
        cursor: move;
      }
      .Merge-fields-wraper:hover {
        background-color: #a9a9a975;
        border-radius: 6px;
      }
    }
    display: block;
    min-width: 16rem;
    padding-left: 1rem;
  }
  .Merge-fields-section {
    max-height: 11rem;
    min-height: 11rem;
    overflow: auto;
    border: 1px solid #f6f6f6;
    border-radius: 10px;
  }
  color: rgb(0, 0, 0);

  .contact-from-sms-template-from {
    .Merge-fields-section {
      min-height: 184px;
      overflow-x: hidden;
      height: 281px;
      overflow: auto;
      min-width: 315px;
      width: 315px;
      @include tablets-max {
        width: 100%;
      }
      @include phones-s-max {
        width: 100%;
      }
      .Merge-fields-wraper {
        font-size: $fz-default;
        font-weight: 600;
        display: flex;
        height: 2.5rem;
        padding-left: 21px;
        cursor: move;
        align-items: center;
        border-radius: 5px;
        @include rtl {
          padding-left: 0px;
          padding-right: 21px;
        }
      }

      .Merge-fields-wraper:hover {
        background-color: #e0e0e0;
      }
    }
    padding-left: 24px;
    @include rtl {
      padding-left: 0px;
      padding-right: 24px;
    }

    @include tablets-max {
      width: 100%;
      margin-top: 24px;
      padding-left: 0px;
    }
    @include phones-s-max {
      width: 100%;
      margin-top: 24px;
      padding-left: 0px;
    }
    .header-section {
      .Title-list {
        display: inline-flex;
        width: 100%;
        justify-content: flex-start;
        padding-left: 1rem;
        padding-right: 1rem;
      }

      .Title-list:hover {
        color: rgb(82, 197, 214);
      }
      border-radius: 10px;
      min-width: 315px;
      width: 315px;
      border-left: 2px solid #f5f5f5;
      border-bottom: 2px solid #f5f5f5;
      border-right: 2px solid #f5f5f5;
      @include tablets-max {
        min-width: 315px;
        width: 100%;
      }
      @include phones-s-max {
        min-width: 315px;
        width: 100%;
      }
    }
  }

  .foundlogodiv {
    display: inline-block;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: initial;
    .notfoundlogo {
      width: 13rem;
    }
  }
  .merged-fields-SMSTemplateComponent {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    .MuiButtonBase-root {
      background-color: #38d351;
      padding: 5px;
      border-radius: 9px;
      font-size: $fz-default;
      font-weight: 700;
      color: white;
      min-width: 11rem;
      display: flex;
      justify-content: space-around;
    }
  }
  .contact-from-sms-template-from {
    animation-duration: 1s;
    animation-name: slidein;
  }

  @keyframes slidein {
    from {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }
    to {
      opacity: 1;
    }
  }
}
.FormsId_conteanr_DragDropBuilderComponent {
  .FormsId_ButtonBase {
    .MuiButtonBase-root {
      height: 2.5rem;
      font-weight: 700;
      min-width: 134px;
      justify-content: inherit;
      padding-left: 10px;
      padding-right: 10px;
    }
    &.active {
      background-color: #e0e0e0;
      color: rgb(36, 116, 221);
    }
  }

  .FormsId_ButtonBase:hover {
    background-color: #e0e0e0;
  }
}
