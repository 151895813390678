.rotationCriteriaDialog-dialog-wrapper {
  .schema-setting-wrapper {
    border-top: none !important;
    //   .override-wrapper {
    //     .radio-btn {
    //       flex-direction: row-reverse;
    //       justify-content: flex-end;
    //     }
    //   }
  }
  .rotaion-content {
    border-top: none !important;
  }
  .title-text {
    color: #000000 !important;
    border-bottom: none !important;
    padding: 0 2rem !important;
    margin-bottom: 0 !important;
    .sub-title-wrapper {
      color: #5182de !important;
    }
  }
  .content-wrapper {
    padding: 0 2rem !important;
    margin-top: 1rem;
    .MuiTab-root {
      margin: 0 2% !important;
    }
  }
  .MuiDialogTitle-root {
    border-bottom: 1px solid $c-gray-dark !important;
    padding-left: 2rem;
  }
  .MuiDialog-paper {
  }
  .MuiDialogContent-root {
    padding: 1rem;
  }
  .dialog-content-wrapper {
    width: 100%;
    @include d-flex;
    flex-wrap: wrap;
    .order-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .mdi {
        margin: 0 10px;
        margin-top: 15px;
      }
    }
    .switch-btn {
      display: flex;
      justify-content: space-between;
    }
    .dialog-content-item {
      border-top: none;
      width: 50%;
      margin-top: 10px;
      margin-bottom: 0.5rem;
      @include phones-max {
        width: 100%;
        .rotationCriteriaDialog-dialog-wrapper .dialog-content-wrapper .dialog-content-item {
          padding: 15px;
        }
      }
      padding: 0 0.5rem;
      .reminder-wrapper {
        display: inline-flex;
        align-items: flex-end;
        // display: grid;
        width: 100%;
        // grid-template-rows: repeat(1, auto);
        // grid-template-columns: repeat(3, auto);
        .section-to {
          margin: 0.5rem;
          padding: 0.25rem;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.75rem;
          letter-spacing: 0px;
          color: #3a3a3c;
          opacity: 1;
        }
        .input-range {
          width: 50%;
        }
      }
      .Requierd-Color::after {
        content: '*';
        color: $c-red;
      }
    }
    .title-wrapper {
      @include d-flex;
      margin-bottom: 0.5rem;
      padding: 0 0.5rem;
      .title-text {
        font-weight: $fw-simi-bold;
      }
    }
  }
  .capitalizeTextEn {
    text-transform: capitalize;
  }
  .invalid {
    border-color: #f44336 !important;
    border: 1px solid;
    border-radius: 0.5rem;
  }
}
