.user-groups-view {

  
    .b-top {
      border-top: 1px #F1F2F2 solid;
    }
  
    .b-bottom {
      border-bottom: 1px #F1F2F2 solid;
    }
    
    .light-text {
      color: #475467;
    }
  
    .dialog-title-wrapper{
      padding-bottom: 1rem !important;
    }
  
    .MuiDialogContent-root{
      padding-top: 0px !important;
      border-top: 1px #F1F2F2 solid;
    }
  
    .table-capsule{
      padding: 2px 10px;
      border-radius: 9999px;
      border: 1px solid #EAECF0;
      background:  #F9FAFB;
    }
  }