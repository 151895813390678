.leads {
  .lead-filters {
    text-align: center;
    display: flex;
    justify-content: center;
    justify-items: center;
    margin: 0.5rem;
  }
}
.property_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
}

.lost-leads-wrapper {
  .date-range-picker-component {
    max-width: unset;
  }
}
.is-Fav-heart {
  display: flex;
  cursor: pointer;
  justify-content: center;
  &.lead {
    justify-content: flex-end;
    margin-top: 7px;
    position: absolute;
    right: 8%;
    top: 5px;
    @include rtl {
      right: unset;
      left: 8%;
    }
  }
  .mdi:before {
    color: red !important;
  }
}
