.SystemTemplate {
  --foundation-orangeorange-50: rgba(252, 238, 234, 1);
  --foundation-orangeorange-100: rgba(245, 204, 189, 1);
  --foundation-orangeorange-200: rgba(241, 179, 157, 1);
  --foundation-orangeorange-300: rgba(234, 144, 112, 1);
  --foundation-orangeorange-400: rgba(230, 122, 85, 1);
  --foundation-orangeorange-500: rgba(224, 89, 42, 1);
  --foundation-orangeorange-600: rgba(204, 81, 38, 1);
  --foundation-orangeorange-700: rgba(159, 63, 30, 1);
  --foundation-orangeorange-800: rgba(123, 49, 23, 1);
  --foundation-orangeorange-900: rgba(94, 37, 18, 1);
  --foundation-blueblue-50: rgba(235, 241, 251, 1);
  --foundation-bluelight: rgba(235, 241, 251, 1);
  --foundation-blueblue-100: rgba(194, 211, 243, 1);
  --foundation-bluelight-hover: rgba(226, 234, 249, 1);
  --foundation-blueblue-200: rgba(165, 190, 238, 1);
  --foundation-bluelight-active: rgba(194, 211, 243, 1);
  --foundation-blueblue-300: rgba(124, 161, 230, 1);
  --foundation-bluenormal: rgba(59, 114, 217, 1);
  --foundation-blueblue-400: rgba(98, 142, 225, 1);
  --foundation-bluenormal-hover: rgba(53, 103, 195, 1);
  --foundation-blueblue-500: rgba(59, 114, 217, 1);
  --foundation-bluenormal-active: rgba(47, 91, 174, 1);
  --foundation-blueblue-600: rgba(54, 104, 197, 1);
  --foundation-bluedark: rgba(44, 86, 163, 1);
  --foundation-blueblue-700: rgba(42, 81, 154, 1);
  --foundation-bluedark-hover: rgba(35, 68, 130, 1);
  --foundation-blueblue-800: rgba(32, 63, 119, 1);
  --foundation-bluedark-active: rgba(27, 51, 98, 1);
  --foundation-blueblue-900: rgba(25, 48, 91, 1);
  --foundation-bluedarker: rgba(21, 40, 76, 1);
  --basewhite: rgba(255, 255, 255, 1);
  --gray-200: rgba(234, 236, 240, 1);
  --gray-900: rgba(16, 24, 40, 1);
  --gray-600: rgba(71, 84, 103, 1);
  --gray-700: rgba(52, 64, 84, 1);
  --text-xl-semibold-font-family: 'Inter-SemiBold', Helvetica;
  --text-xl-semibold-font-weight: 600;
  --text-xl-semibold-font-size: 20px;
  --text-xl-semibold-letter-spacing: 0px;
  --text-xl-semibold-line-height: 30px;
  --text-xl-semibold-font-style: normal;
  --text-sm-medium-font-family: 'Inter-Medium', Helvetica;
  --text-sm-medium-font-weight: 500;
  --text-sm-medium-font-size: 14px;
  --text-sm-medium-letter-spacing: 0px;
  --text-sm-medium-line-height: 20px;
  --text-sm-medium-font-style: normal;
  --shadow-lg: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

  display: flex;
  .side-menu {
    height: 100%;
    width: 250px; /* Adjust the width as needed */
    min-height: calc(100vh - 205px);
    top: 0;
    border-radius: 0px 40px 20px 00px;
    left: 0;
    background-color: #333;
    padding-top: 20px;
  }
  .menu-item {
    padding: 10px 20px;
    color: #fff;
    border-radius: 30px;
    text-decoration: none;
    display: block;
    transition: background-color 0.3s;
  }

  .menu-item:hover {
    background-color: #555;
  }

  .content {
    padding: 20px;
  }
}

.characterSort {
  text-transform: capitalize;
}

.folder-title {
  font-weight: 600;
  color: #0f1728;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
}
.folder-sub-title {
  font-weight: 400;
  color: #475466;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}
.main-folder-title {
  padding-left: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-right: 2rem;
}

.TeamplateFolder-wraper {
  .folder-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: auto;
    max-height: 23.2rem;
    overflow-x: hidden;
    justify-content: space-evenly;
  }
  /* Style the folder card container */
  .folder-card {
    background-color: #f0eeeead;
    border: 1px solid #cccccc59;
    border-radius: 8px;
    width: 13rem;
    padding: 10px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 11px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
  }

  .folder-card:hover {
    transform: translateY(-5px);
  }

  /* Style the folder icon */
  .folder-icon img {
    width: 50px;
    height: 50px;
  }

  /* Style the folder content */
  .folder-content {
    margin-left: 15px;
    width: 100%;
    @include rtl {
      margin-left: 0px;
      margin-right: 15px;
    }
  }

  .folder-content h3 {
    margin: 0;
    font-size: 18px;
  }

  .folder-content p {
    margin: 0;
    color: #888;
  }
}

.file-item {
  display: flex;
  flex-direction: column;
  min-width: 383px;
  align-items: flex-start;
  gap: 24px;
  cursor: pointer;
  margin: 5px;
  padding: 24px;
  transition: background-color 1s ease, color 0.95s ease;
  position: relative;
  background-color: var(--basewhite);
  border-radius: 12px;
  border: 1px solid;
  border-color: var(--gray-200);
}
.file-item:hover {
  background-color: #9d9a9a14;
}
.file-item .heading-and-icon {
  align-items: center;
  gap: 20px;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  position: relative;
}

.file-item .folder {
  position: relative;
  width: 48px;
  height: 48px;
}

.file-item .heading {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  width: 210px;
  position: relative;
  flex: 1;
  font-family: var(--text-xl-semibold-font-family);
  font-weight: var(--text-xl-semibold-font-weight);
  color: var(--gray-900);
  font-size: var(--text-xl-semibold-font-size);
  letter-spacing: var(--text-xl-semibold-letter-spacing);
  line-height: var(--text-xl-semibold-line-height);
  font-style: var(--text-xl-semibold-font-style);
}

.file-item .frame {
  display: flex;
  align-items: flex-start;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.file-item .heading-and-number {
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  flex-grow: 1;
  display: flex;
  position: relative;
}

.file-item .text-wrapper {
  position: relative;
  align-self: stretch;
  margin-top: -1px;
  font-family: var(--text-sm-medium-font-family);
  font-weight: var(--text-sm-medium-font-weight);
  color: var(--gray-600);
  font-size: var(--text-sm-medium-font-size);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
  font-style: var(--text-sm-medium-font-style);
}

.file-item .number-and-badge {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.file-item .number {
  margin: 5px;
  position: relative;
  flex: 1;
  margin-top: -1px;
  font-family: var(--text-xl-semibold-font-family);
  font-weight: var(--text-xl-semibold-font-weight);
  color: var(--gray-900);
  font-size: var(--text-xl-semibold-font-size);
  letter-spacing: var(--text-xl-semibold-letter-spacing);
  line-height: var(--text-xl-semibold-line-height);
  font-style: var(--text-xl-semibold-font-style);
}

.file-item .dropdown-inquiries {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 20px;
  height: 50px;
  left: 358px;
  width: 50px;
  z-index: 3;

  @include rtl {
    right: 352px;
  }
}

.file-item .dots-vertical {
  position: relative;
  width: 20px;
  height: 20px;
}

.top-ppp {
  top: -25px !important;
}
.btns-folder {
  .btns.MuiButtonBase-root {
    border-radius: 30px;
    min-height: 26px;
    line-height: 100%;
    min-width: 85px;
    margin: 0 0.25rem;
    padding: 0.219rem 0.5rem;
    text-transform: initial;
    font-size: 13px;
    background-color: #fac515;
    font-weight: 900;
    color: #000000;
    white-space: nowrap;
  }
}

.TemplatesBuilder- {
  .date-range-picker-component .display-wrapper {
    display: flex;
    flex: 1 1 100%;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.25rem 0.5rem;
    max-height: 32px;
    min-width: 100px;
    border-radius: 0.5rem;
    border: 1px solid #c7c7cc;
  }
}

.folder-plus {
  .mdi:before {
    color: #ebc64f;
  }
}
