@import "../../../assets/theme-style/master.scss";

$footer-height: 76px;
$screen-target: 959px;

body.new-layout {
  .date-range-picker-container {
    position: relative;
    .rdrDateRangePickerWrapper {
      .rdrDefinedRangesWrapper {
        border-right-color: var(--colors-border-border-secondary);
        max-width: 192px;
        .rdrStaticRanges {
          padding: 12px 16px;
          gap: 4px;
          .rdrStaticRange {
            border-bottom: 0px solid transparent;
            background: var(--colors-background-bg-primary);
  
            &.rdrStaticRangeSelected {
              background: var(--colors-background-bg-active);
              border-radius: 6px;
              .rdrStaticRangeLabel {
                padding: 10px 16px;
                color: var(--colors-text-secondary-hover);
              }
            }
  
            .rdrStaticRangeLabel {
              border-radius: 6px;
              padding: 10px 16px;
              &:hover {
                background: var(--colors-background-bg-active);
              }
            }
            
          }
        }
        .rdrInputRanges {
          padding: 0px;
        }
      }
  
      .rdrCalendarWrapper.rdrDateRangeWrapper {
        margin-top: 20px;
        margin-bottom: $footer-height;
      }
    }
  
    .rdrDefinedRangesWrapper {
      background: var(--colors-background-bg-primary);
    }
    .rdrCalendarWrapper.rdrDateRangeWrapper {
      position: relative;
      background: var(--colors-background-bg-primary);
    }
  
    .rdrMonthAndYearWrapper {
      position: absolute;
      width: 100%;
      max-height: 24px;
      .rdrNextPrevButton.rdrPprevButton {
        background: transparent;
        i {
          display: none;
        }
        &:after {
          content: '\e930';
          font-family: 'icomoon';
          font-size: 20px;
          color: var(--colors-foreground-fg-secondary-700);
        }
      }
      
      .rdrNextPrevButton.rdrNextButton {
        background: transparent;
        i {
          display: none;
        }
        &:after {
          content: '\e931';
          font-family: 'icomoon';
          font-size: 20px;
          color: var(--colors-foreground-fg-secondary-700);
        }
      }
  
      .rdrMonthAndYearPickers {
        display: none;
      }
    }
  
    .rdrMonth {
      .rdrMonthName {
        text-align: center;
        color: var(--colors-foreground-fg-secondary-700);
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        padding: 6px 8px;
      }
      .rdrWeekDays {
        .rdrWeekDay {
          color: var(--colors-foreground-fg-secondary-700);
          font-weight: 500;
          font-size: 14px;
        }
      }
      .rdrDays {
        row-gap: 4px;

        .rdrInRange {
          background-color: var(--colors-background-bg-active) !important;
        }

        .rdrStartEdge, .rdrEndEdge {
          width: 40px;
          height: 40px;
          top: 6.2px;
          left: 2.5px;
        }

        .rdrDayNumber {
          width: 100%;
        }

        .rdrDayNumber span {
          color: var(--colors-text-secondary);
        }

        .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, 
        .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, 
        .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, 
        .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
          color: white;
        }

        .rdrDayPassive .rdrDayNumber span {
          color: var(--colors-text-disabled);
        }

        .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, 
        .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
          color: var(--colors-text-secondary) !important;
        }

        .rdrStartEdge + .rdrDayStartPreview.rdrDayEndPreview, 
        .rdrStartEdge + .rdrDayStartPreview {
          border-color: transparent !important;
        }

        .rdrEndEdge + .rdrDayStartPreview.rdrDayEndPreview, 
        .rdrEndEdge + .rdrDayStartPreview {
          border-color: transparent !important;
        }

        .rdrStartEdge.rdrEndEdge + .rdrDayEndPreview {
          border-color: transparent !important;
        }
      }
    }

    .custom-footer {
      position: absolute;
      bottom: 0;
      right: 0;
      border-top: 1px solid var(--colors-border-border-secondary);
      width: calc(100% - 192px);
      min-height: $footer-height;
      padding: 16px;
      display: flex;
      justify-content: space-between;
    }

    .rdrDayToday .rdrDayNumber span:after {
      content: '';
      position: absolute;
      bottom: 4px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 18px;
      height: 2px;
      border-radius: 2px;
      background: var(--colors-foreground-fg-brand-primary-600);
    }
  
    .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after, 
    .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after, 
    .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after, 
    .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
      background: var(--colors-foreground-fg-brand-primary-600);
    }
  }
}

body.new-layout__dark-mode {
  .date-range-picker-container {
    .rdrDateRangePickerWrapper {
      .rdrDefinedRangesWrapper {
        border-right-color: var(--colors-border-border-secondary);
        background: var(--colors-background-bg-primary); 
        max-width: 192px;
        .rdrStaticRanges {
          padding: 12px 16px;
          gap: 4px;
          .rdrStaticRange {
            border-bottom: 0px solid transparent;
            background: var(--colors-background-bg-primary);
  
            &.rdrStaticRangeSelected {
              background: var(--colors-background-bg-active);
              border-radius: 6px;
              .rdrStaticRangeLabel {
                padding: 10px 16px;
                color: var(--colors-text-secondary-hover);
              }
            }
  
            .rdrStaticRangeLabel {
              padding: 10px 16px;
              border-radius: 6px;
              &:hover {
                background: var(--colors-background-bg-active);
              }
            }
          }
        }
        .rdrInputRanges {
          padding: 0px;
        }
      }
    }
  
    .rdrCalendarWrapper.rdrDateRangeWrapper {
      background: var(--colors-background-bg-primary);
    }
  }
}

@media (max-width: $screen-target) {
  body.new-layout {
    .date-range-picker-container {
      .rdrDateRangePickerWrapper {
        flex-direction: column-reverse;
        margin-bottom: 133px;
        .rdrDefinedRangesWrapper {
          border: none;
          max-width: 100%;
          width: 100%;
          .rdrStaticRanges {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            padding-top: 0px;
            .rdrStaticRangeLabel {
              text-align: center;
            }
          }
        }
        .rdrCalendarWrapper.rdrDateRangeWrapper {
          margin-bottom: 0px;
          margin-top: 40px;
          .rdrMonthAndYearWrapper {
            top: -33px;
          }
        }
      }

      .prev-month-name {
        text-align: center;
        color: var(--colors-foreground-fg-secondary-700);
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        padding: 6px 8px;
        width: 100%;
        position: absolute;
        top: 8px;
      }

      .custom-footer {
        width: 100%;
        flex-direction: column;
        gap: 6px;

        span {
          width: fit-content;
          flex: 1;
        }

        .separator {
          flex: 0;
        }

        .actions {
          justify-content: space-between;
        }
      }
    }
  }
}
