.assign-agent-diaog {
  .content-wrapper {
    display: flex;
    flex-direction: column;
    .assignAgentName {
      padding-bottom: 2%;
    }
    .capacity-inputs {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
