.detailsDialog {
  .form-item {

    display: inline-flex; 
    /* flex: 0 1 auto; */
    display: inline-flex;
    flex: 0 1 auto ;
    padding: 0.25rem;
    width: 33%;
    justify-content: flex-start;
    margin: 0.1rem;
   
  }
}
