.units-cards-container {
    height: 90dvh;

    .makeStyles-container-14 {
        max-height: 75dvh;
    }

    @media (max-width: 1200px) {
        height: 85dvh;

        .makeStyles-container-14 {
            max-height: 65dvh;
        }
    }

    .body-image-item-wrapper {
        width: 100%;
        padding: 0 0.5rem;

        &:nth-child(1) {
            width: 115px;
            max-width: 115px;
            min-width: 115px;
        }

        .body-image-item {
            display: flex;
            width: 100%;
            position: relative;

            .body-title-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;

                .body-title {
                    font-size: 16px;
                    font-weight: bold;
                    padding: 1rem;
                    color: var(--c-primary);
                }
            }
            .body-status {
                width: 100%;
                min-width: 229px;
                border-radius: 0.3rem;
                // padding: 0.5rem;
                font-size: 14px;
                display: flex;
                // .body-status-type {
                //   color: $c-gray-primary;
                // }
              }
        }
    }
}