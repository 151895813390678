.ContactsInfoPublic-wraper {
  @include d-flex-center;
  width: 100%;
  .about {
    width: 67rem;
    border-bottom: 3px solid$c-gray-secondary;
    border-radius: 3px;
    font-size: $fz-24px;
    font-weight: bold;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    @include phones-max {
      width: 30rem;
    }
    @include laptops-max {
      width: 64rem;
    }
    @include tablets-max {
      width: 30rem;
    }
  }
}
.ContactsInfoPublicView-main-wrapper {
  min-width: 200px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  @include d-inline-flex;
  justify-content: center;
  flex-wrap: wrap;
  .main-title {
    display: inline-flex;
    justify-content: flex-start;
    width: 89%;
    font-size: $fz-16px;
    padding-bottom: 1rem;
    padding-top: 1rem;
    color: #8e8e93;
    font-weight: 800;
  }
  .item-details {
    color: black;
  }
  .city-d {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 25rem;
  }
  .item-value-impratent {
    font-size: $fz-16px;
    font-weight: 600;
    margin-top: 8px;
    color: $c-blue-lighter;
  }
  .item-value {
    font-size: $fz-16px;
    margin-top: 8px;
    font-weight: 600;
  }
  .details {
    margin-top: 0.8rem;
  }

  .name {
    width: 100%;
    font-size: $fz-18px;
    color: $c-black;
    font-weight: 700;
    padding-top: 1.2rem;
  }

  .about-Contacts-card-wrapper {
    position: relative;
    min-width: 200px;
    min-height: 100px;
    @include d-inline-flex-column-center;
    width: 504px;
    margin-top: 1rem;
    border-radius: 15px;
    border: 2px solid $c-gray-light;

    .information-Contacts-card-wrapper {
      min-width: 200px;
      @include d-inline-flex;
      width: 453px;
      border: 2px solid $c-gray-light;
      border-radius: 15px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      .Contact-profile-action-wrapper {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      .Type-wrapper {
        @include d-inline-flex;
        justify-content: space-between;
        width: 100%;
        padding-top: 1rem;
        flex-wrap: wrap;

        .contact-type {
          width: 71px;
          height: 30px;
          border-radius: 15px;
          color: $c-black;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          &.seller {
            background-color: #e8f0ff;
          }
          &.buyer {
            background-color: #ffe6e5;
          }
          &.landlord {
            background-color: #ffebcc;
          }
          &.tenant {
            background-color: #d6f4ee;
          }
        }
        .state-type {
          width: 71px;
          height: 30px;
          border-radius: 15px;
          color: #0c2a5a;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .dates {
        padding-top: 0.4rem;
        @include d-flex;
        @include flex-v-center;
      }
      .img-wrapper-con {
        width: 15rem;
        .img-wrapper {
          position: relative;
          .back-img {
            border-radius: 7px 0px 70px 15px;
            width: 62px;
            height: 175px;
            background-color: var(--c-primary);
          }
          .user-img {
            width: 128px;
            position: absolute;
            height: 128px;
            border: 4px solid var(--unnamed-color-ffffff);
            border: 4px solid #ffffff;
            border-radius: 100px;
            left: 23px;
            top: 16px;
          }
        }
      }
    }
    .details-Contacts-card-wrapper {
      min-width: 200px;
      min-height: 100px;
      width: 453px;
      height: 389px;
      padding: 20px;
      border-radius: 15px;
      padding: 9px 10px 8px 20px;
      margin-top: 22px;
      margin-bottom: 26px;
      border: 2px solid $c-gray-light;
    }
  }
  .call-Contacts-card-container {
    position: relative;
    //  @include d-inline-flex;
    width: 504px;
    display: block;
    //  border: 2px solid $c-gray-light;
    margin: 1rem 1rem 0rem 1rem;
    .card-line {
      width: 38px;
      margin: 0px 20px 0px 0px;
      @include rtl {
        margin: 0px 0px 0px 20px;
      }
      .timeline-wrapper {
        position: relative;
        padding: 2px 0;
      }
      .timeline-wrapper:before {
        content: '';
        background: #707070;
        width: 2px;
        height: 18rem;
        position: absolute;
        left: 18px;
      }

      .timeline-yr {
        background: var(--c-primary);
        border-radius: 100%;
        position: absolute;
        width: 38px;
        height: 38px;
        line-height: 75px;
        text-align: center;
      }
    }
    .call-Contacts-card-wrapper {
      min-width: 200px;
      //  min-height: 100px;
      display: inline-flex;
      flex: 0 1 auto;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      //  width: 500;
      // height: 679px;

      border-radius: 15px;

      .details-call-Contacts-card-wrapper {
        min-width: 435px;
        width: 448px;
        border-radius: 15px;
        padding: 9px 10px 8px 20px;
        border: 2px solid $c-gray-light;
        margin-bottom: 20px;
      }
    }
  }
}
.card-opp {
  display: inline-flex;
}
