.LocationImagesTabComponent {
  margin-top: 20px;
  .EmptyPage {
    width: 480px;
    height: 550px;
    margin: auto;
    background-repeat: no-repeat;
    background-image: url('../../../../../../../../assets/images/defaults/EmptyPage.png');
  }
}
