.leads-card-detaild-wrapper {
  padding-top: 2rem;
  height: 90%;
  // overflow-y: auto;
  margin-bottom: 60px;

  .contact-btn-div {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .lead-id-area {
      margin-bottom: 0.5rem !important;
    }

    .view-contact {
      width: 100%;
      background-color: #368750e3 !important;
      color: $c-white;
    }
  }

  .details-img {
    width: 100px;
    height: 100px;
    border-radius: 1rem;
    background-color: #f2f2f2;
  }

  .slider-data {
    overflow-y: auto;
    height: calc(100dvh - 600px); 

    &.is-referral {
      height: calc(100vh - 420px);
    }
  }

  .schedual-wrapper {
    overflow-y: auto;
    height: calc(100vh - 434px);
  }

  .items-title {
    color: $c-primary;
    font-weight: bold;
    font-size: $fz-16px;
  }

  .priority-types {
    display: flex;
    justify-content: center;
    align-items: center;

    .priority-type {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $c-gray;
    }
  }

  .high-badge {
    background-color: #ff827c;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
  }

  .medium-badge {
    background-color: var(--c-primary);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
  }

  .low-badge {
    background-color: #31c7aa;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
  }

  .calender-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;

    .calender-type-name {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $fz-14px;
    }

    .low-badge-time {
      background-color: #31c7aa;
      width: 3rem;
      height: 1rem;
      border-radius: 3rem;
      color: white;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .high-badge-time {
      background-color: #ff827c;
      width: 3rem;
      height: 1rem;
      border-radius: 3rem;
      color: white;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .medium-badge-time {
      background-color: var(--c-primary);
      width: 3rem;
      height: 1rem;
      border-radius: 3rem;
      color: white;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .badge-info {
      display: flex;
      flex-direction: column;

      .badge-name {
        font-size: $fz-14px;
      }

      .badge-time {
        font-size: $fz-default;
        color: $c-gray;
      }
    }
  }

  .data-schedule-button {
    display: grid;
    border-radius: 16px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    align-items: center;
    align-content: center;
    justify-items: center;

    .MuiButton-root {
      padding: 0.5rem;
      color: $c-gray-dark;
    }

    .data-file-button {
      &.selected {
        .MuiButton-label {
          color: $c-primary !important;
          font-weight: bold;
        }

        .btns.MuiButtonBase-root.theme-outline {
          border: 1px solid $c-primary;
        }
      }
    }

    .schedule-button {
      &.selected {
        .MuiButton-label {
          color: $c-primary !important;
          font-weight: bold;
        }

        .btns.MuiButtonBase-root.theme-outline {
          border: 1px solid $c-primary;
        }
      }
    }

    .details-button {
      &.selected {
        .MuiButton-label {
          color: $c-primary !important;
          font-weight: bold;
        }

        .btns.MuiButtonBase-root.theme-outline {
          border: 1px solid $c-primary;
        }
      }
    }
  }

  .properety-plan {
    font-size: $fz-14px;
    margin: 1rem;
    font-weight: bold;
  }

  .side-menu-actions {
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px -2px 6px #00000029;
    width: 100%;
    padding: 0.5rem;
    background-color: $c-white;
    z-index: 99;
    position: fixed;
    bottom: 0;
    width: 300px;

    .MuiButtonBase-root {
      padding: 0.5rem;
    }
  }

  .archive-bbt {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    position: absolute;
    right: 0;
    .btns-icon.MuiButtonBase-root.theme-solid {
      background-color: #3b72d9;
      color: #fff;
    }

    .btns-icon.MuiButtonBase-root {
      height: 30px;
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      border-radius: 100%;
      padding: 0.2rem;
    }
  }
}