.kenban-card {
    background: white;
    cursor: pointer;
    min-width: 240px;
    max-width: 281px;
    min-height: 220px;
    overflow: auto;

    .card-badge {
        background-color: #F9FAFB;
        width: fit-content;
        padding-block: 2px;
    }

    .assigne-avatar {
        display: block;
        max-height: 32px;
        max-width: 32px;
        overflow: hidden;
        background-position: center;
        background-size: cover;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.08);
    }

    .attachments-wrap {
        height: 20px;
        width: fit-content;
    }
    &.active-kanban-card {
        border: 2px solid #a6d4ed !important;
        transition: transform .5s;
        transform: scale(1.01);
        box-shadow: 0px 1px 8px #ddddddcf;
    }
}
    