.campaign-req-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0.1rem;
  align-items: center;
  gap: 1rem;
  align-self: center;
  padding: 1rem 1.5rem 2.5rem 1.5rem;

  .campaign-req-card {
    margin: 0.2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
    border-radius: 1rem;
    
    .active-req-card{
      border: 2px solid #a6d4ed  !important;
      transition: transform .5s;
      transform: scale(1.01);
      box-shadow: 0px 1px 8px #ddddddcf;
    }

    .cards-wrapper {
      padding: 0.75rem;
      background: #f5faff;
      border: 1px solid #d1e9ff;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 0.625rem;
      border-radius: 0.5rem;
      width: 17rem;
      height: -webkit-fill-available;
      display: flex;
      justify-content: center;
      align-items: center;
  


      .cards-header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1.5rem;
        align-self: stretch;

        .information-icon {
          position: absolute;
          cursor: pointer;

          img {
            vertical-align: super;
            width: 1rem;
            height: 1rem;
            flex-shrink: 0;
          }
        }

        .header-part1 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding: 0rem 1rem;

          .headerItem1 {
            width: 85%;
            display: flex;
            justify-content: center;
            align-items: center;

            .campaign-req-icon-part {
              display: flex;
              width: 3.5rem;
              height: 3.5rem;
              padding: 0.875rem;
              box-shadow: 0px 1px 5px #ddddddcf;
              border-radius: 1.75rem;
              background: #d1e9ff;

              .mdi:before {
                font-size: 22px;
              }
            }

            .campaign-req-id-part {
              display: flex;
              justify-content: center;
              justify-items: center;
              color: #000;
              font-style: normal;
              font-size: 1.5rem;
              font-weight: 600;
              line-height: 2rem;
            }
          }
        }

        headerPart2 {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .headerItem1 {
          width: 95%;

          .campaign-req-icon-part {
            display: flex;
            width: 3.5rem;
            height: 3.5rem;
            padding: 0.875rem;
            justify-content: center;
            align-items: center;
            border-radius: 1.75rem;
            background: #d1e9ff;
          }

          .campaign-req-id-part {
            display: flex;
            justify-content: center;
            justify-items: center;
            color: #000;
            font-style: normal;
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 2rem;
          }
        }

        .item-wrapper {
          margin: 1rem;
          width: 30%;
        }
      }

      .card-header2 {
        display: flex;
        justify-content: center;
        align-items: center;
        grid-gap: 1.5rem;
        gap: 1.5rem;
        align-self: stretch;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
      }

      .card-header3 {
        font-size: 0.85rem;
        text-align: center;
      }

      .campaign-req-card-footer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        span {
          font-weight: 600;
          font-size: 0.65rem;
          text-align: center;
        }
      }

      .card-capsule {
        display: flex;
        padding: 0.1rem 0.5rem;
        border-radius: 1.75rem;
        background: #e7f5ff;
        font-size: 0.7rem;
        font-weight: 600;
        color: #026AA2;
      }

      .card-seperator {
        height: 1px;
        background-color: #ddd;
        width: 85%;
        display: block;
        margin-bottom: 0.5rem;
      }

      .cards-body {
        display: flex;
        flex-direction: row;
        align-self: stretch;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        height: auto;
        padding: 0rem 1rem;
        gap: inherit;

        .body-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-style: normal;
          color: #000;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.25rem;

          .item-value {
            color: #0C111D;
            align-self: stretch;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.5rem;

            .leadId-part {
              display: inline-flex;
              justify-content: flex-start;
              align-items: center;
              align-self: stretch;
              grid-gap: 0.1rem;

              .copyImage-part {
                width: 1rem;
                height: 1rem;
              }
            }
          }
        }
      }


      &.active {
        box-shadow: 0px 1px 8px #000000cf;
      }
    }
  }
}
