.UnitTransactionsView {
    .m-auto {
        margin: auto;
    }

    .pointer {
        cursor: pointer;
    }

    .text-tertiary {
        color: #475467;
    }

    .b-bottom {
        border-bottom: 1px solid #eaecf0;
    }

    .fz-16 {
        font-size: 16px;
        line-height: 22px;
    }

    .radius-md {
        border-radius: 6px;
    }

    .bg-light-gray {
        background-color: #fafafa;
    }

    .w-min-unset {
        min-width: unset;
    }

    .file-item-container {
        display: grid;
        grid-template-columns: auto 20% 20%;

        .file-icon-wrapper {
            .file-icon {
                width: 32px;
            }

            .file-size {
                color: #475467;
            }

            .file-title {
                font-size: 10px;
            }
        }
    }

    .option-button {
        width: 160px;
        height: 100px;
        border: 2px solid #aeaeb2;
        border-radius: 8px;
        color: #aeaeb2;

        .mdi::before {
            font-size: 1.5rem;
        }

        &.is-active {
            color: #2153b1;
            border: 2px solid #2153b1;
        }

    }

    .devider {
        height: 1px;
        background: #e9e9e9;
    }

    .information-container {
        display: flex;
        margin-top: 0.5rem;
        padding: 0.5rem;
        border: 1px solid #c7c7cc;
        border-radius: 0.5rem;
    }

    .link-item-container {
        display: grid;
        grid-template-columns: auto 20%;
    }

    }
        .ecilipse-text {
            text-overflow: ellipsis;
            overflow: hidden;
            width: 75%;
            height: 1rem;
            white-space: nowrap;
            margin-bottom: 0px;
            font-size: 10px;
        }
