body.new-layout {
  font-family: Inter !important;

  // &.rtl {
  //   text-align: right;
  //   font-family: bla bla bla;
  // }
  .MuiTypography-body1,
  .MuiButtonBase-root,
  .MuiFormControl-root,
  .MuiSelect-root,
  .autocomplete,
  .MuiFormLabel-root,
  .MuiChip-label,
  .MuiInputBase-input,
  .MuiTableCell-root,
  .MuiTypography-root {
    font-family: Inter !important;
    // @include rtl {
    //   font-family: bla bla bla !important;
    // }
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e1e1e6;
    border: 0px none #ffffff;
    border-radius: 10rem;
    &:hover {
      background: #e1e1e6;
    }
    &:active {
      background: #e1e1e6;
    }
  }
  ::-webkit-scrollbar-track {
    box-shadow: none;
    background: #fff;
    border: none;
    &:hover {
      background: #fff;
    }
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  &::-webkit-scrollbar {
    width: 0.6em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e1e1e6;
    outline: 1px solid transparent;
    border-radius: 10rem;
  }

  &__dark-mode {
    ::-webkit-scrollbar-track {
        box-shadow: none;
        background: transparent;
        border: none;
        &:hover {
          background: transparent;
        }
      }
    ::-webkit-scrollbar-thumb {
      background: #333741;
      border: 0px none #ffffff;
      border-radius: 10rem;
      &:hover {
        background: #333741;
      }
      &:active {
        background: #333741;
      }
    }
    &::-webkit-scrollbar-thumb {
      background-color: #333741;
      outline: 1px solid transparent;
      border-radius: 10rem;
    }
  }
}
