.QA-view-wrapper {
  .date-type-select {
    .MuiSelect-select {
      border-radius: 5px 0px 0px 5px;
      border: 1px solid #f0f0f0;
      border-right: none; 
      height: 23px;
      left: 6px;
      position: relative;
      @include rtl {
        border-radius: 0px 5px 5px 0px;
        border: 1px solid #f0f0f0;
        border-left: none;
        right: 6px;
      }
    }

    .select-wrapper.MuiFormControl-root .selects .dropdown-icon-wrapper {
      bottom: 3px;
    }

    .select-wrapper.MuiFormControl-root .selects .MuiSelect-select {
      height: 22px;
      padding-left: 0.7rem !important;
      padding-top: 0.3rem !important;
      @include rtl {
        padding-right: 1rem !important;
        padding-left: 2rem !important;
      }
    }
  }
  .QA {
    justify-content: space-between;
  }
  .buttom-filters-section {
    margin-left: 1.4rem;
  }
  .activities-filter-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    @include laptops-max {
      flex-direction: column;
      align-items: flex-start;
      @include rtl {
        align-items: flex-end;
      }
    }
    .MuiSelect-select {
      font-size: $fz-default;
    }
  }
  .buttons {
    .reset {
      background-color: cadetblue;
    }
  }
}

.fileds {
  display: flex;
  gap: 15px;
  width: 60rem;
}
