.Location-Sharing-wraper {
  display: flex;
  justify-content: space-around;
  @include laptops-max {
    flex-wrap: wrap;
  }
  @include laptops-xl-max {
    flex-wrap: wrap;
  }
}
.w-min-unset{
  min-width: unset;
}

.view-wrapper-AddFormCountry, .lamdmarks-form1 {
  .Requierd-Color::after {
    content: ' *';
    color: $c-red;
  }
  .ReactQuill-wrpaer {
    max-width: 35rem;
  }

  .Open-map {
    width: 20px;
    height: 20px;
  }
  .quill {
    padding-top: 8px;
  }
  .ql-editor {
    max-height: 200px;
    min-height: 200px;
  }
  .ql-toolbar.ql-snow {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .ql-container.ql-snow {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .googlemaps-bbt {
    padding: 2.5px !important;
    border: 0px solid transparent !important;
    color: $c-primary;
    text-transform: capitalize;
    font-size: $fz-default;
    font-weight: 700;
    border-radius: 10px;
  }
  .Active-btt-menu {
    display: flex;
    justify-content: space-evenly;
    .menu-button {
      width: 180px;
      height: 86px;
      border: 1px solid #c7c7cc;
      border-radius: 10px;
      font-size: $fz-16px;
      flex-direction: column;
      justify-content: space-evenly;
      &.is-active {
        border: 1px solid var(--c-primary);
        color: var(--c-primary);
        background: #e8f0ff;
      }
    }
  }
}

.lamdmarks-form1 {
  max-width: 32rem;
}

.view-wrapper-editFormCountry, .lamdmarks-form2 {
  width: 50rem;
  .UploaderComponent-wrapered {
    .uploader-wrapper .uploader-container .dropzone-wrapper {
      height: 100%;
      border-radius: 0.5rem;
      max-height: 192px;
      overflow-y: auto;
      width: 100%;
      display: flex;
      flex: 0 1 auto;
      align-items: center;
      flex-wrap: wrap;
      max-width: calc(100% - 186px);
      padding: 0.25rem 0.25rem 0;
      position: relative;
      overflow: hidden;
      justify-self: center;
      @include phones-max {
        max-width: 77%;
      }
      @include tablets-max {
        max-width: 100%;
      }
      @include phones-s-max {
        max-width: 80%;
      }
      @include phones-xs-max {
        max-width: 77%;
      }
    }
    .box-theme-image {
      height: 60px !important;
      width: 83px !important;
      display: flex;
      background-size: cover;
      background-repeat: no-repeat;
      margin-bottom: 1rem;
    }
    .box-theme-component-wrapper {
      min-height: 14rem;
      width: 100%;
      position: relative;
      display: flex;
      flex: 1 1 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px dashed #c7c7cc;
      padding: 0.5rem;
      border-radius: 0.5rem;
    }
    .drop-here {
      padding: 0.7rem 0.7rem !important;
      display: flex;
      flex: 1 1 100%;
      align-items: center;
      pointer-events: none;
      width: 100%;
      justify-content: center;
    }
    .btns-icon.MuiButtonBase-root.theme-solid {
      background-color: var(--c-primary);
      color: $c-white;
      display: none;
    }
    .MuiInput-underline:before {
      left: 0;
      right: 0;
      bottom: 0;
      content: '\00a0';
      position: absolute;
      transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border-bottom: none;
      pointer-events: none;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: none;
    }
    .add-new-company .DatePicker .MuiInputBase-root {
      border-bottom: none;
    }
  }
  .MuiTabs-flexContainer {
    display: block !important;
  }
}
.view-wrapper-TabelRelated {
  position: relative;
  display: block;
  padding: 1rem;
  .PaginationComponent-wrapper {
    padding: 8px 14px 0px 14px;
    .pagination-component-wrapper {
      justify-content: center;
    }
  }
  .TabelRelated {
    min-height: 20rem;
    max-height: 60rem;
    overflow: auto;
  }
}
.SubCommunityView-wrapper , .landmarksView-wrapper{
  .images-gallery-filter-wrapper .filter-item {
    display: inline-flex;
    flex: 0 1 auto;
    width: 23%;
    min-width: 293px;
    padding: 0 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.landmarksView-wrapper .images-gallery-filter-wrapper {
  width: 60rem !important;
}
