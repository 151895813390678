.developer-profile-wrapper {
  padding: 0 0.5rem;
  .developers-tab {
    .developers-tab-filters-section {
      display: grid; 
      grid-template-rows: 50% 50%;
      gap: .1rem;
      justify-content: flex-end;
      @include tablets-max {
       // grid-template-columns: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
  }

  &:not(.developers-information-wrapper) {
    margin: 1rem 0.5rem;
  }

  .disable-style {
    background-color: #c7c7cc !important;
    cursor: initial;
    color: #ffffff !important;
  }
  .agreement-details-deveoloper-wrapper 
  {
    .agreement-details-add { 
      display: flex;
      justify-content: center ;
      .part1 {
        width: 100%;
        padding: .5rem ;
        display: inline-flex ;
        flex-wrap: wrap ;
        justify-content: center    ;
        align-items: center;
        .item { 
           width:25%;
           display: inline-flex;
           flex: 0 1 auto;
           padding: 0 0.2rem;
        }
        .addBtn
        {
         
          display: inline-flex;
          flex: 0 1 auto;
          padding: 0 0.5rem;
          justify-content: flex-start;


        }
        @include tablets-max {
          .item { 
            width:50%;
            display: inline-flex;
            flex: 0 1 auto;
            padding: 0 0.5rem;
            margin-bottom: .5rem ; 
         }
         .addBtn
         {
          
           display: inline-flex;
           flex: 0 1 auto;
           padding: 0 0.5rem;
           justify-content: flex-end;
 
 
         }
        }
        @include laptops-l-max 
        {
          .item { 
            width:50%;
            display: inline-flex;
            flex: 0 1 auto;
            padding: 0 0.5rem;
            margin-bottom: .5rem ; 
         }
         .addBtn
         {
          
           display: inline-flex;
           flex: 0 1 auto;
           justify-content: flex-end;
 
 
 
         }
        }
        @include laptops-max 
        {
        }
        @include laptops-max 
        {
         
        }
     
        @include phones-max {
           display: flex;
           flex-direction:  column;
          .item {
              width:  100%;
          
          }
          .addBtn
         {
          
           display: inline-flex;
           flex: 0 1 auto;
           padding: 0 0.5rem;
           justify-content: flex-end;
 
 
 
         }
        }
        @include phones-s-max {
          display: flex;
          flex-wrap: wrap ;
          justify-content: flex-start ;
          .item {
             width:  100%;
          }
          .addBtn
         {
          
           display: inline-flex;
           flex: 0 1 auto;
           padding: 0 0.5rem;
           justify-content: flex-end;
 
 
 
         }
        }
        

      }
    
   }

  }
  
  
  .childs-wrapper {
    border-radius: 1rem;
    padding: 0.5rem 0.5rem 1rem;
    border: 1px solid $c-gray-secondary;
    width: 100%;
    &.b-0 {
      border-width: 0;
    }
    .title-section {
      padding: 0 0.5rem;
      font-weight: $fw-simi-bold;
      font-size: $fz-16px;
      margin-bottom: 1rem;
    }
  }
  .form-item {
    @include d-inline-flex;
    width: 25%;
    @include laptops-xl-max {
      width: 33.3333%;
    }
    @include tablets-max {
      width: 50%;
    }
    @include phones-s-max {
      width: 100%;
    }
    padding: 0 0.5rem;
    margin-bottom: 1rem;
  }
  .tabs-content-wrapper {
    padding: 1rem 0.5rem 0;
    @include d-flex-h-center;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .filter-sections-wrapper {
    @include d-flex-v-center;
    .Disabled-color {
      width: 100%;
      display: flex;
      flex: 1 1 100%;
      align-items: center;
      min-height: 32px;
    }
    flex-wrap: wrap;
    .filter-section-item {
      @include d-inline-flex-v-center;
      margin-bottom: 0.5rem;
    }
  }

  .developer-profile-payables-wrapper {
    max-width: 700px;
    width: 100%;
  }
  .pigeon-attribution {
    display: none;
  }
 
  .btns.MuiButtonBase-root.c-success-light:disabled {
    background-color: $c-success-light !important;
  }

  .btns.MuiButtonBase-root.c-primary:disabled {
    background-color: $c-primary !important;
  }

  .btns.MuiButtonBase-root.c-black-light:disabled {
    background-color: $c-black-light !important;
  }

  .btns.MuiButtonBase-root.c-warning-light:disabled {
    background-color: $c-warning-light !important;
  }

}

.developers-view
 {
  .container-display{
    display: flex;
  }

  .customDatePicker {
    display: flex;
    align-items: center;
    width: max-content !important;
    justify-content: flex-end;

    .MuiInput-underline.Mui-disabled:before{
      border-bottom-style: none;
    }
    .date-type-select {
      .MuiSelect-select {
        border-radius: 5px 0px 0px 5px;
        border: 1px solid #f0f0f0;
        border-right: none;
        left: 6px;
        position: relative;
        @include rtl {
          border-radius: 0px 5px 5px 0px;
          border: 1px solid #f0f0f0;
          border-left: none;
          right: 6px;
        }
      }

      .select-wrapper.MuiFormControl-root .selects .dropdown-icon-wrapper {
        bottom: 3px;
      }

      .select-wrapper.MuiFormControl-root .selects .MuiSelect-select {
        height: 22px;
        padding-left: 0.7rem !important;
        padding-top: 0.3rem !important;
        @include rtl {
          padding-right: 1rem !important;
          padding-left: 2rem !important;
        }
      }
    }

    .date-range-picker-component {
      min-height: 32px;
      
    }

    .date-range-picker-component .display-wrapper{
      border: 1px solid #f0f0f0 !important;
      padding: 0 0.5rem;
  }
    
  }
  .view-wrapper .header-section .filter-section .customDatePicker {
    width: 28rem;
  }
}
