.templates-preview-dialog {
  .se-toolbar-cover {
    display: contents !important;
  }

  .unit-template-action-btns {
    display: flex;
    width: 312px;
    justify-content: center;
    align-items: center;
    margin: 5px 0;

    .btns {
      width: 50%;
      height: 30px;
      margin: 0px 0.5rem !important;
    }

    .bg-share {
      border: 1px solid rgb(59, 114, 217);
      background: none;
      color: rgb(59, 114, 217);

      .mdi::before {
        font-size: $fz-14px;
        margin-left: 3px;
        font-weight: 600;
      }
    }
  }
  .template-image-wrapper {
    height: 550px;
    width: 100%;
    margin-bottom: 1rem;
    background-color: $c-black;
    border-radius: 1rem;
    @include d-flex-center;
    .template-image {
      max-width: 100%;
      max-height: 550px;
      border-radius: 1rem;
    }
    .template-video {
      max-width: 550px;
      width: 500px;
      height: 500px;
      border-radius: 1rem;
    }
  }
  .template-iframe-wrapper {
    padding: 1rem;
  }
}

#ifmcontentstoprint {
  box-shadow: 0px 0px 10px 0px #00000033;
  border: none;
  border-radius: 14px;
}

.print-icon-wwww {
  button.print-button {
    width: 100px;
    height: 100px;
  }
  span.print-icon,
  span.print-icon::before,
  span.print-icon::after,
  button.print-button:hover .print-icon::after {
    border: solid 4px #333;
    border-radius: 6px;
  }
  span.print-icon::after {
    border-width: 2px;
  }

  button.print-button {
    position: fixed;
    padding: 0;
    border: 0;

    border: none;
    background: transparent;
  }

  span.print-icon,
  span.print-icon::before,
  span.print-icon::after,
  button.print-button:hover .print-icon::after {
    box-sizing: border-box;
    background-color: #fff;
  }

  span.print-icon {
    position: relative;
    display: inline-block;
    padding: 0;
    margin-top: -68%;
    width: 60%;
    height: 35%;
    background: #fff;
    border-radius: 20% 20% 0 0;
  }

  span.print-icon::before {
    content: 'Print';
    position: absolute;
    bottom: 100%;
    left: 12%;
    right: 12%;
    height: 110%;

    transition: height 0.2s 0.15s;
  }

  span.print-icon::after {
    content: '';
    position: absolute;
    top: 55%;
    left: 12%;
    right: 12%;
    height: 0%;
    background: #fff;
    background-repeat: no-repeat;
    background-size: 70% 90%;
    background-position: center;
    background-image: linear-gradient(
      to top,
      #fff 0,
      #fff 14%,
      #333 14%,
      #333 28%,
      #fff 28%,
      #fff 42%,
      #333 42%,
      #333 56%,
      #fff 56%,
      #fff 70%,
      #333 70%,
      #333 84%,
      #fff 84%,
      #fff 100%
    );

    transition: height 0.2s, border-width 0s 0.2s, width 0s 0.2s;
  }

  button.print-button:hover {
    cursor: pointer;
  }

  button.print-button:hover .print-icon::before {
    height: 0px;
    transition: height 0.2s;
    content: '';
  }
  button.print-button:hover .print-icon::after {
    height: 120%;
    transition: height 0.2s 0.15s, border-width 0s 0.16s;
  }
}

.share-template-dialog {
  .send-to-fieldset {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    border: 1px solid #ededed;
    border-radius: 8px;
    min-height: 38px;
    padding: 10px;
  }

  .send-to-legend {
    width: fit-content;
    margin: 0;
    padding: 0px 4px;
    font-size: $fz-default;
    line-height: inherit;
    border: 0;
    color: $c-black;
  }
}
