.unitValidationContextDialogWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.actionButtonsUnitValidationDialogWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 4px;
  align-items: center;
  justify-content: space-between !important;
}

.potential-duplicated-units-cards {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  row-gap: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 30px 0px 20px 0px;
}

.copyToClipBoard {
  color: blue($color: #000000);
}

.blueText {
  color: #3b72d9;
}

.boldListingAgentText {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.confirmClaimDialog {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between !important;
  padding-top: 32px !important;

  
}
.claim-confirm{
display: flex !important;
padding: 10px  16px !important;
justify-content: center !important;
align-items: center !important;
gap:  6px !important;
border-radius:  8px !important;
border: 1px solid  #3B72D9 !important;
background:  #3B72D9 !important;
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important; 
width: 209px !important;
height: 44px !important;
}

.Claim-heading-icon{
display: flex;
width: 44px;
height: 44px;
padding: 8px;
justify-content: center;
align-items: center;
border-radius:  8px;
position: absolute;
right: 16px;
top: 16px;
border-radius:8px;
cursor: pointer;
}

.duplicate-card-wrapper {
  position: relative;
  display: flex;
  min-width: 230px;
  min-height: 259px;
  flex-shrink: 0;
  flex-wrap: wrap;
  border-radius: 16px;
  border: 1px solid #eaecf0;
  background: #fff;
  cursor: pointer;

  .Side_popup_btn {
    display: none;
    position: absolute;
    z-index: 2;
    background: #cedbf5;
    width: 32px;
    min-width: 32px;
    height: 40px;
    padding: 0px;
    bottom: 105px;
    right: 0px;
    border-radius: 10px 0px 0px 10px;
    box-shadow: 0px 2px 4px 0px rgba(16, 24, 40, 0.05);
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &:hover {
    .Side_popup_btn {
      display: inline-flex;
    }
  }

  &.duplicate-card-wrapper-selected {
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.3);
    border: 2px solid #eaecf0;
    transition: all 0.3s ease;
  }

  .duplicate-card-content {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    flex: 1 0 0;

    .section-one {
      display: flex;
      padding: 0px 8px;
      flex-direction: column;
      justify-content: center;
      gap: 8px;
      min-height: 142px;

      .property-name {
        color: #000;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
      }

      .city-name,
      .unit-number,
      .unit-type {
        color: #2d3e50;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }

      .idRef-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        .copy-Ref {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }

      .unit-type-wrapper {
        display: flex;
        gap: 8px;
        height: 22px;

        .unit-Operation {
          display: flex;
          padding: 2px 8px;
          align-items: center;
          border-radius: 9999px;
          border: 1px solid #b2ddff;
          background: #eff8ff;
          color: #175cd3;
          text-align: center;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
        }

        .unit-status {
          color: #3b72d9;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }

      .unit-facility {
        display: flex;
        align-items: center;
        gap: 8px;
        min-width: 223px;

        .text-icon {
          display: flex;
          align-items: center;
          gap: 4px;
          color: #475467;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }

    .section-two {
      display: flex;
      padding: 8px;
      justify-content: space-between;
      align-items: flex-end;
      align-self: stretch;
      border-radius: 8px;
      border: 0.811px solid #f0f0f0;
      background: #fff;

      .image-name-wrapper {
        display: flex;
        align-items: center;
        gap: 12px;

        .Avatar {
          height: 55px !important;
          width: 55px !important;
          max-width: 55px;
          min-width: 55px;
          background-color: #8e8e93;
          color: #ffffff;
          font-size: 1rem;
          border: 3px solid #fff;
          box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
            0px 2px 4px -2px rgba(16, 24, 40, 0.06);
          img {
            object-fit: fill;
          }
        }
        .image-agent {
          display: flex;
          width: 40px;
          height: 40px;
          justify-content: center;
          border-radius: 9999px;
        }

        .name-agent-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .name-agent {
            color: #000;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
          }

          .label-agent {
            color: #2d3e50;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }

      .social-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        .listingAgentIcon {
          display: flex;
          width: 24px;
          height: 24px;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          background: rgba(59, 114, 217, 0.1);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          cursor: pointer;
        }
      }

      .no-agent {
        color: #000;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }
}

.claim-dialog-wrapper {
  display: flex;
  flex-direction: column;
  .claim-background-pattern {
    background-image: url('./Icons/backgroundCircleSm.svg');
    width: 336px;
    height: 336px;
    position: absolute;
    left: -28px;
    top: -108px;
  }
 
  .claim-dialog-content {
    display: flex;
    padding: 24px 24px 0px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    .claim-icon-wrapper {
      display: flex;
      width: 48px;
      height: 48px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      border-radius: 9999px;
      background: #dcfae6;
    }
    .claim-title-Wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      .claim-title {
        color: #101828;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
      }
      .claim-sub-title {
        color: #475467;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}
