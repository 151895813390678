$primary-color: #68b7ff;
$primary-color-text: #0090ff;
$secondary-color: #e7eaee;
$hover-color: darken($secondary-color, 10%);
$error-color: #ff0000;
$missing-activity-bg: #858f8f29;
$confirm-message-color: red;

.stages-breadcrumb {
    list-style-type: none;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 1rem 0;
}

.v1 .breadcrumb-level {
    margin: 3px;
    cursor: pointer;
    position: relative;
    padding: 4px 6px;

    &.past-stage {
        cursor: not-allowed;
    }

    &.not-allowed{
        cursor: not-allowed;
    }

    &.disabled-event {
        pointer-events: none;
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        left: 0;
        height: 50%;
        width: 100%;
        background: $secondary-color;
        z-index: -1;
    }

    &::before {
        top: 0;
        transform: skew(20deg);
    }

    &::after {
        bottom: 0;
        transform: skew(-20deg);
    }
}

body[dir="rtl"] .v1 .breadcrumb-level {
    &::before {
        top: 0;
        transform: skew(-20deg);
    }

    &::after {
        bottom: 0;
        transform: skew(20deg);
    }
}

.breadcrumb-level {
    display: inline-block;
    position: relative;

    &:hover {

        &::before,
        &::after {
            background: $primary-color;
        }
    }

    &:has(> a.active) {

        &::before,
        &::after {
            background: $primary-color;
        }
    }

}

.breadcrumb-level.unit-stage.completed a{
    font-weight: 900;
}

.v1 .breadcrumb-level.past-stage::before,
.v1 .breadcrumb-level.past-stage::after {
    background: #c9d0ff;

    cursor: not-allowed;
}

.breadcrumb-level.finish-disabled{
    pointer-events: none;
    opacity: .7;
}

.breadcrumb-level a {
    display: inline-block;
    position: relative;
    line-height: 2.5;
    padding: 0 20px;
    color: black;
    text-decoration: none;
    white-space: nowrap;

    &.units-stage {
        padding: 0px !important;
    }

    &.active {
        color: #000000;
        font-weight: 700;

        &.unit-stage {
            color: #fff !important;
        }
    }
}

.stages-breadcrumb.v1 {
    margin: 25px 0;
}

.stages-list {
    line-height: 20px;

    .checkbox-groups-wrapper .form-control-label.MuiFormControlLabel-root .MuiFormControlLabel-label {
        font-size: 12px;
        text-transform: capitalize;
    }
}

.confirm-message {
    font-size: 14px;
    color: $confirm-message-color;
    margin: 15px 0 0 0;
    color: $primary-color-text;
}

.isMissing {
    .form-control-label.MuiFormControlLabel-root .MuiFormControlLabel-label {
        color: $error-color;
    }

    .checkbox-groups-wrapper.theme-default .checkbox-wrapper.Mui-checked .mdi,
    .checkbox-groups-wrapper.theme-default .checkbox-wrapper.MuiCheckbox-indeterminate .mdi {
        background-color: $error-color;
    }
}

.assignedToActivityClass {
    margin-bottom: 1rem;
}

.MissingStageActivity {
    background-color: $missing-activity-bg;
    border-radius: 5px;
    padding: 3px;
    margin: 10px;
}


.units-status-management-wrapper {
    .absolute-list {
        position: absolute;
        z-index: 10000;
        overflow: visible;
        background: white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        /* Optional: Add some shadow for better visibility */
        padding: 10px;
        /* Optional: Add some padding */
        border-radius: 5px;
        width: max-content;
    }

    .stages-breadcrumb {
        overflow: visible;
    }

    .absolute-list ul {
        list-style-type: none;
        padding: 0 !important;
    }

    // .completed{
    //     background-color: green;
    // }
}