.PropertyInformation {
  width: 75%;
  margin-top: 0.5rem;
  padding: 1rem;
  border-radius: 0.5rem;

  .card {
    .titleSection {
      line-height: 1.42857143;
      color: var(--c-primary);
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      display: block;
    }

    .PropertyInfoContant {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      .headtitle{
        font-weight: 600;
      }
      .sectionItem {
        width: fit-content;
        display: flex;
        margin: 0.5rem;
        font-size: .8rem ;
      }
    }
  }

  .sections-wrapper{
    display: flex;
    flex-wrap: wrap;
  }

  .capsules-container{

    .capsule-element{
      background-color: #ddd;
      border: none;
      color: black;
      padding: 5px 10px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      margin: 4px 2px;
      border-radius: 25px;
    }
  }
}


