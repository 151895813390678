.support-footer-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  border-top: 1px solid #eaecf0;
  position: relative;
  background: white;
  z-index: 100;
  .footer-content {
    margin-right: 5rem;
    margin-left: 5rem;
    padding: 2rem;
    font-weight: 500;
    font-size: $fz-14px;
    color: #98a2b3;
  }
}
