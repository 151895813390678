.TaskDetailsSideMenu {
  display: grid;
  grid-template-rows: 40px 100px 175px auto;
  height: 100%;

  .golden-gradiant {
    background: linear-gradient(45deg, #4a8b8b 0%, #0d3d8e);
  }



  .user-avatar {
    position: absolute;
    bottom: -40px;
    left: 16px;
    height: 85px;
    width: 85px;
    max-width: 85px;
    min-width: 85px;
    border: 4px solid #fff;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

    img {
      object-fit: fill;
    }
  }

  .status-label {
    border: #5393ff 1px solid;
    background: #e6efff !important;
    color: #044dca;

    &::before {
      content: '';
      background: #044dca;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      position: absolute;
      left: 8px;
    }
  }

  .task-details-container {
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }

  @media (min-width: 1440px) {
    .task-details-container {
      height: 38vh;
    }
  }

  @media (max-width: 1440px) {
    .task-details-container {
      height: 30vh;
    }
  }
}
