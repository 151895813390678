.audio-player-container {
    display: flex;
    height: 50px;
    position: relative;
    overflow: hidden;
    justify-content: flex-start;
    border-radius: 10px;

    audio {
        position: absolute;
        width: 107%;
        left: -8px;

    }

    audio::-internal-media-controls-download-button {
        display: none;
    }

    audio::-webkit-media-controls-enclosure {
        overflow: hidden;
    }

    audio::-webkit-media-controls-panel {
        width: calc(100% - 30px);
        /* Adjust if needed */
    }
}