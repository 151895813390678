.scroll-container {
    display: flex;
    overflow-x: auto;
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
    /* Adjust the visible area */
}

.scroll-container::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, and Opera */
}

.scroll-content {
    white-space: nowrap;
}