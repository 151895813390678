.units-bulk-assign-view-wrapper {
  @include d-flex-h-center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  position: relative;

  .section-seperator {
    &.mdi::before {
      @include rtl {
        transform: rotate(180deg);
      }

      position: absolute;
      top: 15%;
      font-size: $fz-24px;
    }
  }

  .bulk-section-wrapper {
    @include d-flex-column;
    @include d-inline-flex-column;
    min-width: 355px;
    width: 33%;
    max-width: 380px;

    @include laptops-xl-max {
      width: 25%;
    }

    @include laptops-l-max {
      width: 33.3333%;
    }

    @include laptops-max {
      width: 50%;
    }

    @include tablets-max {
      width: 100%;
    }

    @include phones-max {
      width: 100%;
      max-width: initial;
    }

    .bulk-header-section {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: $fz-18px;
      font-weight: 400;
    }

    .bulk-sub-header-section {
      color: $c-gray;
      font-size: $fz-14px;
    }

    .bulked-units-section {
      overflow-y: auto;
      height: calc(100vh - 270px);
      padding-right: 1rem;

      @include rtl {
        padding-right: 0;
        padding-left: 1rem;
      }

      .body-item-wrapper {
        display: flex;
      }

      .disabled {
        opacity: .5;
        pointer-events: none;
      }

      .cover-image {
        background-color: $c-gray-lighter;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 3.9rem;
        width: 105px;
        border-radius: 0.5rem;
      }

      .bulked-unit-item {
        cursor: pointer;
        overflow-y: auto;
        display: flex;
        position: relative;
        padding: 0.5rem;
        width: 100%;
        height: 5rem;
        background-color: $c-white;
        border-radius: 10px;
        border: 1px solid #4972d2;

        &:hover {
          background-color: #f6f6f6;
        }

        &.is-search {
          border: 2px solid $c-primary;
          background-color: $c-secondary-light;
        }

        .item-name {
          font-weight: bold;
        }

        .item-ref-no {
          font-size: $fz-14px;
          font-weight: bold;
        }
      }

      .success-bulked-unit-item {
        cursor: default;
        overflow-y: auto;
        display: flex;
        position: relative;
        padding: 0.5rem;
        width: 100%;
        height: 5rem;
        background-color: $c-green-light;
        border-radius: 10px;
        border: 2px solid $c-green-dark;

        &:hover {
          background-color: $c-green-light;
        }

        .checkbox-groups-wrapper.theme-default .checkbox-wrapper.Mui-checked .mdi,
        .checkbox-groups-wrapper.theme-default .checkbox-wrapper.MuiCheckbox-indeterminate .mdi {
          background-color: $c-gray-light;
          color: $c-black;
        }

        &.is-search {
          border: 2px solid $c-primary;
          background-color: $c-secondary-light;
        }

        .item-name {
          font-weight: bold;
        }

        .item-ref-no {
          font-size: $fz-14px;
          font-weight: bold;
        }
      }

      .failed-bulked-unit-item {
        cursor: pointer;
        overflow-y: auto;
        display: flex;
        position: relative;
        padding: 0.5rem;
        width: 100%;
        height: 5rem;
        background-color: $c-warning-light;
        border-radius: 10px;
        border: 2px solid $c-warning;

        &.is-search {
          border: 2px solid $c-primary;
          background-color: $c-secondary-light;
        }

        .item-name {
          font-weight: bold;
        }

        .item-ref-no {
          font-size: $fz-14px;
          font-weight: bold;
        }
      }

      .unit-fields-item {
        cursor: pointer;
        overflow-y: auto;
        display: flex;
        position: relative;
        padding: 0.5rem;
        width: 100%;
        height: 3rem;
        display: flex;
        justify-content: center;
        background-color: #efefef;
        border-radius: 10px;
        text-align: center;

        &:hover {
          background-color: #f6f6f6;
        }

        &.is-search {
          border: 2px solid $c-primary;
          background-color: $c-secondary-light;
        }

        &.is-cheked {
          background-color: $c-primary;
          color: $c-white;
        }

        .item-name {
          font-weight: bold;
          text-align: center;
        }

        .item-ref-no {
          font-size: $fz-14px;
          font-weight: bold;
        }
      }

      .success-bulked-property-item {
        cursor: default;
        overflow-y: auto;
        display: flex;
        position: relative;
        padding: 0.5rem;
        width: 100%;
        height: 5rem;
        background-color: $c-green-light;
        border-radius: 10px;
        border: 2px solid $c-green-dark;

        &:hover {
          background-color: $c-green-light;
        }

        .checkbox-groups-wrapper.theme-default .checkbox-wrapper.Mui-checked .mdi,
        .checkbox-groups-wrapper.theme-default .checkbox-wrapper.MuiCheckbox-indeterminate .mdi {
          background-color: $c-gray-light;
          color: $c-black;
        }

        &.is-search {
          border: 2px solid $c-primary;
          background-color: $c-secondary-light;
        }

        .item-name {
          font-weight: bold;
        }

        .item-ref-no {
          font-size: $fz-14px;
          font-weight: bold;
        }
      }

      .failed-bulked-property-item {
        cursor: pointer;
        overflow-y: auto;
        display: flex;
        position: relative;
        padding: 0.5rem;
        width: 100%;
        height: 5rem;
        background-color: $c-warning-light;
        border-radius: 10px;
        border: 2px solid $c-warning;

        &.is-search {
          border: 2px solid $c-primary;
          background-color: $c-secondary-light;
        }

        .item-name {
          font-weight: bold;
        }

        .item-ref-no {
          font-size: $fz-14px;
          font-weight: bold;
        }
      }

    }
  }

  .unit-fields-card-checkbox-wrapper {
    position: absolute;
    left: 0.5rem;
    margin-top: -0.2rem;

    .checkbox-groups-wrapper.theme-default .checkbox-wrapper.Mui-checked .mdi,
    .checkbox-groups-wrapper.theme-default .checkbox-wrapper.MuiCheckbox-indeterminate .mdi {
      background-color: $c-white;
      color: $c-black;
    }
  }

  .new-value-section {
    .label-wrapper {
      color: var(--c-primary);
      font-size: $fz-16px;
      font-weight: 500;
      margin-left: -1rem;

      @include rtl {
        margin-left: 0;
        margin-right: -1rem;
      }
    }
  }
}

.bulk-actions-wrapper {
  display: flex;
  justify-content: space-between;
}