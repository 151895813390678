.unitsModelsFields {
    .dialog-content-wrapper 
    {
        .dialog-content-item {
            width:  33% ;
            margin-bottom: 0.5rem;
            padding: 0 0.5re
    
        }

    }

}