@import "../../../../assets/theme-style/Helpers/Mixins/Mixins.Style.scss";
i {
  margin-right: 10px;
}
#navbar-animmenu {
  background: #133c89;
  overflow: hidden;
  position: relative;
  padding: 10px 0px;

  @include rtl {
    background: #133c89;
    overflow: hidden;
    position: relative;
    padding: 10px 0px;
    direction: rtl;
    display: flex;
  }
}
#navbar-animmenu ul {
  padding: 0px;
  margin: 0px;
  @include rtl {
    padding: 0px;
    margin: 0px;
    display: flex;
    direction: rtl;
  }
}

#navbar-animmenu ul li a i {
  margin-right: 10px;
}

#navbar-animmenu li {
  list-style-type: none;
  float: left;
  cursor: pointer;
}

#navbar-animmenu ul li a {
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-size: 15px;
  line-height: 45px;
  display: block;
  padding: 0px 20px;
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  position: relative;
}

#navbar-animmenu > ul > li.active > a {
  color: #5161ce;
  background-color: transparent;
  transition: all 0.7s;
}
@media only screen and (max-width: 1536px) {
  #navbar-animmenu > ul > li.active > a {
    color: #ffffff;
    background-color: #2153b1;
    transition: all 0.7s;
    border-radius: 10px;
  }
  #navbar-animmenu {
    background: #133c89;
    overflow: hidden;
    position: relative;
    padding: 10px 0px;
    height: unset;
    cursor: pointer;
  }
  .hori-selector {
    display: inline-block;
    position: absolute;
    height: 100%;
    top: 10px;
    left: 0px;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    background-color: #fff0 !important;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .hori-selector .right,
  .hori-selector .left {
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: #fff0 !important;
    bottom: 10px;
  }
  .hori-selector .right:before,
.hori-selector .left:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff0 !important;
}
}

#navbar-animmenu a:not(:only-child):after {
  content: "\f105";
  position: absolute;
  right: 20px;
  top: 10%;
  font-size: 14px;
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  transition: 0.5s;
}

#navbar-animmenu .active > a:not(:only-child):after {
  transform: rotate(90deg);
}
.hori-selector {
  display: inline-block;
  position: absolute;
  height: 100%;
  top: 10px;
  left: 0px;
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background-color: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.hori-selector .right,
.hori-selector .left {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: #fff;
  bottom: 10px;
}
.hori-selector .right {
  right: -25px;
}
.hori-selector .left {
  left: -25px;
}
.hori-selector .right:before,
.hori-selector .left:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #133c89;
}
.hori-selector .right:before {
  bottom: 0;
  right: -25px;
}
.hori-selector .left:before {
  bottom: 0;
  left: -25px;
}
