.landmark-school-form {
    background: #e8f0ff;
    border: 1px solid #ebebeb;
    border-radius: 0.5rem;

    h3{
        font-weight: 600;
        color: var(--c-primary);
        text-align: center;
    }
}
