.size-filter-container {
    width: 100%;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .styled-fieldset {
    border: none;
    padding: 10px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .slider-container {
    width: 100%;
    margin: 10px 0;
  }
  
  .inputs-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .size-input {
    width: 100px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .range-separator {
    font-weight: bold;
    font-size: 18px;
  }
  