.header-search-wrapper {
  @include d-inline-flex;
  margin-left: 10%;
  @include rtl {
    margin-left: 0;
    margin-right: 10%;
  }
  width: 70%;
  @include laptops-max {
    margin-left: 0.5rem;
    @include rtl {
      margin-left: 0;
      margin-right: 0.5rem;
    }
    width: 100%;
  }
  .header-search-btn {
    margin: 0 !important;
    border-radius: 0 0.5rem 0.5rem 0;
    min-width: 80px;
    @include rtl {
      border-radius: 0.5rem 0 0 0.5rem;
    }
  }
  .select-wrapper.MuiFormControl-root {
    min-width: 160px;
  }
  .input-wrapper.theme-default .text-field-wrapper .inputs .MuiInputBase-root {
    border-radius: 0.5rem 0 0 0.5rem;
    @include rtl {
      border-radius: 0 0.5rem 0.5rem 0;
    }
  }
}
