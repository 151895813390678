.uploader-wrapper {
  width: 100%;
  .file-input {
    display: none;
  }
  .counter-text {
    color: $c-secondary;
  }
  .drop-here {
    padding: 0 0.5rem;
    @include d-flex-v-center;
    pointer-events: none;
  }
  .MuiChip-root .MuiChip-avatar {
    width: 18px;
    height: 18px;
  }
  .as-overlay,
  .as-overlay-spinner {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.2);
    height: 100%;
    width: 100%;
    z-index: 3;
    @include rtl {
      right: 0;
      left: initial;
    }
  }
  .as-overlay {
    color: $c-gray-lighter;
  }
  .as-overlay-spinner {
    @include d-flex-center;
  }

  .GalleryComponent {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: space-around;
    video::-webkit-media-controls {
      visibility: visible;
    }
    video::-webkit-media-controls-enclosure {
      visibility: hidden;
    }
  }
  .All-documents {
    border: rgb(75, 75, 75) 0.1px solid;
    border-radius: 0.25rem;
  }

  .document-card{
    border: #b9b9b9fa 0.1px solid;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.377);
  }
  .cover-image {
    border-radius: .5rem .5rem 0 0;
  }
}

.file-icons-container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0.1rem;
}
.file-icons-container-with-download-icon {
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.1rem;
}
