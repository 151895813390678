.Contacts-Action-Dialogs-wrapper {
  .med {
    max-width: 933px;
  }
}
.ContactsActionEmailDialogsComponent {
  .autocomplete-contenear {
    .line {
      height: 20px;
      width: 50px;
      color: $c-danger;
    }
    display: flex;
    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
      padding-right: 2px;
    }
    .bbt-Action {
      .MuiButton-root {
        min-width: 39px !important;
        color: $c-primary !important;
      }
    }
  }
  .icon-close {
    background-color: $c-gray-secondary;
  }

  .actions-conteaner-wrapper {
    display: flex;
    justify-content: space-between;
    color: $c-primary !important;
    font-size: $fz-14px;
    padding-top: 35px;

    .file-uploader-coneaner {
      .MuiCircularProgress-indeterminate {
        width: 15px !important;
        height: 15px !important;
        margin-left: 10px !important;
        margin-top: 2px !important;
      }
    }

    .MuiButtonBase-root {
      border-radius: 20px !important;
      font-size: $fz-14px;
      -webkit-border-radius: 20px !important;
      -moz-border-radius: 20px !important;
      -ms-border-radius: 20px !important;
      -o-border-radius: 20px !important;
    }
    .space {
      padding-right: 30px;
      @include rtl {
        padding-left: 30px;
        padding-right: 0px;
      }
    }
  }

  .bbt-style-dawnlode {
    cursor: pointer !important;
  }

  .fileName-dw:hover {
    color: $c-green-dark;
    text-decoration: underline;
    cursor: pointer !important;
  }

  .bbt-style-remove {
    cursor: pointer !important;
    color: $c-danger;
  }
  .remove-b {
    cursor: pointer;
  }
  .List-Merge-fields {
    width: 34%;
    .input-wrapper.MuiFormControl-root {
      margin-bottom: 0rem !important;
    }
  }

  .Collapse {
    overflow: auto;

    .MuiList-subheader {
      padding-top: 0;
      border: 1px solid $c-gray-secondary;
      border-radius: 13px;
    }
    .MuiList-root {
      margin: 0;
      padding: 0;
      position: relative;
      list-style: none;
      overflow: auto !important;
      height: 11% !important;
    }

    .MuiCollapse-wrapperInner {
      height: 20% !important;
    }

    .MuiCollapse-container {
      max-height: 30px !important;
    }
    .MuiCollapse-entered {
      max-height: 190px !important;
    }
  }
  .MuiList-subheader {
    border-left: 1px solid $c-gray-secondary;
    border-bottom: 1px solid $c-gray-secondary;
    border-right: 1px solid $c-gray-secondary;
  }

  .Merge-fields-section {
    min-height: 272px;
    overflow-x: hidden;
    height: 281px;
    overflow: auto;
    min-width: 315px;
    width: 315px;
    @include tablets-max {
      width: 100%;
    }
    @include phones-s-max {
      width: 100%;
    }
    .Merge-fields-wraper {
      font-size: $fz-default;
      font-weight: 600;
      display: flex;
      height: 2.5rem;
      padding-left: 21px;
      cursor: move;
      align-items: center;
      border-radius: 5px;
      @include rtl {
        padding-left: 0px;
        padding-right: 21px;
      }
    }

    .Merge-fields-wraper:hover {
      background-color: #e0e0e0;
    }
  }
  .header-section {
    border-radius: 10px;
    min-width: 315px;
    width: 315px;
    border-left: 2px solid #f5f5f5;
    border-bottom: 2px solid #f5f5f5;
    border-right: 2px solid #f5f5f5;
    @include tablets-max {
      min-width: 315px;
      width: 100%;
    }
    @include phones-s-max {
      min-width: 315px;
      width: 100%;
    }
  }
  .Title-list {
    font-size: $fz-14px;
    font-weight: 600;
    padding-left: 11px;
    margin-top: 5px;
    @include rtl {
      padding-left: 0px;
      padding-right: 11px;
    }
  }
  .Title-header {
    font-size: $fz-14px;
    font-weight: 600;
    padding-bottom: 10px;
  }

  .Email-from {
    min-width: 501px;
    width: 501px;
    min-height: 273px;
    height: 388px;
    margin-top: 3px;
    width: 100%;
    .input-wrapper.MuiFormControl-root {
      margin-bottom: 0rem !important;
    }
    .ql-editor {
      min-height: 314px !important;
      max-height: 20rem;
    }
  }
  .contact-from {
    padding-left: 24px;
    @include rtl {
      padding-left: 0px;
      padding-right: 24px;
    }

    @include tablets-max {
      width: 100%;
      margin-top: 24px;
      padding-left: 0px;
    }
    @include phones-s-max {
      width: 100%;
      margin-top: 24px;
      padding-left: 0px;
    }
  }

  .Email-from-wraper {
    display: inline-flex;
    width: 100%;
    justify-content: space-around;
    position: relative;
    @include tablets-max {
      flex-wrap: wrap;
    }
    @include phones-s-max {
      flex-wrap: wrap;
    }
  }
}

.massage-style-box {
  display: flex;
  flex-direction: row-reverse;
}
.merged-fields-container
{
  .MuiCircularProgress-root 
  {
    width: 16px !important;
    height: 15px !important;
    margin: 0px 7px 0px 11px !important;
  }
}
