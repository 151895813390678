.accordion-component-wrapper {
  width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem;

  .accordion-header {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: $fz-24px;
    font-weight: 600;
    line-height: 32px;
    text-transform: uppercase;
  }

  .accordion-title-container {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  .accordion-title-container {
    padding-top: 1rem;
    padding-bottom: 1rem;

    .accordion-title {
      font-size: $fz-20px;
      font-weight: 500;
      line-height: 28px;
      color: #e15626;
      text-shadow: 0 0 black;
    }

    .minus-btn,
    .plus-btn {
      animation: fadeIn 0.4s;

      &::before {
        font-size: $fz-25px;
      }
    }

    .minus-btn {
      color: #98a2b3;
    }

    .plus-btn {
      color: #e0592a;
    }
  }

  .accordion-content {
    animation: fadeIn 0.5s;
  }

  .sun-editor {
    border: none;

    .se-resizing-bar {
      display: none;
    }
    .se-wrapper-inner {
      height: unset !important;
      max-height: unset !important;
    }
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}
