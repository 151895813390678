.Content_Card {
  position: relative;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  height: fit-content;
  min-height: 220px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
  background: #fcfcfd;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  p {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
}
.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .Header_Title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
}

.Info_box {
  display: flex;
  min-width: 92px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid #f2f4f7;
  background: #fff;
  box-shadow: 0px 1px 1px -1px rgba(16, 24, 40, 0.1),
    0px 1px 1px -1px rgba(16, 24, 40, 0.06);

  p {
    margin: 0px;
    font-size: 10px;
    font-weight: 500;
    line-height: 20px;
  }

  .Text_Icon {
    color: #101828;
    font-size: 12px;
    font-weight: 600;
    line-height: 28px;
  }
}

.Location_Wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}
.Location_Text {
  color: #101828;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.View_Type_Wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Capsules_container {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 8px;
  .Outlined_capsule__gray {
    display: flex;
    padding: 2px 8px;
    align-items: center;
    border-radius: 9999px;
    border: 1px solid #eaecf0;
    background: #f9fafb;
  }
}
