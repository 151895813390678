.add-new-company {
  .MuiIconButton-root {
    color: var(--c-primary) !important ;
  }
  .dropzone-wrapper {
    max-height: 171px !important ;
  }
  .box-theme-component-wrapper .btn-close {
    position: absolute;
    top: 0;
    right: 85px;
  }
  .MuiFormLabel-root {
    color: #3a3a3c;
    padding: 0;
    font-size: 1rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    margin-top: -2px;
    margin-left: 10px;
    @include rtl {
      margin-left: 0px;
      margin-right: 10px;
    }
  }
  .DatePicker {
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 0px solid red !important;
    }
    .MuiInputBase-root {
      min-height: 32px !important ;
      width: 100% !important ;
      background-color: #ffffff !important ;
      border: 1px solid #f0f0f0 !important ;
      color: #3a3a3c !important ;
      border-radius: 0.5rem;
      display: flex;
      flex: 1 1 100%;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
    }
    .MuiInput-underline:before {
      left: 0;
      right: 0;
      bottom: 0;
      content: '\00a0';
      position: absolute;
      transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border-bottom: none;
      pointer-events: none;
    }
    .MuiInput-underline:after {
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      position: absolute;
      transform: scaleX(0);
      transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      border-bottom: none;
      pointer-events: none;
    }
  }
  .MuiDialogTitle-root {
    border-bottom: 1px solid $c-gray-dark !important;
  }
  .MuiDialogContent-root {
    padding: 2rem 3rem 32px 3rem;
    max-width: 30rem;
  }
  .MuiFormControl-root {
    width: 100%;
  }
  .input-wrapper.MuiFormControl-root {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }
}
.UploaderComponent-wrapered {
  .uploader-wrapper .uploader-container .dropzone-wrapper {
    height: 100%;
    border-radius: 0.5rem;
    max-height: 92px;
    overflow-y: auto;
    width: 100%;
    display: inline-flex;
    flex: 0 1 auto;
    align-items: center;
    flex-wrap: wrap;
    max-width: calc(100% - 115px);
    padding: 0.25rem 0.25rem 0;
    position: relative;
    overflow: hidden;
  }
  .box-theme-component-wrapper {
    min-height: 150px;
    width: 100%;
    position: relative;
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed #c7c7cc;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }

  .MuiInput-underline:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: '\00a0';
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: none;
    pointer-events: none;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }
  .add-new-company .DatePicker .MuiInputBase-root {
    border-bottom: none;
  }
}
