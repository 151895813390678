.images-gallery-filter-wrapper {
  @include d-flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  .filter-item {
    @include d-inline-flex;
    width: 25%;
    min-width: 260px;
    padding: 0 0.5rem;
    margin-bottom: 0.5rem;
  }
  .title-text {
    font-weight: $fw-simi-bold;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
}
