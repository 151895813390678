.support-tickets-wrapper {
  background: '#f9f9f9';

  h1 {
    font-size: 1.25rem;
  }

  .ticket-type {
    font-weight: 900;
    color: #007610;
    justify-content: center;
    width: fit-content;
    margin: auto;
    margin-top: 1.5rem;
  }

  .bg-container {
    .bg-logo {
      position: absolute;
      z-index: 10;
      width: 24%;
      opacity: 0.1;
      right: 38%;
      top: 45%;
      filter: grayscale(0.4);
    }
  }

  .tickets-container {
    &::-webkit-scrollbar {
      display: none;
    }

    width: 100%;
    position: absolute;
    z-index: 11;
    display: flex;
    padding: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    overflow-y: scroll;
    max-height: 90.5vh;
  }

  .ticket-card {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 5px 5px #efefef;
    display: flex;
    display: flex;
    margin: 10px;
    overflow: hidden;
    width: 650px;
  }

  .ticket-card h6 {
    opacity: 0.6;
    margin: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .ticket-card h2 {
    margin: 10px 0;
    font-size: $fz-16px;
    font-weight: 600;
  }

  .ticket-preview {
    background-color: #ecf1ee;
    border-radius: 2px;
    padding: 10px;
    width: 25%;
    min-width: 25%;

    div {
      opacity: 0.6;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-size: x-small;
      background: white;
      border-radius: 5px;
      padding: 2px 10px;
      min-height: 20px;
      display: flex;
      align-items: center;
      width: fit-content;
      margin: auto;
    }
  }

  .ticket-info {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .upper-info {
      max-height: 100px;
      overflow: hidden;
    }

    h2 span {
      padding: 2px;
      background: #f6f6f7;
      border-radius: 5px;
      font-size: $fz-default;
      font-weight: 400;
    }

    p {
      color: gray;
    }
  }

  .card-bottom {
    border-top: 2px solid #ecf1ee;
    height: 2rem;
    margin-top: 15px;
    display: flex;
    align-items: center;
    font-size: $fz-10px;
  }

  .preview-icon {
    &::before {
      font-size: $fz-15px;
    }

    &.ticket-status::before {
      font-size: $fz-default;
    }

    &.coral {
      color: coral;
    }
    &.FireBrick {
      color: FireBrick;
    }
    &.LimeGreen {
      color: LimeGreen;
    }
    &.BlueViolet {
      color: BlueViolet;
    }
    &.Gold {
      color: Gold;
    }
    &.royalblue {
      color: royalblue;
    }

    &.red {
      color: $c-red;
    }
  }
}
