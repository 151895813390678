.units-profile-wrapper {
  padding: 0 0.5rem;

  .disable-style {
    background-color: #c7c7cc !important;
    cursor: initial;
    color: #ffffff !important;
  }

  .leadOwner {
    margin: 0.8rem;
    font-size: 0.9rem;
    color: var(--c-primary);
    .leadName {
      color: #8e8e93;
    }
  }
  .childs-wrapper {
    border-radius: 1rem;
    padding: 0.5rem 0.5rem 1rem;
    border: 1px solid $c-gray-secondary;
    width: 100%;
    &.b-0 {
      border-width: 0;
    }
    .title-section {
      padding: 0 0.5rem;
      font-weight: $fw-simi-bold;
      font-size: $fz-16px;
      margin-bottom: 1rem;
    }
  }
  .form-item {
    @include d-inline-flex;
    width: 25%;
    @include laptops-xl-max {
      width: 33.3333%;
    }
    @include tablets-max {
      width: 50%;
    }
    @include phones-s-max {
      width: 100%;
    }
    padding: 0 0.5rem;
    margin-bottom: 1rem;
  }
  .tabs-content-wrapper {
    padding: 1rem 0.5rem 0;
    @include d-flex-h-center;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .filter-sections-wrapper {
    @include d-flex-v-center;
    .Disabled-color {
      width: 100%;
      display: flex;
      flex: 1 1 100%;
      align-items: center;
      min-height: 32px;
    }
    flex-wrap: wrap;
    .filter-section-item {
      @include d-inline-flex-v-center;
      margin-bottom: 0.5rem;
    }
  }

  .unit-profile-payables-wrapper {
    max-width: 700px;
    width: 100%;
  }
  .pigeon-attribution {
    display: none;
  }
  .UnitProfileOpenHouseseaction {
    display: flex;
    flex: 1 1 100%;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    //    padding: 1rem;
    @include phones-s-max {
      justify-content: center;
    }
  }

  .btns.MuiButtonBase-root.c-success-light:disabled {
    background-color: $c-success-light !important;
  }

  .btns.MuiButtonBase-root.c-primary:disabled {
    background-color: $c-primary !important;
  }

  .btns.MuiButtonBase-root.c-black-light:disabled {
    background-color: $c-black-light !important;
  }

  .btns.MuiButtonBase-root.c-warning-light:disabled {
    background-color: $c-warning-light !important;
  }

  .popover-unit-share {
    position: absolute;
    border-radius: 0.5rem;
    max-height: 300px;
    overflow-y: auto;
    background-color: #fafafa;
    z-index: 1;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 0px 5px 1px #00000029;
    .btns.MuiButtonBase-root {
      display: block;
    }
  }
}

.sale-view,
.resale-view ,
.lease-view,
.contacts-view,
.property-view,
.leads-wrapper {

  .container-display{
    display: flex;
  }
  .resale-filters 
  {
    .location-filter 
    {
      .all-items-part
      {
      .address 
      {
        margin: .25rem ;
        display: inline-flex;
        justify-content: center;
        padding : 0.125rem  0.625rem  ; 
        align-items: center;
        border-radius:  624.938rem;
        border: 1px solid  #EAECF0;
        background:  #F9FAFB;
      }

    }
    .sub-items-part
    {
    .address 
    {
      margin: .25rem ;
      display: inline-flex;
      justify-content: center;
      padding : 0.125rem  0.625rem  ; 
      align-items: center;
      border-radius:  624.938rem;
      border: 1px solid  #EAECF0;
      background:  #F9FAFB;
    }

  }
  }


  }

  .customDatePicker {
    display: flex;
    align-items: center;
    width: max-content !important;
    justify-content: flex-end;

    .MuiInput-underline.Mui-disabled:before{
      border-bottom-style: none;
    }
    .date-type-select {
      .MuiSelect-select {
        border-radius: 5px 0px 0px 5px;
        border: 1px solid #f0f0f0;
        border-right: none;
        left: 6px;
        position: relative;
        @include rtl {
          border-radius: 0px 5px 5px 0px;
          border: 1px solid #f0f0f0;
          border-left: none;
          right: 6px;
        }
      }

      .select-wrapper.MuiFormControl-root .selects .dropdown-icon-wrapper {
        bottom: 3px;
      }

      .select-wrapper.MuiFormControl-root .selects .MuiSelect-select {
        height: 22px;
        padding-left: 0.7rem !important;
        padding-top: 0.3rem !important;
        @include rtl {
          padding-right: 1rem !important;
          padding-left: 2rem !important;
        }
      }
    }

    .date-range-picker-component {
      min-height: 32px;
      
    }

    .date-range-picker-component .display-wrapper{
      border: 1px solid #f0f0f0 !important;
      padding: 0 0.5rem;
  }
    
  }
  .view-wrapper .header-section .filter-section .customDatePicker {
    width: 28rem;
  }
}
