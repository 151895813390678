.filter-section-Primary {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 10px 20px 0;
  justify-content: flex-end;

  .location-view-search-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 25px;
    .location-wrapper{
      max-width: 1368px;
    }
  }

  .filter-sort-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;

    .sort-date-wrapper {
      display: flex;
      justify-content: flex-end;
      max-width: 415px;
      padding-right: 10px;
    }
  }
}

.button-actions-wrapper {
  display: flex;
  align-items: center;
  margin: 20px 25px;
}

.more-filter-wrapper {
  display: flex;
  .buttons-wrapper {
    display: flex;
    gap: 1rem;
    align-self: flex-end;
    margin-bottom: 9px;
  }
}

.primary-date-picker {
  margin-bottom: 12px;
}
