.message-header-wrapper {
  display: flex;
  align-items: center;

  .message-header,
  .message-title {
    min-height: 32px;
    width: 100%;
    border: 1px solid #f0f0f0;
    color: $c-black;
    border-radius: 0.5rem;
    display: flex;
    flex: 1 1 100%;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin-top: 3px;
    margin-bottom: 3px;
    padding: 5px 10px;
  }
  .message-chip {
    background-color: #e4e4e4;
    max-height: 25px;
  }

  label {
    min-width: 55px;
    margin-right: 5px;
    margin-left: 5px;
  }
}

.message-body {
  min-height: 250px;
  border: 1px solid #ccc;
  font-family: Helvetica, Arial, sans-serif;
  font-size: $fz-13px;
  line-height: 1.42;
  overflow-y: auto;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 5px;
  margin-bottom: 20px;
  .ql-toolbar {
    display: none;
  }
  .ql-container.ql-snow {
    border: none;
  }
}
