.pagination-wrapper-for-agent-history-tab {
  margin-top: 20px;
}

.global-history-component-table-wrapper {
  .header-table-cell {
    width: 35%;
    color: black;
    font-weight: 600;
    background-color: #e5e5ea;
    padding: 5px 10px;
  }

  .global-history-component-row {
    height: 40px;
    width: 100%;
    color: black;
    padding: 10px;
    cursor: pointer;
    &.odd-row {
      background-color: #fafafa;
    }

    &.even-row {
      background-color: #fff;
    }

    .table-body-cell {
      padding: 0;
    }
  }
}
