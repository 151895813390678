.learning-profile {
    display: flex;
    flex-direction: column;
    max-width: 90%;
    margin: auto;
    padding: 1rem;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 8px #eeeeee;
    border-radius: 10px;


    .learning-container {
        .learning-tab {
            display: flex;
            gap: .5rem;

            .tab-btn {
                all: unset;
                padding: .75rem 1.5rem;
                color: #007aff;
                background-color: #f0f0f5;
                border-radius: 5px;
                cursor: pointer;

                &[active = true] {
                    background-color: #007aff;
                    color: #fff;
                }
            }
        }

        .learning-title {
            font-size: 28px;
            font-weight: 600;
            margin: 2rem 0;
        }

        .learning-cards-container {
            display: flex;
            gap: 1rem;
            justify-content: space-evenly;

            .learning-card-item {
                flex: 1;
                background-color: #f0f0f5;
                padding: 2rem 1rem;
                border-radius: 10px;
                min-height: 150px;

                .learning-card-title {
                    font-size: 16px;
                    color: #555555;
                    text-align: center;
                }

                .learning-card-text {
                    text-align: center;
                    font-size: 14px;

                    &.number {
                        color: #007aff;
                        font-weight: 600;
                        font-size: 18px;
                    }
                }

            }
        }
    }
}