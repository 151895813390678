.units-information-wrapper {
  .view {
      display: flex;
      flex-direction: row-reverse;
        margin: 0.5rem;
  }

  .information-container{
    display: flex;
    margin-top: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #c7c7cc;
    border-radius: 0.5rem;
  }
}
