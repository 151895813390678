.ActivitiesType-View {
  .Requierd-Color::after {
    content: " *";
    color: $c-red;
  }

  .check-true {
    color: #31c7aa;
    width: 100%;
    display: inline-flex;
    justify-content: center;
  }

  .check-false {
    color: #c33c3c;
    width: 100%;
    display: inline-flex;
    justify-content: center;
  }

  .Filter-text {
    font-weight: 900;
    font-size: $fz-default;
    display: flex;
    align-items: center;
  }
}

.DialogComponent-ActivitiesType {
  .Requierd-Color::after {
    content: " *";
    color: $c-red;
  }

  border-bottom: 1px solid $c-black;
  color: $c-black;
  width: 100% !important;
  font-size: $fz-16px;
  padding: 0 0.5rem 0.5rem;
  margin-bottom: 0.5rem;
}

.wrapperClasses-ActivitiesType {
  .Requierd-Color::after {
    content: " *";
    color: $c-red;
  }

  .dialog-title-text {
    border-bottom: 0px solid $c-black !important;
    color: $c-black !important;
    font-size: $fz-16px !important;
    font-weight: bold !important;
    padding: 0.5rem 2rem 0.5rem !important;
    margin-bottom: 0.5rem;
  }
}

.DialogManagementViewComponent-wrapper {
  .activityType-fieldset {
    border-top: 1px solid #e3e3e3;
    border-radius: 5px;

    legend {
      width: unset;
      font-size: inherit;
      border-bottom: none;
      padding: 0px 5px;
      margin-bottom: 0px;
      color: black;
    }
  }

  .details-tab-radio-btns {
    padding: 0 !important;

    &::before {
      display: none;
    }

    .MuiTypography-body1 {
      font-size: unset;
    }
  }

  .Requierd-Color::after {
    content: " *";
    color: $c-red;
  }

  display: inline-flex;
  width: 100%;
  flex-wrap: nowrap;

  .format-wraper {
    padding: 3px 22px 14px 10px;
  }

  .activity-dialog-item {
    padding: 0.25rem 0.6rem;
  }

  .expired-period-component-wrapper {
    display: flex;
    align-items: flex-start;

    .autocomplete-wrapper {
      min-width: 59%;
      margin-left: 10px;
    }
  }

  .h-around {
    justify-content: space-around;
  }

  .align-center {
    align-items: center;
  }

  .MuiFormGroup-row {
    .MuiFormControlLabel-root {
      margin: 0;
    }
  }

  .checkbox-container {
    position: relative;
    padding: 1.5rem 3.5rem;
    border: #c33c3c 1px solid;
    border-radius: 10px;
    cursor: pointer;
  }

  .checkbox {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.check-box-wraper-ActivitiesType {
  .Requierd-Color::after {
    content: " *";
    color: $c-red;
  }

  // display: flex;
  // justify-content: space-between;
  display: grid;
  grid-template-columns: 50% 50%;
}

@media (max-width: 915px) {
  .check-box-wraper-ActivitiesType {
    grid-template-columns: auto;
  }
}

.radio-item-score {
  padding: 10px;
  border: #84693f 1px solid;
  border-radius: 5px;
  width: 35px;
  height: 40px;
  text-align: center;

  &.selected {
    background-color: #dcdcdc;
  }
}

.dialog-action-btn {
  .MuiButton-label {
    font-size: 12px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    font-family: sans-serif !important;
  }
}
