.lead-opportunities-logs-date-paker{
.customDatePicker {
    display: flex;
    align-items: center;
    width: max-content !important;
    justify-content: flex-start;
    width: 28rem;
    .MuiInput-underline.Mui-disabled:before{
      border-bottom-style: none;
    }
    .date-type-select {
      .MuiSelect-select {
        border-radius: 5px 0px 0px 5px;
        border: 1px solid #f0f0f0;
        border-right: none;
        left: 6px;
        position: relative;
        @include rtl {
          border-radius: 0px 5px 5px 0px;
          border: 1px solid #f0f0f0;
          border-left: none;
          right: 6px;
        }
      }
  
      .select-wrapper.MuiFormControl-root .selects .dropdown-icon-wrapper {
        bottom: 3px;
      }
  
      .select-wrapper.MuiFormControl-root .selects .MuiSelect-select {
        height: 22px;
        padding-left: 0.7rem !important;
        padding-top: 0.3rem !important;
        @include rtl {
          padding-right: 1rem !important;
          padding-left: 2rem !important;
        }
      }
    }
  
    .date-range-picker-component {
      min-height: 32px;
      
    }
  
    .date-range-picker-component .display-wrapper{
      border: 1px solid #f0f0f0 !important;
      padding: 0 0.5rem;
  }
    
  }
 
}