.camp-req-f1{
    .d-block{
        display: block;
    }
    .m-auto {
        margin: auto;
    }
    .input-fieldset {
        border: 1px solid #ededed;
        border-radius: 8px;
        min-height: 38px;
        padding: 10px;
    }

    .input-fieldset-legend {
        width: fit-content;
        margin: 0;
        padding: 0px 4px;
        font-size: 12px;
        line-height: inherit;
        border: 0;
        color: #000000;
    }

}
.camp-req-main-info-f1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    overflow-x: auto;
}

.camp-req-art-suggest-f1{
    overflow-x: auto;

    .uploader-type {
        margin: auto;
    }
    .counter-text {
        color: white !important;
    }
    
    .uploaded-files-container{
        max-width: 60% !important;
    }
    .Uploader {
        max-width: 50% !important;
        .drop-here{
            color: white !important;
        }
    }
}

.camp-task-f1 {
    width: 85%;
    margin: auto;

    .input-fieldset {
        height: 170px;
        overflow-y: auto;
    }
}
.camp-req-finance-f1 {
    overflow-x: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .withType {
        display: grid;
        grid-template-columns: 30% 40% 20% 10%;
    }
    
    .withoutType {
        display: grid;
        grid-template-columns: 40% 45% 15%;
    }
    .source-style {
        min-width: unset;
    }
}

.camp-req-f1 .form-actions-wrapper {
    flex: 0 1 auto;
    position: absolute;

    width: 100%;
    padding: 0 1rem;
    bottom: 0px;
    z-index: 8;
    background-color: #ffffff;
    padding: 0.6rem;
    padding-left: 1rem;
    padding-right: 1rem;
    box-shadow: 0px -2px 6px #00000029;
    border-radius: 20px 20px 0 0;
  }


