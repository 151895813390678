.sign-off-container {
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid #EAECF0;
    background: #F9FAFB;
    position: relative;

    .sign-off-header {
        p {
            text-align: center;
        }
    }

    .sign-off-check {
        position: absolute;
        top: 16px;
        right: 20px;
    }

    .uploader-wrapper{
        .uploader-chip{
            display: none;
        }
        .GalleryComponent{
            gap: 0px;
            grid-gap: 0px;
        }
        .MuiButton-label{
            color: #7F6944 !important;
            font-weight: 600;
        }
    }
}