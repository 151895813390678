.activities-view-wrapper {
  .activities-filter-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    @include laptops-max {
      flex-direction: column;
      align-items: flex-start;
      @include rtl {
        align-items: flex-end;
      }
    }
    .MuiSelect-select {
      font-size: $fz-default;
    }
  }
}
