.remiders-view-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  .reminders-wrapper {
    display: flex;
    padding-bottom: 1rem;
    .select-wrapper.MuiFormControl-root {
      min-width: 170px;
    }
    .reminders-section {
      display: flex;
      border: 1px solid $c-gray-light;
      border-radius: 0.5rem;
      height: 5rem;
    }
  }
}
.agent-info-sale-related-component-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.unitTransactionId {
  height: 3rem;
  line-height: 3rem;
  padding-left: 2rem;
}

.textArea-id {
  width: 0;
  height: 0;
  padding: 0;
  opacity: 0;
}

#copyIcon::before {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: 0.875rem;
  cursor: pointer;
  color: $c-primary;
}
