.units-card-detaild-wrapper {
  .details-img {
    width: 100px;
    height: 100px;
    border-radius: 1rem;
    background-color: #f2f2f2;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }

  
.side-menu-wrapper{
  overflow-y: auto;
max-height: 75dvh;
}
  .cards-header-wrapper {
    @include d-flex-h-center;
    flex-wrap: wrap;
    padding-top: 0.5rem;
    .card-header-item {
      @include d-inline-flex;
      padding: 0 0.5rem;
      margin: 0 0 0.5rem;
      min-height: 108px;
      width: 33.3333%;
      min-width: 130px;
      @include phones-max {
        width: 50%;
      }
      .card-header-btn {
        border-radius: 0.5rem;
        padding: 0;
        height: 100%;
        width: 100%;
      }
    }
    .cover-image {
      background-color: $c-gray-lighter;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 100%;
      width: 100%;
      min-height: 100px;
      border-radius: 0.5rem;
    }
  }
  .items-title {
    color: $c-primary;
    font-weight: bold;
    font-size: $fz-16px;
  }

  .priority-types {
    display: flex;
    justify-content: center;
    align-items: center;
    .priority-type {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $c-gray;
    }
  }
  .high-badge {
    background-color: #ff827c;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
  }
  .medium-badge {
    background-color: var(--c-primary);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
  }
  .low-badge {
    background-color: #31c7aa;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
  }
  padding-top: 2rem;
  .details-img {
    width: 100px;
    height: 100px;
    border-radius: 1rem;
    background-color: #f2f2f2;
  }
  .slider-data {
    overflow-y: auto;
    height: calc(100vh - 430px);
  }
  .schedual-wrapper {
    overflow-y: auto;
    height: calc(100vh - 434px);
  }

  .items-title {
    color: $c-primary;
    font-weight: bold;
    font-size: $fz-16px;
  }

  .priority-types {
    display: flex;
    justify-content: center;
    align-items: center;

    .priority-type {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $c-gray;
    }
  }

  .high-badge {
    background-color: #ff827c;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
  }

  .medium-badge {
    background-color: var(--c-primary);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
  }

  .low-badge {
    background-color: #31c7aa;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
  }

  .calender-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;

    .calender-type-name {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $fz-14px;
    }

    .low-badge-time {
      background-color: #31c7aa;
      width: 3rem;
      height: 1rem;
      border-radius: 3rem;
      color: white;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .high-badge-time {
      background-color: #ff827c;
      width: 3rem;
      height: 1rem;
      border-radius: 3rem;
      color: white;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .medium-badge-time {
      background-color: var(--c-primary);
      width: 3rem;
      height: 1rem;
      border-radius: 3rem;
      color: white;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .badge-info {
      display: flex;
      flex-direction: column;

      .badge-name {
        font-size: $fz-14px;
      }

      .badge-time {
        font-size: $fz-default;
        color: $c-gray;
      }
    }
  }

  .data-schedule-button {
    display: grid;
    border-radius: 16px;
    background-color: #fafafa;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    align-items: center;
    align-content: center;
    justify-items: center;
 

    .MuiButton-root {
      padding: 0.5rem;
      color: $c-gray-dark;
    }

    .data-file-button {
      &.selected {
        .MuiButton-label {
          color: $c-primary !important;
          font-weight: bold;
        }

        .btns.MuiButtonBase-root.theme-outline {
          border: 1px solid $c-primary;
        }
      }
    }

    .schedule-button {
      &.selected {
        .MuiButton-label {
          color: $c-primary !important;
          font-weight: bold;
        }

        .btns.MuiButtonBase-root.theme-outline {
          border: 1px solid $c-primary;
        }
      }
    }
    .details-button {
      &.selected {
        .MuiButton-label {
          color: $c-primary !important;
          font-weight: bold;
        }

        .btns.MuiButtonBase-root.theme-outline {
          border: 1px solid $c-primary;
        }
      }
    }
    .add-activity{
      &.selected {
        .MuiButton-label {
          color: $c-primary !important;
          font-weight: bold;
        }

        .btns.MuiButtonBase-root.theme-outline {
          border: 1px solid $c-primary;
        }
      }
    }
  }

  .properety-plan {
    font-size: $fz-14px;
    font-weight: bold;
    margin: 1rem;
  }

  .side-menu-actions {
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px -2px 6px #00000029;
    width: 100%;
    padding: 1rem;
    background-color: $c-white;
    z-index: 99;

    .MuiButtonBase-root {
      padding: 0.5rem;
    }
  }

  .archive-bbt {
    display: flex;
    justify-content: flex-end;

    .btns-icon.MuiButtonBase-root.theme-solid {
      background-color: var(--c-primary);
      color: $c-white;
    }

    .btns-icon.MuiButtonBase-root {
      height: 30px;
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      border-radius: 100%;
      padding: 0.2rem;
    }
  }

  .calender-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    .calender-type-name {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $fz-14px;
    }
    .low-badge-time {
      background-color: #31c7aa;
      width: 3rem;
      height: 1rem;
      border-radius: 3rem;
      color: white;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .high-badge-time {
      background-color: #ff827c;
      width: 3rem;
      height: 1rem;
      border-radius: 3rem;
      color: white;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .medium-badge-time {
      background-color: var(--c-primary);
      width: 3rem;
      height: 1rem;
      border-radius: 3rem;
      color: white;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .badge-info {
      display: flex;
      flex-direction: column;
      .badge-name {
        font-size: $fz-14px;
      }
      .badge-time {
        font-size: $fz-default;
        color: $c-gray;
      }
    }
  }
  .price-wrapper {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: $fz-14px;
    font-weight: bold;
    .Sale {
      color: #31c7aa;
    }
    .Rent {
      color: var(--c-primary);
    }
    .unit-price {
      padding-left: 0.2rem;
      padding-right: 0.2rem;
    }
    .for-lable {
      padding-right: 0.2rem;
      @include rtl {
        padding-left: 0.2rem;
      }
    }
  }
  .contact-id-wrapper {
    padding-bottom: 0.5rem;
    font-size: $fz-14px;
    min-width: 80px;
    font-weight: bold;
    display: flex;
    align-items: center;
    textarea {
      width: 0;
      height: 0;
      padding: 0;
      opacity: 0;
    }
    .contact-id {
      margin-left: 0.3rem;
      margin-right: 0.3rem;
    }
    .mdi::before {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      font-size: $fz-14px;
      cursor: pointer;
      color: $c-primary;
    }
  }
  .id-date-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include laptops-max {
      justify-content: initial;
    }
    align-items: center;
  }
  .flat-contents-wrapper {
    @include d-flex-v-center;
    width: 100%;
    min-width: 301px;
    padding: 0 0.25rem;
    flex-wrap: wrap;
    .flat-content-item {
      @include d-inline-flex-v-center;
      margin: 0 0.15rem 0.5rem;
      padding: 0.1rem 0.5rem;
      border-radius: 1rem;
      background-color: $c-gray-lighter;
      color: $c-black;
    }
  }
  .created-on {
    min-width: 140px;
    .mdi {
      vertical-align: bottom;
      padding-right: 0.5rem;
      @include rtl {
        padding-right: 0;
        padding-left: 0.5rem;
      }
      &::before {
        font-size: $fz-16px;
      }
    }
  }
  .data-schedule-button {
    display: grid;
    border-radius: 16px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    align-items: center;
    align-content: center;
    justify-items: center;
    .MuiButton-root {
      padding: 0.5rem;
      color: $c-gray-dark;
    }
    .data-file-button {
      &.selected {
        .MuiButton-label {
          color: $c-primary !important;
          font-weight: bold;
        }
        .btns.MuiButtonBase-root.theme-outline {
          border: 1px solid $c-primary;
        }
      }
    }
    .schedule-button {
      &.selected {
        .MuiButton-label {
          color: $c-primary !important;
          font-weight: bold;
        }
        .btns.MuiButtonBase-root.theme-outline {
          border: 1px solid $c-primary;
        }
      }
    }
    .details-button {
      &.selected {
        .MuiButton-label {
          color: $c-primary !important;
          font-weight: bold;
        }
        .btns.MuiButtonBase-root.theme-outline {
          border: 1px solid $c-primary;
        }
      }
    }
  }

  .properety-plan {
    font-size: $fz-14px;
    font-weight: bold;
    margin: 1rem;
  }

  .side-menu-actions {
    position: absolute;
    bottom: 0;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px -2px 6px #00000029;
    width: 100%;
    padding: 1rem;
    background-color: $c-white;

    .MuiButton-root {
      padding: 0.5rem;
    }
  }
  .archive-bbt {
    display: flex;
    justify-content: flex-end;
    .btns-icon.MuiButtonBase-root.theme-solid {
      background-color: var(--c-primary);
      color: $c-white;
    }
    .btns-icon.MuiButtonBase-root {
      height: 30px;
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      border-radius: 100%;
      padding: 0.2rem;
    }
  }
  .status-wrapper {
    @include d-flex-center;
    width: 100%;
    padding-left: 1.3rem;
    padding-right: 1.3rem;
    margin-top: -0.5rem;
    .body-status {
      @include d-flex;
      border-radius: 0.5rem;
      width: 100%;
      font-weight: $fw-simi-bold;
    }
  }
}
.status-wrapper-text {
  color: $c-primary !important;
}

.status-wrapper-text-pen {
  color: $c-ornage !important;
}
