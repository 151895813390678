.properties-cards-wrapper {
  @include d-flex-h-center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 1rem;
  .properties-card-wrapper {
    .properties {
      top: 5%  !important;
    }

    .publish  {
      background: #66b94d !important;
      top: 11%   !important;
      right: -3% !important ; 
    }
    
    .publish-only  {
      background: #66b94d !important;
      top: 6%   !important;
    }
    .cards-wrapper {
      @include d-flex-column;
      position: relative;
      border: 1px solid $c-gray-secondary;
      border-radius: 10px;
      color: $c-black;
      .card-id-badge {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        margin-top: -0.3rem;
      }
      .body-item-flex {
        display: flex;
        padding-left: 0.2rem;
        padding-bottom: 0.3rem;
        @include rtl {
          padding-left: 0;
          padding-right: 0.2rem;
        }
        flex-direction: row;
        align-items: center;
        width: 100%;
      }
      .card-badge {
        border-radius: 12px;
        min-width: 4rem;
        max-width: 10rem;
        min-height: 1.3rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .contact-id-wrapper {
        font-size: $fz-14px;
        font-weight: bold;
        display: flex;
        align-items: center;
        textarea {
          width: 0;
          height: 0;
          padding: 0;
          opacity: 0;
        }
        .contact-id {
          margin-left: 0.01rem;
          margin-right: 0.01rem;
        }
        .mdi::before {
          margin-left: 0.5rem;
          margin-right: 0.5rem;
          font-size: $fz-14px;
          cursor: pointer;
          color: $c-primary;
        }
      }
      .cover-image {
        background-color: $c-gray-lighter;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 90px;
        min-height: 85px;
        width: 100%;
        min-width: 100px;
        border-radius: 5px;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
      }
      .cards-header-wrapper {
        @include d-flex-v-center;
        padding: 0.5rem 0.5rem 0;

        .header-title-wrapper {
          @include d-flex-v-center;
          padding: 0 0.5rem;
          margin-bottom: 0.1rem;
          width: 100%;
          .header-title {
            font-size: $fz-16px;
            font-weight: $fw-simi-bold;
          }
        }
      }
      .cards-body-wrapper {
        @include d-flex;
        align-items: flex-start;
        width: 100%;
        padding: 0.5rem 0.5rem 0;
        .created-on {
          display: flex;
          .mdi {
            vertical-align: bottom;
            padding-right: 0.3rem;
            @include rtl {
              padding-right: 0;
              padding-left: 0.3rem;
            }
            &::before {
              font-size: $fz-16px;
            }
          }
        }
        .body-title-wrapper {
          width: 100%;
          .body-title {
            color: $c-black;
            padding-left: 0.5rem;
            font-size: $fz-16px;
            font-weight: $fw-simi-bold;
          }
        }
        .card-body-section {
          @include d-flex-column;
          width: 100%;
          align-items: flex-end;
          .body-item-wrapper {
            @include d-flex-column;
            width: 100%;
            .body-icon {
              padding: 0 0.5rem;
              vertical-align: middle;
            }
          }
          .details-item-wrapper {
            @include d-flex;
            width: 100%;
            padding: 0 0.5rem;
            margin-bottom: 0.1rem;
            min-width: 200px;
            max-height: 35px;
            overflow: hidden;
            text-overflow: ellipsis;
            align-self: flex-start;
            .Gold 
            {
              display: flex;
              align-items: center;
              color: #a28200;
              display: flex;
              padding:  2px 8px;
              align-items: center;
              border-radius: 9999px;
              background:  #F4F1ED; 

            }
            .Platinum {
              display: flex;
              align-items: center;
              color: #3538CD;
              display: flex;
              padding:  2px 8px;
              align-items: center;
              border-radius: 9999px;
              background:  #EEF4FF; 

            }
            .Bronze{
              display: flex;
              align-items: center;
              color: #A5886D;
              display: flex;
              padding:  2px 8px;
              align-items: center;
              border-radius: 9999px;
              background:  #FEF6EE; 

            }
            .Silver 
            {
              display: flex;
              align-items: center;
              color: #363F72;
              display: flex;
              padding:  2px 8px;
              align-items: center;
              border-radius: 9999px;
              background:  #F8F9FC; 

            }
            // @supports (display: -webkit-box) {
            //   display: -webkit-box;
            //   -webkit-box-orient: vertical;
            //   -webkit-line-clamp: 2;
            //   .mdi {
            //     vertical-align: bottom;
            //     &::before {
            //       font-size: $fz-14px;
            //     }
            //   }
            // }
          }
          .details-text {
            white-space: nowrap;
            white-space: break-spaces;
          }
          .details-icon {
            vertical-align: bottom;
          }
          .details-icon,
          .details-text,
          .body-text {
            font-weight: $fw-bold;
          }
          &.is-expanded {
            flex-direction: row;
            flex-wrap: wrap;
            .body-item-flex {
              padding-bottom: 0.3rem;
              display: flex;
              flex-direction: row;
              width: 100%;
              align-items: center;
            }
            .details-item-wrapper,
            .body-item-wrapper {
              width: 33.3333%;
              max-width: 33.3333%;
              @include phones-max {
                width: 100%;
                max-width: 100%;
              }
            }
          }
        }
      }
      .details-item {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        @supports (display: -webkit-box) {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
      .cards-progress-wrapper {
        width: 100%;
      }
      .cards-footer-wrapper {
        @include d-flex-v-center;
        flex: 1 1 auto;
        overflow: hidden;
        width: 100%;
        min-height: 40px;
        padding: 0;
        border-top: 1px solid $c-gray-secondary;
        display: flex;
        justify-content: center;
        // .edit-power-icon {
        //   position: absolute;
        //   top: 50%;
        //   right: 1rem;
        //   transform: translateY(-50%);
        // }
        .btns {
          position: relative;
          border-radius: 0;
          height: 100%;
          min-height: 40px;
          color: $c-black;
        }
      }
    }
    &.is-open {
      .cards-wrapper {
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.25);
        color: $c-secondary;
        .cards-footer-wrapper {
          .btns {
            color: $c-secondary;
          }
        }
      }
    }
    position: relative;
    @include d-inline-flex-column;
    padding: 0 0.5rem;
    min-width: 355px;
    @include phones-max {
      min-width: 290px;
    }
    margin-bottom: 1rem;
    width: 20%;
    max-width: 380px;
    @include laptops-xl-max {
      width: 25%;
    }
    @include laptops-l-max {
      width: 33.3333%;
    }

    @include laptops-max {
      width: 50%;
    }

    @include phones-max {
      width: 100%;
      max-width: initial;
    }
    &.is-expanded {
      width: 50%;
      max-width: initial;
      // @include laptops-xl-max {
      //   width: 33.3333%;
      // }

      // @include laptops-l-max {
      //   width: 50%;
      // }

      @include laptops-max {
        width: 100%;
      }
    }
    // margin-top: 5%;
    .ribbon {
      position: absolute;
      cursor: pointer;
      z-index: 2;
      padding: 0px 4px;
      margin-right: 2.4%;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      font-size: $fz-10px;
      line-height: 18px;
      font-weight: bold;
      text-align: center;
      color: #fff;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      zoom: 1;
      top: 1.3%;
      right: -3%;
      min-width: 10%;
      height: 18px;
    }
    .blue {
      background:#3b72d9 ;  
    }
    .ribbon:after {
      content: '';
      position: absolute;
      z-index: -20;
      top: 100%;
      left: auto;
      border-style: solid;
      border-width: 0 0 10px 10px;
      right: 0;
    }
    .blue:after {
      border-color: transparent #214280;
    }
    .no-ribbon {
      height: 41px;
      position: absolute;
      z-index: 2000;
    }
  }
  .properity-card-header {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  .isBulkUpload {
    margin-top: 2.7rem
  }
}

.search-properties {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lauxary-banner {
  height: 24px;
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 3px 8px -1px rgba(171, 171, 171, 0.75);
  border-bottom: 2px solid #a08756;
  background: url(../../../../../assets/images/luxary_bg.jpg);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin-block: 2px;
    font-weight: 900;
    font-size: 11px;
    color: white;
    text-shadow: #a28200 1px 0 10px;
  }
}
