  .dragDrop{
    .rotation-criteria-assign-view-wrapper {
      .agent-section-wrapper{
        .bulked-units-section {
          height: calc(100vh - 400px) !important;
        }

      }


    }
  }
  .rotationCriteriaManage {
    box-sizing: border-box;
    .bulked-units-section
    {
      cursor: default !important ;
    }
    .row {
      display: flex;
      flex: 1 1 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      position: relative;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .rotation-details {
      .title-text {
        font-size: 1rem;
        letter-spacing: 0px;
        color: #3a3a3c;
        opacity: 1;
      }
    }

    .rotation-criteria-assign-view-wrapper {
      .arrow-span {
        margin-right: 3rem;
      }

      .arrow
      {
        margin-top: 6.5rem ;
        margin-left: 1rem ;
        margin-right: 0;
        @include rtl {
          margin-right: 1rem;
          margin-left: 0;
        }
      }
      @include d-flex-h-center;
      justify-content: flex-start;
      flex-wrap: nowrap;
      width: 100%;
      position: relative;
      @include rtl {
        padding-right: 0;
      }
      padding-left: 1rem;
      .section-seperator {
        &.mdi::before {
          @include rtl {
            transform: rotate(180deg);
          }
          //position: absolute;
          //top: 15%;
          font-size: 1rem;
        }
      }
      .sortByName.true{
          transform: rotate(180deg);
       } 

      .sortById.true{
         transform: rotate(180deg);
        } 
      .agent-section-wrapper {
        @include d-flex-column;
        @include d-inline-flex-column;
        margin: .5rem;
        width: 33.333%;
        .agent-header-section {
          display: flex;
          justify-content: flex-start;
          width: 100%;
          color: #3a3a3c;
          margin: .5rem;
          font-size: .85rem;
          .header-section1 {
            width: 10%;
          }
          .header-section2 {
            width: 90%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }
        .agent-sub-header-section {
          color: $c-gray;
          font-size: 0.875rem;
          display: flex;
          justify-content: flex-start;
          width: 100%;
          margin: 0.5rem;
          margin-bottom: 0.25rem;
          .sub-header1 {
            width: 10%;
          }
          .sub-header2 {
            display: grid;
            grid-template-rows: repeat(1, auto);
            grid-template-columns: auto auto auto;
            width: 90%;
            
            .header-sub-section-id {
              text-align: flex-end;
              @include rtl {
                text-align: center;
              }
            }
            .selected-agent
            {
              .sortByName.true{
                transform: rotate(180deg);
             } 
      
            .sortById.true{
               transform: rotate(180deg);
              } 


            }
          }

          .agent-sub-header-section-name-id {
            width: 50%;
          }
        }

        .bulked-units-section {
          overflow-y: auto;
          height: calc(100vh - 270px);
          padding-right: 1rem;
          @include rtl {
            padding-right: 0;
            padding-left: 1rem;
          }
        }

      }

      .agent2 {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 50%;
        .agent11 {
          width: 50%;

        }
        .agent12 {
          width: 50%;
          
        }


      }
    
    }

    .tabs-wrapper.theme-solid .MuiTabs-flexContainer .MuiTab-root {
      margin-left: 2.5rem;
      margin-right: 2.5rem;

    }

  }
