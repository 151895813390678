.view-wrapper {

  .activities-top-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 15px 30px;
  }

}

.cold-score {
  background-color: #3b72d9 !important;
  color: white !important;
}

.warm-score {
  background-color: #dfc213 !important;
  color: white !important;
}

.hot-score {
  background-color: #db504a !important;
  color: white !important;
}

.buttons-container{
  display: flex;
  flex-direction: column;
}