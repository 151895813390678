.external-link {
  .no-content-result {
    height: 80vh;
  }
}

.external-link-display {
  .btns-container {
    display: flex;
    width: fit-content;
    margin: 1rem;
    align-items: center;

    .external-btn {
      display: flex;
      cursor: pointer;
      font-size: 0.85rem;

      .mdi::before {
        margin-left: 5px;
      }
    }

    .btn:hover {
      color: var(--c-primary);
    }
  }

  .iframe-style {
    width: 100%;
    height: 80vh;
    border: #dfdfdf 2px solid;
    padding: 1rem;
    border-radius: 10px;
  }
}

.table-list-wrapper {
  display: flex;
  flex-wrap: wrap;
  min-width: 500px;
  .circle-dot-element {
    background: rgb(142, 142, 147);
    margin-right: 0.5rem;
    margin-top: 0.3rem;
    border-radius: 50%;
    width: 0.3rem;
    height: 0.3rem;
  }
}
