.quick-add-popover-wrapper {
  @include d-flex;
  flex-wrap: wrap;
  padding: 0.5rem;
  .quick-add-item-wrapper {
    @include d-inline-flex-column;
    align-items: center;
    padding: 0.5rem 0.5rem 0;
    .quick-add-button {
      width: 50px;
      height: 50px;
      min-width: 50px;
      border: 1px solid $c-gray-secondary;
      border-radius: 0.5rem;
      margin-bottom: 0.25rem;
    }
    .quick-add-text {
      color: $c-black;
      cursor: pointer;
    }
  }
  .icons {
    height: 41px;
    width: 41px;
    background-position: center;
    background-size: 41px 24px;

    &.i-folder-white {
      background-size: 14px 14px;
    }
  }
}
