.activities-management-dialog-wrapper {
  .MuiDialogTitle-root {
    border-bottom: 1px solid $c-gray-dark !important;
    padding-left: 2rem;
  }
  .MuiDialog-paper {
    min-width: 50rem;
  }
  .MuiDialogContent-root {
    padding: 1rem;
    min-width: 30rem;
  }
  .dialog-content-wrapper {
    width: 100%;
    @include d-flex;
    flex-wrap: wrap;
    .dialog-content-item {
      width: 50%;
      margin-bottom: 0.5rem;
      @include phones-max {
        width: 100%;
      }
      padding: 0 0.5rem;
    }
    .title-wrapper {
      @include d-flex;
      margin-bottom: 0.5rem;
      padding: 0 0.5rem;
      .title-text {
        font-weight: $fw-simi-bold;
      }
      .Requierd-Color::after{
        content: " *";
          color: $c-red;
        }
    }
    .reminder-wrapper {
      @include d-flex-column;
      .reminder-item-wrapper {
        @include d-flex;
        flex-wrap: wrap;
        .reminder-section {
          @include d-inline-flex;
          align-items: flex-start;
          margin-bottom: 1rem;
          padding: 0 0.5rem;
          width: 33.3333%;
          @include phones-max {
            width: 100%;
          }
        }
      }
    }
  }
}
