
.buttons-action{
  min-height: 32px !important;
  padding: 9px !important; 
}

.no-scrollbar {
  /* Hide scrollbar for WebKit browsers (Chrome, Safari, etc.) */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.resale-date{
  margin-bottom: -5px;
}