.users-manage-view {

  
    .b-bottom {
      border-bottom: 1px #F1F2F2 solid;
    }
    .b-top {
      border-top: 1px #F1F2F2 solid;
    }

    .fz-10{
      font-size: 10px;
    }
    .fz-14{
      font-size: 14px;
    }
    
    .light-text {
      color: #475467;
    }

    .pointer {
      cursor: pointer;
    }
  
    .dialog-title-wrapper{
      padding-bottom: 1rem !important;
    }
  
    .MuiDialogContent-root{
      padding-top: 0px !important;
      border-top: 1px #F1F2F2 solid;
      max-height: 75vh;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
    }
  
}