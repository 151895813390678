.stepper-border {
    border: 1px solid #ccc;
    border-radius: 10px;

    .date {
        font-size: smaller;
    }

    .g-c-1 {
        column-gap: 1rem !important;
    }

    .flex {
        display: flex;
    }

    .divider-height {
        height: 3rem !important;
    }
}