.convolo-card-container {
    border: 1px solid rgba(128, 128, 128, 0.637);
    border-left: 10px solid $c-primary;
    margin: 0 10px;
    border-radius: 10px;
    column-gap: 10px;
    width: fit-content;

    .convolo-card-head {
        gap: .5rem;
    }

    .convolo-card-title {
        margin: 0;
        display: block;
    }

    .convolo-card-label {
        width: 30%;
        min-width: 10rem;

        strong {
            display: block;
            font-weight: 900;
        }
    }

    .convolo-card-label:nth-child(-n+4) {
        border-bottom: 1px solid rgba(128, 128, 128, 0.637);
    }
    
}