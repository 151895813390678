.header-section-AgentRotation{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
}
.filter-section-AgentRotation{
    display: flex;
    gap: 20px;
}

.datePickerComponentAgentRotation{
    width: 20rem;
}
