.clear-search-criteria-filters-button-container{
    min-width: 120px;
    background-color: #db504a;
    color: white;
    border: 2px #db504a;
    border-radius: 30px;
    max-height: 30px;
 }
 
 .clear-search-criteria-filters-button {
     min-height: 100%;
     width: 100%;
     font-size: 13px;
     white-space: nowrap;
 }
.table-head {
    background-color: #f5f5f5; 
    font-weight: bold; 
  }
  
  .table-head th {
    border-bottom: 2px solid #133c89;
  }
  
  .table-head th {
    padding: 16px;
    text-align: center;
  }
  .table-header {
    text-align: left;
    margin-bottom: 20px;
    padding-left: 20px;
  }

  .table-header h2 {
    color: #103a87;
  }
  
  .table-container {
    max-width: 95%; 
    margin: auto;
  }
  

  .TableRow-TableBody-aml {
    height: 50px !important; 
  }

  .pass-lapel {
    padding: 5px 12px;
    min-width: 5.5rem;
    text-align: center;
    height: fit-content;
    font-weight: 900;
    color: #027a48;
    border-radius: 25px;
    background: #ecfdf3;
  }
   
  .Fail-lapel {
    padding: 5px 12px;
    min-width: 5.5rem;
    color: #b42318;
    text-align: center;
    height: fit-content;
    font-weight: 900;
    border-radius: 25px;
    background: #fef3f2;
  }
   
  .Pending-lapel {
    padding: 5px 12px;
    min-width: 5.5rem;
    text-align: center;
    height: fit-content;
    font-weight: 900;
    color: #b54708;
    border-radius: 25px;
    background: #fffaeb;
  }
  
  .Expired-lapel {
    padding: 5px 12px;
    min-width: 5.5rem;
    text-align: center;
    height: fit-content;
    font-weight: 900;
    color: #737c84;
    border-radius: 25px;
    background: #e4e7ea;
  }
  