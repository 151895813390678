@import "../../../assets/theme-style/master.scss";


.calendars-new {
  position: relative;
  z-index: 1;

  .MuiPickersCalendarHeader-switchHeader {
    margin-bottom: 90px !important;
    height: 36px;
    margin-top: 0;
  }

  .MuiPickersDay-day{
    color:var(--colors-foreground-fg-secondary-700); //done!!
    font-weight: 400;
    font-size: 14;
  }

  .MuiIconButton-root:hover {
    background-color: var(--colors-background-bg-primary_hover) !important;
  }

    .MuiPickersDay-daySelected {
      color: #FFF //done!!
    }


  .MuiTypography-body1 {
    font-size: $fz-16px;
    font-weight: 600;
    color:var(--colors-foreground-fg-secondary-700); //done!!
  }

  .full-calendar-item {
    background-color: $c-secondary-light;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0.5rem;
    max-height: 8rem;
    overflow-y: auto;
    &:hover {
      background-color: $c-gray-lighter;
    }
    .activity-item {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      border-radius: 0.5rem;
      width: 90%;
      padding: 0.5rem;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      .activity-item-subject {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: $fz-13px;
        margin-bottom: 0.1rem;
        .activity-item-badge {
          width: 0.4rem;
          height: 0.4rem;
          background-color: $c-secondary;
          border-radius: 50%;
          margin-right: 0.5rem;
          @include rtl {
            margin-right: 0;
            margin-left: 0.5rem;
          }
        }
      }
      .activity-item-hour {
        font-size: $fz-default;
        margin-left: 1rem;
        @include rtl {
          margin-left: 0;
          margin-right: 1rem;
        }
      }
    }
    .activity-date {
      position: absolute;
      top: 0;
      right: 0;
      @include rtl {
        right: initial;
        left: 0;
      }
    }
  }
  .MuiPickersStaticWrapper-staticWrapperRoot {
    overflow: visible;
    background-color: $c-white;
    border-radius: 5px;
    background-color: transparent;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
  }

  .MuiPickersCalendarHeader-iconButton {
    background-color: transparent;
  }

  .MuiIconButton-root {
    padding: 0.25rem;
    min-height: 30px;
    min-width: 30px;
  }

  &.hide-header {
    .MuiPickersDatePickerRoot-toolbar {
      display: none;
    }
  }

  &.small-calender {
    .MuiPickersStaticWrapper-staticWrapperRoot {
      min-width: initial;
      display: inline-block;
      width: 100%;
    }

    .MuiPickersBasePicker-pickerView {
      max-width: initial;
      min-width: initial;
    }

    .MuiPickersBasePicker-pickerView {
      min-height: initial;
      max-width: initial;
      // padding: 0.5rem;

      .MuiPickersCalendar-transitionContainer {
        min-height: 180px;
      }
    }

    .MuiIconButton-root {
      height: 30px;
      width: 30px;
    }

    .MuiPickersCalendarHeader-dayLabel {
      margin: 0 !important;
      width: 40px;
      height: 40px;
      color:var(--colors-foreground-fg-secondary-700); //done!!
      font-size: 14px;
      padding: 0;
      font-weight: 500;
    }
  }

  &.transparent-calender {
    .MuiPickersStaticWrapper-staticWrapperRoot {
      overflow: visible;
      background-color: transparent;
      min-width: initial;
      display: inline-block;
      width: 100%;
      min-height: 21rem;
      box-shadow: 0 0 0 0 transparent;
    }

    .MuiPickersDay-daySelected {
      background-color: var(--colors-foreground-fg-brand-primary-600) !important; //done
    }
    .MuiPickersBasePicker-pickerView {
      max-width: initial;
      min-width: initial;
    }

    .MuiPickersBasePicker-pickerView {
      min-height: initial;
      max-width: initial;
      padding: 20px 24px;

      .MuiPickersCalendar-transitionContainer {
        min-height: 240px;
      }
    }

    .MuiIconButton-root {
      height: 40px;
      width: 40px;
      margin: 0 !important;
    }

    .MuiPickersCalendarHeader-dayLabel {
      margin: 0 !important;
      width: 40px;
      height: 40px;
      color:var(--colors-foreground-fg-secondary-700); //done!!
      font-size: 14px;
      padding: 10px 8px;
      font-weight: 500;
    }

    .MuiPickersCalendar-week {
      margin-bottom: 4px;
      width: 280px;

      &:last-child {
        margin-bottom: 0; /* Adjust the value as needed */
    }
      
    }
    .MuiPickersBasePicker-pickerView {
      overflow: hidden;
      // margin-bottom: 0.5rem;
    }

    .MuiPickersBasePicker-container:after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      pointer-events: none;
      // background-image: linear-gradient(
      //   to bottom,
      //   rgba(255, 255, 255, 0),
      //   rgba(255, 255, 255, 1) 90%
      // );
      width: 100%;
      height: 8em;
    }
  }

  .MuiBadge-anchorOriginTopRightCircle {
    width: 5px !important;
    height: 5px !important;
    min-width: 5px !important;
    top: 80%;
    right: 20px;
    padding: 0;
  }

  .expiredEvent {
    .MuiBadge-colorSecondary {
      background-color: var(--colors-foreground-fg-disabled); 
    }
  }

  .notExpiredEvent {
    .MuiBadge-colorSecondary {
      background-color: var(--colors-foreground-fg-brand-primary-600); //done
  }
}
}


.calendars-new {
  &-without-dropdown {
    .MuiPickersCalendarHeader-switchHeader {
      margin-bottom: 10px !important;
    }
    &.transparent-calender {
      .MuiPickersBasePicker-pickerView {
        padding: 0px;
      }
    }
  }
}