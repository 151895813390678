.containerSearchFileds{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .rowSection{
        display: flex;
        flex-direction: row;
        width: 40%;
        .firstSearchFiled{
            display: flex;
            align-items: flex-end;
            justify-content: center;
            width: 100%;
        }.dateFiled{
            width: 100%;
        }
    }
}