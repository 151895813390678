.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .Icon_Button {
    padding: 0px !important;
    width: 34px;
    height: 34px;
    min-width: -moz-fit-content;
    min-width: fit-content !important;
  }

  .Header_Title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
}

.Info_box {
  display: flex;
  min-width: 92px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-md, 8px);
  border-radius: 12px;
  border: 1px solid var(--Colors-Border-border-tertiary, #f2f4f7);
  background: #fff;
  box-shadow: 0px 1px 1px -1px rgba(16, 24, 40, 0.1),
    0px 1px 1px -1px rgba(16, 24, 40, 0.06);

  p {
    margin: 0px;
    font-size: 10px;
    font-weight: 500;
    line-height: 20px;
  }
}

.Location_Text {
  display: flex;
  align-items: flex-end;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.Marketing_Desc {
    white-space: pre-wrap;
    overflow-wrap: anywhere;
}
