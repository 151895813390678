@import '../../../assets/theme-style/master.scss';


  .badges .MuiBadge-badge.MuiBadge-badge {
    height: 19px;
    min-width: 19px;
    padding: 0.1rem;
    font-weight: 500;
    font-size: $fz-14px;
    line-height: 12px;
  }

  .badges .MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle {
    top: -10%;
  }

  .activity-events-wrapper {
    .events-button {
      top: -5px;
    }
    box-shadow: none;
    .calendars-new .MuiPickersStaticWrapper-staticWrapperRoot {
      border-radius: 0;
      box-shadow: 0 0 0 0 transparent;
      max-height: 25rem;
    }

    .events-button {
      min-height: 50px;
    }
    .events-badge,
    .events-hour-badge {
      flex-shrink: 0;
    }
    .event-badge-text {
      text-align: flex-start;
      white-space: break-spaces;
      line-height: initial;
    }
    .events-container {
      overflow-y: auto;
      max-height: 100px;
      height: 100px;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-top: 5px;
      margin-bottom: 5px;
      position: relative !important;
    }
    .MuiPickersBasePicker-container:after {
      max-height: 3.5rem;
    }

    .event-element-container {
      width: 100%;
      padding: 12px;
      border-radius: 12px;
      border: 1px solid var(--Colors-Border-border-secondary, #EAECF0);
      background: var(--Colors-Foreground-fg-white, #FFF);
    }

    .new-events-wrapper {
      display: flex;
      height: 82px;
      overflow-y: auto;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 24px !important;
      margin: 12px 0 !important;
      gap: 8px;
    
      span {
        color: #667085;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        margin-bottom: 4px;
      }

      .event-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 4px;

        span {
          font-size: 12px;
          margin-bottom: 0;
          color: #344054;
          font-weight: 500;
        }

        label {
          color: #344054;
          font-weight: 600;
        }

        .event-dot {
          background-color: #7f6944;
          width: 5px;
          height: 5px;
          border-radius: 50%;
        }

        .text {
          width: 100%;
          gap: 4px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .view-all-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 3rem;
    margin-top: -1.2rem;
  }
  .event-item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .event-badge-subject-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .events-badge {
      background-color: $c-secondary;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
    }
    .events-hour-badge {
      background-color: $c-secondary;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 1.5rem;
      color: $c-white;
      border-radius: 2rem;
    }
  }
  @include phones-s-max {
    flex-wrap: wrap;
    justify-content: center;
  }
  .btns.MuiButtonBase-root {
    &.user-button-wrapper {
      @include phones-max {
        margin: 0rem;
      }
      margin: 0 1rem;
      padding: 0;
      min-width: initial;
      .user-image {
        min-width: 35px;
        height: 35px;
        width: 35px;
        border-radius: 100%;
      }
      .user-name-wrapper {
        @include d-inline-flex;
        @include phones-s-max {
          display: none !important;
        }
        color: #3a3a3c;
        margin: 0 0.5rem;
        .user-name-text {
          display: inline-block;
          @include texts-truncate;
          max-width: 147px;

          @include phones-max {
            max-width: 80px !important;
          }
        }
      }
    }
  }

  .img-logo {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .section {
    padding: 0.5rem;
    @include d-inline-flex-v-center;

    .section-conteaner {
      @include rtl {
        transform: scale(-1);
      }
    }
  }

  .logo-text {
    font-size: $fz-10px;
    font-weight: bold;
    margin-bottom: 0.25rem;
    color: $c-green;
  }

  .logo-text-small {
    font-size: $fz-9px;
    color: $c-blue-light;

    .text-small {
      font-variant: small-caps;
    }
  }

  .logo-text,
  .logo-text-small {
    font-family: AcuminConcept, serif;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .btns-icon:not(.btns-small) {
    margin: .25rem !important ;
    @include laptops-max {
      margin: 0.25rem !important ;
    }
  }
  .i-text-gray {
    margin-right: 0.5rem;
    margin-left: 1rem;
  }

  .user-img {
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    margin-bottom: 1rem;
    border-radius: 100%;
  }

  .user-btn {
    position: absolute;
    right: 0.25rem;
    bottom: 0.25rem;
    transform: translate(50%, -50%);
  }
  .privet-icon {
    color: $c-gray-dark !important;
  }
  .privet-icon:hover {
    color: $c-primary !important;
  }
  .btns-active {
    &.mdi,
    .mdi {
      color: $c-secondary;
    }

    &.icons,
    .icons {
      filter: invert(101%) sepia(98%) saturate(545%) hue-rotate(344deg) brightness(98.5%)
        contrast(95%);
      -webkit-filter: invert(101%) sepia(98%) saturate(545%) hue-rotate(344deg) brightness(98.5%)
        contrast(95%);
    }

    &img,
    img {
      border: 2px solid #c7c7cc;
      border-radius: 100%;
    }
  }
  .notifcationImage {
    width: 44px;
    height: 44px;
    background-repeat: no-repeat;
  }
  .logout-footer:hover {
    font-weight: 700;
    transition-duration: 0.7s;
    background-color: #db504a;
    color: rgb(255, 255, 255);
    border: 1px solid #000000;
  }

  .textNotification {
    white-space: pre-wrap;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 336px;
  }
  
.notificationItems-container-tab-1 {
  overflow: auto;
  min-width: 30rem;
  width: 19rem !important;
  max-height: 20rem;
  .unRead
  {
   color: #3B72D9 ; 

  }
  .read-text{
    color:#8E8E93  ;
    
  }
  .read-more-btn{
    text-align: justify;
    width: 100%;
  }
  .alertItem
    {
        display: inline-flex;
        background-color: rebeccapurple;
        justify-content: flex-end;
        white-space: break-spaces;
    }
    .circle 
    {
      width: 6.382px;
      height: 6.382px;

    }
 
 
  .btns.MuiButtonBase-root.theme-wide {
    min-height: 90px;
    background-color: #e5e5ef85;   
    position: relative;
    &.is-read {
      background-color: #ffffff !important;
    }
    
   .dot {
     position: absolute;
     top: 16px ;
     right: 16px;
     @include rtl {
      top: 16px ;
      left: 16px;
      right: auto;
    }
    }
    
    .body-part
    {
      display: inline-flex; 
      white-space:nowrap ;
      letter-spacing: normal;
      flex-direction: column;
      align-items: center;
      line-height: 150%;
      .more-info {
        text-wrap : wrap ; 
        text-align: flex-start;

      }


    } 

  }
}

.notificationItems-container {
  overflow: auto;
  min-width: 30rem;
  width: 19rem !important;
  max-height: 20rem;
  .btns.MuiButtonBase-root.theme-wide {
    background-color: #e5e5ef85;   
    &.is-read {
      background-color: #ffffff !important;
    }
  }
}

.notificationItems-container-aleart {
  overflow: auto;
  min-width: 30rem;
  width: 19rem !important;
  max-height: 20rem;
  .btns.MuiButtonBase-root.theme-wide {
    width: 24rem !important;
    background-color: #e5e5ef85;
    &.is-read {
      background-color: #ffffff !important;
    }
  }
}
.actions-item-type {
  overflow: auto;
  max-height: 21rem;
}

.user-menu-wrapper {
  min-width: 345px;

  .icon-falg-wrpper
  {
    width: 23px;
    height: 21px;
    background-size: 26px;
    background-repeat: no-repeat;
    margin-top: 5px;
    margin-right: 32px;
  }
}

.MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: '\00a0';
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: none !important;
  pointer-events: none;
}
.MuiInput-underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-bottom: none !important;
  pointer-events: none;
}