.AMLView-view-wrapper {
  .MuiTableRow-root .makeStyles-root {
    height: 72px;
    background: #ebf1fb !important;
  }

  .margin-titel {
    /* Text lg/Semibold */
    margin: 0px 40px 0px 40px;
    font-weight: 600;
    font-size: $fz-18px;
    line-height: 28px;
    /* identical to box height, or 156% */

    .TableRow-heed-aml {
      margin: 1rem;
    }

    /* Foundation /Blue/blue-600 */
    color: #3668c5;
  }

  .row-wrper-line {
    display: flex;

    .row-item {
      width: 16rem;
      margin-right: 10px;

      &.EMAIL-S {
        width: 24rem;
        margin-right: 2rem;
      }
    }

    .row-item-sub-title {
      &.nowrap {
        width: 230px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      font-weight: 500;
      font-size: $fz-13px;
      margin-top: 20px;
      margin-bottom: 20px;
      line-height: 20px;
    }
  }
}

.Request-Details {
  display: flex;
  width: 44rem;
  justify-content: space-between;
}

.TableRow-heed-aml {
  margin: 1rem;
  background-color: rgb(255, 255, 255);
  width: 247.5px;
  height: 44px;

  .MuiTableCell-root {
    display: table-cell;
    letter-spacing: 0;
    padding: 0px;
    text-align: center;
    font-weight: 500;
    font-size: $fz-default;
  }
}

.TableRow-TableBody-aml {
  background-color: rgb(255, 255, 255);
  padding: 0px;
  width: 1557px !important;
  height: 72px !important;

  .MuiTableCell-root {
    padding: 0px !important;
    font-size: 0.875rem;
    text-align: center;
  }

  &.isselect {
    background-color: #ebf1fb;
  }
}

.AML-TABEL {
  margin: 2rem !important;
}

.pass-lapel {
  padding: 5px 12px;
  width: fit-content;
  text-align: center;
  height: fit-content;
  font-weight: 900;
  color: #027a48;
  border-radius: 25px;
  background: #ecfdf3;
}

.Fail-lapel {
  padding: 5px 12px;
  width: fit-content;
  color: #b42318;
  text-align: center;
  height: fit-content;
  font-weight: 900;
  border-radius: 25px;
  background: #fef3f2;
}

.Pending-lapel {
  padding: 5px 12px;
  width: fit-content;
  text-align: center;
  height: fit-content;
  font-weight: 900;
  color: #b54708;
  border-radius: 25px;
  background: #fffaeb;
}

.AMLView-view-wrapper {
  .MuiTableRow-root .makeStyles-root {
    height: 72px;
    background: #ebf1fb !important;
  }

  .margin-titel {
    /* Text lg/Semibold */
    margin: 36px 40px 0px 1px;
    font-weight: 600;
    font-size: $fz-18px;
    line-height: 28px;
    /* identical to box height, or 156% */
  }
  .margin-titel-comment{
    margin: 10px 40px 0px 1px;
    font-weight: 600;
    font-size: $fz-18px;
    line-height: 28px;
    color: #3668c5;
  }

  .row-item-wrap {
    margin-bottom: 3rem;
  }

  .row-item-sub-value {
    &.nowrap {
      width: fit-content;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    font-weight: 900;
  }
}

.part-tow-secation {
  padding-bottom: 40px;
}

.stepaer-mian-wr {
  // width: 119%;
  display: flex;
  justify-content: right;
  flex-direction: row;
}

.stepaer-inner-container {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.row-item-Button {
  display: flex;
  justify-content: space-between;
  width: 234px;
  justify-content: space-evenly;
  align-items: flex-end;

  &.alia {
    align-items: center !important;
    margin: 0px 13px 0px 13px;
  }

  .btns-can-Approve {
    .MuiButton-root {
      width: 101px;
      height: 32px;
      background: #039855 !important;
      font-weight: 500;
      font-size: $fz-13px;
      line-height: 13px;
      text-transform: none;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.37141px;
      color: $c-white;
      border: 1px solid #98a2b3 !important;
      border-radius: 30px !important;
    }
  }

  .MuiButton-root {
    width: 101px;
    height: 32px;
    background: #ffffff !important;
    font-weight: 500;
    font-size: $fz-13px;
    line-height: 13px;
    text-transform: none;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.37141px;
    color: #475467;
    border: 1px solid #98a2b3 !important;
    border-radius: 30px !important;
  }
}

.flx-v-been {
  justify-content: space-between;
}

.Complete-bbt {
  width: 173px;
  height: 37px;
}

.darkline {
  width: 119%;
  height: 3px;
  border: 1px solid #dedddb;
}

.darkline-2 {
  width: 110%;
  height: 3px;
  border: 1px solid #dedddb;
}

.claapse_wraper {
  @include laptops-max {
    width: 20rem;
  }

  @include laptops-xl-max {
    width: 100%;
  }

  @include laptops-l-max {
    width: 94%;
  }

  @include laptops-max {
    // width: 50%;
  }

  @include tablets-max {
    width: 100%;
  }

  @include phones-max {
    width: 100%;
  }
}

.TableCell-BLOCK {
  max-width: 60rem;
}

.Contanier_StepperAndRevession{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  width:100%;
}
.part-Three-secation {
  padding-bottom: 40px;
}

.continer_comments {
  display: flex;
  flex-direction: column;
}

