.TaskDetailsView {
  .file-item-container {
    display: grid;
    grid-template-columns: auto 20% 20%;

    .file-icon-wrapper {
      .file-icon {
        width: 32px;
      }
      .file-size {
        color: #475467;
      }
      .file-title{
        font-size: 10px;
      }
    }
  }
  .sec-color {
    color: #498a8b;
  }

  .link-item-container {
    display: grid;
    grid-template-columns: auto 20%;
  }
  .ecilipse-text {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 75%;
    height: 1rem;
    white-space: nowrap;
    margin-bottom: 0px;
    font-size: 10px;
  }
}
