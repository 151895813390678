.Policies {
  .policiesSearch {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    display: inline-flex;
    .section {
      margin: 0.3rem;
      width: 50%;
    }
  }
}

.Policies-dialog-wrapper {
  .CheckboxesComponent-unitsSaleListingSpecificsUsers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem;
  }

  .checkboxesItem {
    margin: 0.5rem;
  }
  .UnitsSaleListedBy-con {
    .radio-groups-wrapper.theme-default.MuiFormControl-root .radio-group-wrapper .MuiFormControlLabel-label {
      color: #4b7ddb !important;
      font-weight: 600 !important;
    }
    .MuiTypography-body1 {
      font-size: 12px !important;
      text-transform: capitalize !important;
    }
  }

  .showSensitive-wrapper {
    margin: 15px;
    padding: 1px;
    text-transform: capitalize;
  }

  .showSensitive-unitsSaleListingSpecificsUsers {
    display: inline-flex;
    width: 100%;
  }

  //height: calc(100vh - 400px);
  //min-height: calc(100vh - 250px);
  // min-height: calc(100vh - 200px);
  // min-height: 0rem
  // max-height: 700px ;
  min-height: 300px;
  .MuiDialogTitle-root {
    border-bottom: 1px solid $c-gray-dark !important;
    padding-left: 2rem;
  }
  .MuiDialog-paper {
  }
  .MuiDialogContent-root {
    padding: 1rem;
  }

  //background-color: orange;
  .Requierd-Color::after {
    content: " *";
    color: $c-red;
  }

  .MuiDialogContent-root {
    padding: 1rem;
    min-width: 30rem;
  }
  .dialog-content-wrapper {
    width: 100%;
    @include d-flex;
    justify-content: center;
    flex-wrap: wrap;
    .form-item {
      width: 50%;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-bottom: 0.5rem;
    }
    .form-item-two-fields {
      display: flex;
      .autocomplete-wrapper {
        min-width: 200px;
      }
      .salutation-select {
        padding-right: 0.5rem;
        @include rtl {
          padding-left: 0.5rem;
          padding-right: 0;
        }
      }
    }
  }

  .part {
    margin: 0.5rem;
    padding: 0.5rem;
  }

  .leadsDataDetailsTab {
    padding: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    .leadLastDays {
      display: inline-flex;
      justify-content: flex-start;
      .leadsDataDetailsItem {
        margin: 0.5rem;
      }
    }
  }

  .content {
    margin: 0.2rem;
    //min-width: 40rem ;
    // background-color: blue;
    //min-height: calc(100vh - 400px);
    @include phones-max {
      width: 100%;
      .Policies-dialog-wrapper .dialog-content-wrapper .dialog-content-item {
        padding: 15px;
      }
    }

    .checkBoxsPart {
      display: flex;
      flex-wrap: wrap;
    }

    .checkboxesItem {
      display: inline-flex;
      margin: 0.5rem;
    }

    .centerItem {
      display: flex;
      justify-content: center;
    }

    .address {
      width: 100%;
      padding: 0.25rem;
      display: inline-flex;
      .addressPart {
        margin: 0.5rem;
        width: 50%;
      }
    }
  }
  .MuiDialogTitle-root {
    border-bottom: 1px solid $c-gray-dark !important;
    padding-left: 2rem;
  }
  .MuiDialog-paper {
    min-width: 60rem;
    min-height: auto;
  }
  .MuiDialogContent-root {
    padding: 1rem;
    min-width: 60rem;
  }

  .dialog-content-wrapper {
    // min-width: 40rem ;
    // min-height: 5rem ;
    width: 100%;
    @include d-flex;
    flex-wrap: wrap;

    .titlePart1 {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin: 1rem;
      .titlePart2 {
        width: 50%;
      }
    }

    .Requierd-Color::after {
      content: " *";
      color: $c-red;
    }
    .contant-tabs {
      margin: 0.5rem;
      // min-width: 40rem ;
      // min-height: 5rem;
      max-width: 60rem;
      max-height: 3rem;

      .part {
        padding: 0.25rem;
      }
      .address {
        width: 100%;
        padding: 0.25rem;
        display: inline-flex;
        .addressPart {
          margin: 0.5rem;
          width: 50%;
        }
      }
    }
  }
}
// .Radios-unitsSaleListingSpecificsUsers {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   padding: 2rem;
// }
.showSensitive-bg {
  background-color: #0064ff47;
  border-radius: 6px;
}
