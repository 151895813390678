body:has(.payment-plan-management-dialog) {
    .MuiDialog-paperWidthSm {
        max-width: 720px !important;
    }
}

.payment-plan-view {
    .add-btn {
        border-radius: var(--radius-md, 8px);
        border: 1px solid var(--Component-colors-Components-Buttons-Primary-button-primary-border, #7F6944);
        background: var(--Component-colors-Components-Buttons-Primary-button-primary-bg, #7F6944);

        /* Shadows/shadow-xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        display: flex;
        padding: 10px var(--spacing-xl, 16px);
        justify-content: center;
        align-items: center;
        gap: var(--spacing-sm, 6px);
        color: #FFF;

        &:hover {
            background: lighten($color: #7F6944, $amount: 20)
        }
    }

    .payment_plan_text {
        color: var(--colors-text-text-tertiary-600, #475467);

        /* Text md/Regular */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
    }

    .payment_plan_title {
        color: var(--colors-text-text-primary-900, #101828);

        /* Display sm/Semibold */
        font-family: Inter;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
        /* 126.667% */
    }

    .payment-plans-cards-container {
        display: flex;
        align-items: center;
        gap: 32px;
        max-width: 100%;
        overflow-x: auto;
        padding-bottom: 1rem;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
            /* For Chrome, Safari, and Opera */
        }

        .payment-plan-card {
            align-self: stretch;
            display: flex;
            align-items: center;
            flex: 1 0 0;
            border-radius: var(--radius-2xl, 16px);
            border: 1px solid var(--Colors-Border-border-secondary, #EAECF0);
            background: var(--Colors-Background-bg-primary, #FFF);

            /* Shadows/shadow-lg */
            box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);

            .info-container {
                display: flex;
                width: 360px;
                padding: var(--spacing-4xl, 32px);
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: var(--spacing-xl, 16px);
                align-self: stretch;

                .title {
                    color: #101828;
                    text-align: center;

                    /* Display xs/Semibold */
                    font-family: Inter;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 32px;
                    /* 133.333% */
                }

                .text {
                    flex: 1 0 0;
                    align-self: stretch;
                    overflow: hidden;
                    color: var(--colors-text-text-tertiary-600, #475467);
                    text-overflow: ellipsis;
                    // white-space: wrap;

                    /* Text sm/Regular */
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    /* 142.857% */
                }
            }

            .informative-container {
                display: flex;
                padding: var(--spacing-4xl, 32px) var(--spacing-4xl, 32px) var(--spacing-5xl, 40px) var(--spacing-4xl, 32px);
                align-items: flex-start;
                gap: var(--spacing-3xl, 24px);
                flex: 1 0 0;
                position: relative;

                .details-container {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: var(--spacing-3xl, 24px);
                    flex: 1 0 0;

                    .installment-detail {
                        display: flex;
                        width: 296px;
                        align-items: flex-start;
                        gap: var(--spacing-lg, 12px);

                        .installment-text {
                            color: var(--colors-text-text-tertiary-600, #475467);

                            /* Text md/Regular */
                            font-family: Inter;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;
                            /* 150% */
                        }

                        .detail-icon {
                            width: 24px;
                            height: 24px;
                            flex-shrink: 0;
                        }
                    }
                }

                .action-btn-container {
                    display: flex;

                    .select-btn {
                        display: flex;
                        padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
                        justify-content: center;
                        align-items: center;
                        gap: var(--spacing-xs, 4px);
                        // position: absolute;
                        // right: 0px;
                        border-radius: var(--radius-md, 8px);
                        border: 1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD);
                        background: var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg, #FFF);

                        /* Shadows/shadow-xs */
                        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                        color: var(--Component-colors-Components-Buttons-Secondary-button-secondary-fg, #344054);

                        /* Text sm/Semibold */
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;

                        /* 142.857% */
                        &:disabled {
                            opacity: .5;
                            cursor: not-allowed;
                        }

                    }

                    .selected-btn {
                        display: flex;
                        padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
                        justify-content: center;
                        align-items: center;
                        gap: var(--spacing-xs, 4px);
                        border-radius: var(--radius-md, 8px);
                        border: 1px solid var(--Component-colors-Components-Buttons-Primary-button-primary-border, #7F6944);
                        background: var(--Component-colors-Components-Buttons-Primary-button-primary-bg, #7F6944);

                        /* Shadows/shadow-xs */
                        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                        color: var(--Component-colors-Components-Buttons-Primary-button-primary-fg, #FFF);

                        /* Text sm/Semibold */
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        /* 142.857% */
                        pointer-events: none;
                    }
                }
            }

        }
    }
}

.payment-plan-management-dialog {
    position: relative;
    margin-top: 10%;

    .total-installment {
        position: fixed !important;
        z-index: 100;
        top: 13%;

        .MuiChip-root {
            border-radius: var(--radius-full, 9999px);
            border: 1px solid var(--Component-colors-Utility-Brand-utility-brand-200, #CABFAC);
            background: var(--Component-colors-Utility-Brand-utility-brand-50, #F4F1ED);

            h3 {
                color: #635235;
            }
        }
    }

    .radio-item {
        border-radius: var(--radius-xl, 12px);
        border: 1px solid var(--Colors-Border-border-secondary, #EAECF0);
        background: var(--Colors-Background-bg-primary, #FFF);
        display: flex;
        padding: var(--spacing-lg, 12px) var(--spacing-xl, 16px);
        justify-content: space-between;
        align-items: flex-start;
        flex: 1 0 0;

        .MuiRadio-root {
            margin: 0;
            padding: 0;
        }

        &:has(.Mui-checked) {
            border: 2px solid #7F6944;

        }

        .MuiFormControlLabel-label {
            color: var(--Component-colors-Utility-Success-utility-success-700, #067647);
            text-align: center;

            /* Text sm/Medium */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            /* 142.857% */
        }
    }

    .labeled-field__label {
        color: var(--colors-text-text-secondary-700, #344054);

        /* Text sm/Semibold */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 142.857% */
    }

    .special-label {
        color: var(--colors-text-text-secondary-700, #344054);

        /* Text md/Medium */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 150% */
    }

    .attachment-container {
        display: flex;
        min-width: 448px;
        padding: var(--spacing-xl, 16px) var(--spacing-3xl, 24px);
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-xs, 4px);
        align-self: stretch;
        border-radius: var(--radius-xl, 12px);
        border: 1px solid var(--Colors-Border-border-secondary, #EAECF0);
        background: var(--Colors-Background-bg-primary, #FFF);

        .upload-icon {
            display: flex;
            width: 40px;
            height: 40px;
            padding: 10px;
            justify-content: center;
            align-items: center;
            border-radius: var(--radius-md, 8px);
            border: 1px solid var(--Component-colors-Components-Icons-Featured-icons-Modern-featured-icon-modern-border, #EAECF0);

            /* Shadows/shadow-xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }
    }

    .start-adornment {
        color: var(--colors-text-text-secondary-700, #344054);

        /* Text md/Regular */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
    }

    .helper-text {
        color: #475467;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 142.857% */
    }

    .MuiInput-root {
        display: flex;
        padding: 10px 0px 10px 14px;
        align-items: center;
        gap: var(--spacing-md, 8px);
        flex: 1 0 0;
    }

    .MuiAutocomplete-root {

        .MuiInput-root {
            padding: 5px 7px !important;
        }

    }



    .MuiInputBase-adornedStart .MuiInput-root {
        padding: 0;
    }

}

.payment-plan-dialog-footer {
    display: flex;
    padding: 0px var(--spacing-3xl, 24px) var(--spacing-3xl, 24px) var(--spacing-3xl, 24px);
    align-items: center;
    gap: var(--spacing-lg, 12px);
    align-self: stretch;

    .cancel-btn {
        display: flex;
        padding: 10px var(--spacing-xl, 16px);
        justify-content: center;
        align-items: center;
        gap: var(--spacing-sm, 6px);
        border-radius: var(--radius-md, 8px);
        border: 1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD);
        background: var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg, #FFF);
        text-transform: capitalize;
    }

    .add-btn {
        display: flex;
        padding: 10px var(--spacing-xl, 16px);
        justify-content: center;
        align-items: center;
        gap: var(--spacing-sm, 6px);
        border-radius: var(--radius-md, 8px);
        border: 1px solid var(--Component-colors-Components-Buttons-Primary-button-primary-border, #7F6944);
        background: var(--Component-colors-Components-Buttons-Primary-button-primary-bg, #7F6944);
        color: #ffffff;
        text-transform: capitalize;

        &:hover {
            background-color: lighten($color: #7F6944, $amount: 20);
        }
    }
}


// .payment-plan-view {



// }