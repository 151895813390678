.Inquiries-card-component-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0.1rem;
  align-items: center;
  gap: 1rem;
  align-self: center;
  padding: 1rem 1.5rem 2.5rem 1.5rem;

  .Inquiries-card-wrapper {
    margin: 0.2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
    border-radius: 1rem;

    .cards-wrapper {
      display: flex;
      padding: 1.25rem 1rem;
      background: #f5faff;
      border: 1px solid #d1e9ff;

      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 0.625rem;
      border-radius: 0.5rem;
      width: 355px;
      height: 320px;
      // min-width: 280px;
      // max-width: 290px ;

      &.Listing-Shortage {
        // max-width: 295px ;
        //min-width: 290px;
        border: 1px solid #fdead7;
        background: #fefaf5;
      }

      @include rtl {
        // max-width: 320px ;
        // min-width: 330px;
      }

      .cards-header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;
        align-self: stretch;

        .information-icon {
          img {
            vertical-align: super;
            width: 1.42188rem;
            height: 1.42206rem;
            flex-shrink: 0;
          }
        }
        .header-part1 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding: 0rem 1rem;
          .headerItem1 {
            width: 95%;
            display: flex;
            justify-content: center;
            align-items: center;

            .inquiry-icon-part {
              display: flex;
              width: 3.5rem;
              height: 3.5rem;
              padding: 0.875rem;

              border-radius: 1.75rem;
              background: #d1e9ff;
              &.Listing-Shortage {
                background: #fdead7;
              }
            }
            .inquiries-id-part {
              display: flex;
              justify-content: center;
              justify-items: center;
              color: #000;
              font-style: normal;
             // font-size: 1.5rem;
              font-weight: 600;
            //  line-height: 2rem;
            }
          }
        }

        headerPart2 {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .headerItem2 {
        }

        .headerItem1 {
          width: 95%;
          .inquiry-icon-part {
            display: flex;
            width: 3.5rem;
            height: 3.5rem;
            padding: 0.875rem;
            justify-content: center;
            align-items: center;
            border-radius: 1.75rem;
            background: #d1e9ff;
          }
          .inquiries-id-part {
            display: flex;
            justify-content: center;
            justify-items: center;
            color: #000;
            font-style: normal;
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 2rem;
          }
        }

        .item-wrapper {
          margin: 1rem;
          width: 30%;
        }
      }
      .card-header2 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .status {
          color: #0C111D;
          display: flex;
          padding: 0.25rem 0.75rem;
          align-items: center;
        }
        .cancel {
          background: #fef6ee;
          color: #d92d20;
          border-radius: 1rem;
          background: #fef6ee;
          mix-blend-mode: multiply;
        }

        .open {
          color: #027a48;
          background-color: #ecfdf3;
          border-radius: 1rem;
          mix-blend-mode: multiply;
        } 
        .assigned {
          color:  #993366;
          background-color:  #ffe6f2 ;
          border-radius: 1rem;
          mix-blend-mode: multiply;
        }
        .completed {
          color: #0066cc;
          background-color:  #b3d9ff ;
          border-radius: 1rem;
          mix-blend-mode: multiply;
        }
        

       // grid-gap: 1.5rem;
       // gap: 1.5rem;
       // align-self: stretch;
       // font-size: 1.5rem;
       // font-style: normal;
       // font-weight: 600;
      }

      .cards-body {
          display: flex;
          flex-direction: row;
          width: 100%;
         
        .sections
        {
          display: flex;
          flex-direction: row;
          align-self: stretch;
          justify-content: space-evenly;
          align-items: center;
          margin: 0.2rem;
          width: 100%;
          height: auto;
          padding: 0rem 1rem;

        
       
        // line-height: 1.5rem;

        &.listing-shortage-body {
          // justify-content: center ;
        }

        .body-item {
         

          @include rtl {
            // white-space: wrap;
          }

          .status {
            color: #0C111D;
            display: flex;
            padding: 0.25rem 0.75rem;
            align-items: center;
          }
          .cancel {
            background: #fef6ee;
            color: #d92d20;
            border-radius: 1rem;
            background: #fef6ee;
            mix-blend-mode: multiply;
          }

          .open {
            color: #027a48;
            background-color: #ecfdf3;
            border-radius: 1rem;
            mix-blend-mode: multiply;
          }

          .item-value {
            color: #0C111D;
            align-self: stretch;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.5rem;

            &.Listing-Shortage {
              color: #d92d20;
            }

            .leadId-part {
              display: inline-flex;
              justify-content: flex-start;
              align-items: center;
              align-self: stretch;
              grid-gap: 0.1rem;
              .copyImage-part {
                width: 1rem;
                height: 1rem;
              }
            }
          }
        }
      }
      .second-section
      {
        display: grid;
        gap: 3px;
        grid-template-columns: repeat(3, minmax(70px, auto));
        grid-template-rows: 22px;
        align-self: stretch;
        justify-content: space-evenly;
        align-items: center;
        margin: 0.2rem;
        width: 100%;
        height: auto;
        padding: 0rem 1rem;

      
     
      // line-height: 1.5rem;

      &.listing-shortage-body {
        // justify-content: center ;
      }

      .body-item {
        display: flex;
        flex-direction: row;
        white-space: nowrap;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-style: normal;
        color: #000;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.25rem;

        @include rtl {
          // white-space: wrap;
        }

        .status {
          color: #0C111D;
          display: flex;
          padding: 0.25rem 0.75rem;
          align-items: center;
        }
        .cancel {
          background: #fef6ee;
          color: #d92d20;
          border-radius: 1rem;
          background: #fef6ee;
          mix-blend-mode: multiply;
        }

        .open {
          color: #027a48;
          background-color: #ecfdf3;
          border-radius: 1rem;
          mix-blend-mode: multiply;
        }

        .item-value {
          color: #0C111D;
          align-self: stretch;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.5rem;

          &.Listing-Shortage {
            color: #d92d20;
          }

          .leadId-part {
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            align-self: stretch;
            grid-gap: 0.1rem;
            .copyImage-part {
              width: 1rem;
              height: 1rem;
            }
          }
        }
      }
    }
    .body-section
    {


      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    
   

    &.listing-shortage-body {
      // justify-content: center ;
    }

    .body-item {
      display: flex;
      flex-direction: row;
      white-space: nowrap;
      justify-content: center;
      align-items: center;
     // text-align: center;
    //  font-style: normal;
      color: #000;
      //font-weight: 500;
   //   line-height: 1.25rem;

      @include rtl {
        // white-space: wrap;
      }

      .status {
        color: #0C111D;
        display: flex;
        padding: 0.25rem 0.75rem;
        align-items: center;
      }
      .cancel {
        background: #fef6ee;
        color: #d92d20;
        border-radius: 1rem;
        background: #fef6ee;
        mix-blend-mode: multiply;
      }

      .open {
        color: #027a48;
        background-color: #ecfdf3;
        border-radius: 1rem;
        mix-blend-mode: multiply;
      }

      .item-value {
        color: #0C111D;
        align-self: stretch;
      //  font-size: 1rem;
      //  font-style: normal;
       // font-weight: 500;
       // line-height: 1.5rem;

        &.Listing-Shortage {
          color: #d92d20;
        }

        .leadId-part {
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          align-self: stretch;
          grid-gap: 0.1rem;
          .copyImage-part {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }
  }
      }

      &.active {
        box-shadow: 0px 1px 8px #000000cf;
      }
    }
  }
}
