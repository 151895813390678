.QualificationFilter-wrapper {
  margin-bottom: 1rem;
  .btns.MuiButtonBase-root {
    border-radius: 30px;
    min-height: 26px;
    line-height: 100%;
    min-width: 85px;
    margin: 0 0.25rem;
    padding: 0.219rem 0.5rem;
    text-transform: initial;
    font-size: 13px;
    color: var(--c-primary-blue);
    white-space: nowrap;
    &.Qualifiedlead {
      border: 1px solid #b3d984;
      margin: 8px;
    }
    &.Opportunity {
      border: 1px solid #3a6a57;
      margin: 8px;
    }
    &.Activeitem {
      background-color: var(--c-primary-blue);
      border: 1px solid var(--c-primary-blue) !important;
      color: rgb(255, 255, 255) !important;
    }
    &.UnqualifiedLead {
      border: 1px solid #ce4f2f;
      margin: 8px;
    }
    &.QualifiedbyQA {
      border: 1px solid #6c2fce;
      margin: 8px;
    }
  }
}
