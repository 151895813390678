.approvals-user-table {
    .pending-chip {
        background-color: #3498db !important;
        color: white !important;
        min-width: 5.5rem;
    }

    .approved-chip {
        background-color: #2ecc71 !important;
        color: white !important;
        min-width: 5.5rem;
    }

    .rejected-chip {
        background-color: #e74c3c !important;
        color: white !important;
        min-width: 5.5rem;
    }

    .expired-chip {
        background-color: #95a5a6 !important;
        color: white !important;
        min-width: 5.5rem;
    }

    .unknown-chip {
        background-color: #7f8c8d !important;
        color: white !important;
        min-width: 5.5rem;
    }

}

/* PreviewDialog.css */


.dialog-content {
    padding: 16px;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .detail-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    border-bottom: 1px solid #f2f2f7;
    padding-bottom: 5px;
  }
  
  .detail-label {
    font-weight: bold;
    color: #333;
    font-size: 14px;
  }
  
  .detail-value {
    color: #555;
    font-size: 14px;
  }
  
  .change-history {
    margin-top: 20px;
  }
  
  .change-history-title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .change-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    padding-bottom: 5px;
    border-bottom: 1px solid #f2f2f7;
  }
  
  .change-field {
    font-weight: bold;
    color: #333;
    flex-basis: 30%;
  }
  
  .change-from,
  .change-to {
    color: #555;
    flex-basis: 30%;
  }
  
  .no-changes {
    color: #777;
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
  }
  