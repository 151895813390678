.Card {
  display: flex;
  width: 336px;
  height: 100%;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid #c7c7cc;
  background: #fff;

  p {
    margin: 0px;
  }

  .Card_Content {
    display: flex;
    width: 100%;
    padding-bottom: 12px;
    flex-direction: column;
    gap: 8px;
  }

  .Card_Buttons {
    padding-top: 12px;
    padding-bottom: 8px;
    border-top: 1px solid rgba(199, 199, 199, 0.78);
    width: 100%;
    display: flex;
    justify-content: space-around;

    .blueBtnTheme {
      border: 1px solid #3b72d9 !important;
      background: #3b72d9 !important;
    }
  }

.Card_section1 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 12px;

  .Card__Main_info {
    position: relative;
  }

  .bookmark_icon {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 2;
    cursor: pointer;
  }
  .bookmark_icon svg{
    fill: #175cd3;
  }
}

  .Card_Image {
    background-color: rgba(199, 199, 199, 0.78);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: 135px;
    width: 125px;
    border-radius: 0.5rem;
  }

  .Card_section2 {
    display: flex;
    gap: 8px;

    p {
      font-weight: 500;
    }
  }

  .Capsules {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .Solid_capsule {
    display: flex;
    padding: 1.6px 8px;
    align-items: center;
    border-radius: 16px;
    background: #f2f2f7;
    gap: 4px;
    margin-inline: 4px;
    font-weight: 500;
  }

  .Outlined_capsule {
    display: flex;
    padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
    align-items: center;
    border-radius: var(--radius-full, 9999px);
    border: 1px solid var(--Component-colors-Utility-Blue-utility-blue-200, #b2ddff);
    background: var(--Component-colors-Utility-Blue-utility-blue-50, #eff8ff);

    span {
      color: var(--Component-colors-Utility-Blue-utility-blue-700, #175cd3);
      font-size: 12px;
      font-weight: 500;
    }
  }
}

.Card_wrapper{
  position: relative;

  .Side_popup_btn {
    display: none;
    position: absolute;
    z-index: 2;
    background: #cedbf5;
    width: 32px;
    min-width: 32px;
    height: 40px;
    padding: 0px;
    bottom: 105px;
    right: 0px;
    border-radius: 10px 0px 0px 10px;
    box-shadow: 0px 2px 4px 0px rgba(16, 24, 40, 0.05);
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &:hover{
    .Side_popup_btn {
      display: inline-flex;
    }
  }
  .Location_info {
    p {
      font-size: 10px;
      font-weight: 500;
    }
    span {
      font-size: 20px;
      line-height: 0.5;
      margin-bottom: 8px;
      margin-inline: 4px;
    }
  }
}