@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loadingSpinnerContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  gap: 16px;

  label {
    font-family: Inter;
    font-weight: 500;
  }
}

.smallContainer {
  height: 16px;
  padding: 10px;
}

.extraSmallContainer {
  height: 16px;
  padding: 0;
}

.base {
  animation: spin 0.75s linear infinite, fade-in 0.25s linear;
}

.large {
  @extend .base;
  width: 64px;
}

.medium {
  @extend .base;
  width: 56px;
}

.small {
  @extend .base;
  width: 48px;
}

.extraSmall {
  @extend .base;
  width: 32px;
}
