.Transaction-Layout {


  .b-top {
    border-top: 1px #F1F2F2 solid;
  }

  .b-bottom {
    border-bottom: 1px #F1F2F2 solid;
  }

  .light-text {
    color: #475467;
  }

  .line {
    display: inline-block;
    height: 1px;
    background-color: #EAECF0;
  }

  .from-to {
    margin-inline: 8px;
    margin-top: 2px;
    font-size: 20px;
  }

  .dialog-title-wrapper {
    padding-bottom: 1rem !important;
  }

  .MuiDialogContent-root {
    padding: 0px !important;
  }

  .Layout-container {
    display: grid;
    grid-template-columns: 20% auto;
    column-gap: 2%;
  }

  .propx-stepper-wrapper {
    padding-block: 1.5rem;
    padding-inline: .5rem;
    width: 50%;
    margin: auto;

    .MuiStepIcon-active {
      fill: #7f6944 !important;
    }

    .MuiStepConnector-horizontal {
      top: 12px !important;
      bottom: initial !important;
    }

    .MuiStepLabel-horizontal {
      flex-direction: column-reverse !important;
    }
  }

  .agent_commission_widget {
    background-color: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 0.5rem;
    display: flex;
    padding: 4px 14px;
    gap: 8px;
    max-width: 250px;

    .MuiAvatar-root {
      border: 0.5px solid rgba(0, 0, 0, 0.08);
      width: 20px;
      height: 20px;
      font-size: 8px;
    }
  }

}