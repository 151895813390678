@import "../../../../../../../../assets/theme-style/Helpers/Variables/Utilities/ColorsVariables.Style.scss";

.KycFormsView,
.newKycFormsView {
  .signature_input {
    display: flex;
    height: 120px;
    padding: 3.584px 7.167px;
    align-items: flex-start;
    gap: 3.584px;
    align-self: stretch;
    border: 0.717px dashed #000;
    color: #8c8c8c;

    canvas {
      border: unset;
    }
  }

  .client-form {
    position: fixed;
  }

  .form-top-actions {
    button {
      padding: 0px !important;
      width: 42px;
      height: 40px;
      min-width: fit-content;
    }
    button:hover {
      transform: scale(1.08);
    }
    .outlined .mdi::before {
      color: #363f72;
    }
  }

  .btns.MuiButtonBase-root.theme-propx.solid {
    background-color: #363f72;
  }
}

.newKycFormsView {
  width: 100%;
  padding: 24px 32px;
  border-radius: 12px;
  border: 1px solid var(--colors-border-border-secondary);
  background: transparent;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  .signature_input {
    background-color: var(
      --component-colors-utility-gray-utility-gray-100
    ) !important;
    color: var(--colors-foreground-fg-primary-900) !important;
    opacity: 0.35;

    canvas {
      border: unset;
    }
  }

  .PageHeading {
    color: var(--colors-foreground-fg-primary-900);

    h5 {
      margin-bottom: 4px !important;
    }

    p {
      color: var(--colors-foreground-fg-tertiary-600);
    }
  }

  .actions {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    padding: 0 32px 32px;
  }
}

.buttonActions {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.psiLogo {
  margin-bottom: 16px;
  flex-direction: column;
  gap: 32px;
  display: flex;
  justify-self: flex-start;
}