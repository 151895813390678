.header-section-activities-communication-Logs{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
}
.filter-section-activities-communication-Logs{
    display: flex;
    justify-content: flex-end;
    margin: .5rem ;
   // gap: .5rem ;
   .section-filters 
   {
     display: flex;
     justify-content: center;
     margin: .5rem ;

   }

    .section  {

      display: flex;
      flex-direction :column ; 
      .item-filter {
        margin: .2rem  ;
        color: firebrick ;
      }
     .section-filters 
      {
        display: flex; 
        justify-content: center;
        margin: .2rem  ;
        color: navy;


      }
    .section-filters2 
    {
        display: flex;
        justify-content: flex-start;

    }   
 }


    
}

.datePickerComponent-activities-communication-Logs{
    width: 20rem;
}
