.accordionTable {

  .accordionTable_Row--expanded {
    border-top: 2.5px solid #ebebeb;
    border-bottom: 1px solid #f3f3f3;
  }
  .accordionTable_Cell--expanded{
    padding-block: 24px;
  }

}
