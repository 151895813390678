.DialogSystemNotificationContent {
    height: auto;
    font-size: 0.9rem;
    min-height: 2rem !important;
    vertical-align: middle;
    display: inline-block;
    line-height: 18px;
}

.DialogSystemContainer {
    height: 200px;
    line-height: 200px;
}
.datePickerComponentSystem {

    flex-direction: row-reverse;
    margin: 0px 0px 10px 0px; 
    display: flex;
    width: 100%;
}