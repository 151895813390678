.contacts-cards-wrapper {
  @include d-flex-h-center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 0.5rem;
  .contacts-card-wrapper {
    .cards-wrapper {
      @include d-flex-column;
      position: relative;
      border: 1px solid $c-gray-secondary;
      border-radius: 1.1rem;
      color: $c-black;
      .cards-header-wrapper {
        @include d-flex-v-center;
        padding: 0.5rem 0.5rem 0;
      }
      .cards-body-wrapper {
        @include d-flex;
        width: 100%;
        padding: 0.5rem;
        padding-top: 0.8rem;
        .cover-image {
          background-color: $c-gray-lighter;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          height: 50px;
          min-height: 50px;
          width: 50px;
          border-radius: 0.5rem;
          margin: 0 0 0.5rem;
        }
        .body-title-wrapper {
          width: 95%;
          padding: 0.5rem;
          padding-top: 0.1rem;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          .created-on {
            @include d-inline-flex;
            .mdi {
              vertical-align: bottom;
              padding-right: 0.5rem;
              @include rtl {
                padding-right: 0;
                padding-left: 0.5rem;
              }
              &::before {
                font-size: $fz-16px;
              }
            }
          }
          .body-title {
            font-size: $fz-16px;
            font-weight: $fw-simi-bold;
            padding-bottom: 0.3rem;
            color: $c-black;
          }
        }
        .hedar-card {
          @include d-inline-flex;
          @include phones-s-max {
            flex-wrap: wrap;
          }
          width: 100%;
        }
        .actions-wrapper {
          @include d-flex-column-v-center;
          // @include flex-;
          height: 100%;
          width: 100%;
          padding: 0 0.5rem 0;
          .btns-icon {
            margin-bottom: 1rem;
          }
        }
        .card-body-section:first-child {
          @include d-flex-column-v-center;
          min-width: 50px;
          max-width: 60px;
        }
        .card-body-section:nth-child(2) {
          @include d-flex-column;
          width: 100%;
          .details-item-wrapper {
            @include d-flex;
            width: 100%;
            padding: 0 0.5rem;
            margin-bottom: 0.3rem;
            min-width: 200px;
            max-height: 35px;
            overflow: hidden;
            text-overflow: ellipsis;
            align-self: flex-start;
          }
          .details-text {
            // white-space: nowrap;
            white-space: break-spaces;
          }
          .details-icon {
            vertical-align: bottom;
          }
          .details-icon,
          .details-text {
            font-weight: $fw-bold;
            white-space: break-spaces;
          }
          .details-item {
            display: block;
            // white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @supports (display: -webkit-box) {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
          &.is-expanded {
            flex-direction: row;
            flex-wrap: wrap;
            .details-item-wrapper {
              width: 33.3333%;
              max-width: 33.3333%;
              @include phones-max {
                width: 100%;
                max-width: 100%;
              }
            }
          }
        }
      }
      .cards-progress-wrapper {
        width: 100%;
      }
      .cards-footer-wrapper {
        @include d-flex-v-center;
        flex: 1 1 auto;
        height: 40px;
        min-height: 40px;
        overflow: hidden;
        border-radius: 0 0 1.1rem 1.1rem;
        width: 100%;
        padding: 0;
        border-top: 1px solid $c-gray-secondary;
        display: flex;
        justify-content: center !important;

        .btns {
          position: relative;
          border-radius: 0;
          height: 100%;
          min-height: 40px;
          color: $c-black;
        }
      }
      .type-wrapper {
        display: flex;
        align-items: center;
        padding: 0.5rem 0.25rem;
        margin-top: -0.2rem;
        .type-item {
          color: $c-black;
          border-radius: 12px;
          margin-left: 0.2rem;
          margin-right: 0.2rem;
          min-width: 3rem;
          min-height: 1.3rem;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          &.seller {
            background-color: #e8f0ff;
          }
          &.buyer {
            background-color: #ffe6e5;
          }
          &.landlord {
            background-color: #ffebcc;
          }
          &.tenant {
            background-color: #d6f4ee;
          }
        }
      }
      .contact-id-wrapper {
        width: 100%;
        font-size: $fz-14px;
        font-weight: bold;
        display: flex;
        //  align-items: center;
        @include phones-max {
          justify-content: flex-end;
        }
        @include phones-s-max {
          justify-content: flex-start;
        }
        justify-content: center;
        @include rtl {
          width: 100%;
          text-align: center;
          align-items: center;
        }
        textarea {
          width: 0;
          height: 0;
          padding: 0;
          opacity: 0;
        }
        .contact-id {
          margin-left: 0.01rem;
          margin-right: 0.01rem;
          @include rtl {
            justify-content: center;
            align-items: center;
            display: flex;
          }
          textarea {
            resize: none;
          }
        }
        .mdi::before {
          // margin-left: 0.5rem;
          margin-right: 1rem;
          font-size: $fz-14px;
          cursor: pointer;
          color: $c-primary;
        }
      }
      .merge-wrapper {
        position: relative;
        .merged-contact {
          cursor: pointer;
          width: 1rem;
          height: 1rem;
          border-radius: 0.2rem;
          position: absolute;
          bottom: 7px;
          left: 1px;
          background-color: $c-white;
          .mdi::before {
            font-size: $fz-16px;
            color: $c-primary;
          }
        }
      }
    }
    &.is-open {
      .cards-wrapper {
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.25);
        color: $c-secondary;
        .cards-footer-wrapper {
          .btns {
            color: $c-secondary;
          }
        }
      }
    }
    position: relative;
    @include d-inline-flex-column;
    padding: 0 0.5rem;
    min-width: 355px;
    @include phones-max {
      min-width: 290px;
    }
    margin-bottom: 1rem;
    width: 20%;
    max-width: 380px;
    @include laptops-xl-max {
      width: 30%;
    }
    @include laptops-l-max {
      width: 33.3333%;
    }

    @include laptops-max {
      width: 50%;
    }

    @include phones-max {
      width: 100%;
      max-width: initial;
    }
    &.is-expanded {
      width: 50%;
      max-width: initial;
      // @include laptops-xl-max {
      //   width: 33.3333%;
      // }

      // @include laptops-l-max {
      //   width: 50%;
      // }

      @include laptops-max {
        width: 100%;
      }
    }
    .ribbon {
      position: absolute;
      cursor: pointer;
      z-index: 2;
      padding: 0px 4px;
      margin-right: 2.4%;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      font-size: $fz-10px;
      line-height: 18px;
      font-weight: bold;
      text-align: center;
      color: #fff;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      zoom: 1;
      top: 3.3%;
      right: -3%;
      min-width: 10%;
      height: 18px;
    }
    .extra-top {
      top: 22% !important;
    }
    .blue {
      background: #3b72d9;
    }
    .contact-opportunity-ribbon{
      background: #caced6;
      color: black;
    }
    .contact-opportunity-ribbon:after{
      border-color: transparent #caced6;
    }
    .top {
      top: 13.3%;
    }
    .ribbon:after {
      content: '';
      position: absolute;
      z-index: -20;
      top: 100%;
      left: auto;
      border-style: solid;
      border-width: 0 0 10px 10px;
      right: 0;
    }
    .blue:after {
      border-color: transparent #214280;
    }
    .no-ribbon {
      height: 41px;
      position: absolute;
      z-index: 2000;
    }
    .card-checkbox-wrapper{
      top: 0.6rem
    }
  }
}
.popover-contact-prefernces {
  .MuiPaper-elevation8 {
    box-shadow: 0 2px 8px 0 rgba(114, 114, 114, 0.2), 0 6px 20px 0 rgba(187, 187, 187, 0.116) !important;
    padding: 0.5rem;
  }
  .MuiPaper-root {
    margin-top: 0.3rem;
    border-radius: 0.5rem;
  }
}

.details-item-flex {
  display: flex !important;
}

.contacts-text-wrap {
  white-space: nowrap !important;
}

.contacts-card-text {
  width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
