.support-view-wrapper {
  height: 100vh;
  display: grid;
  grid-template-rows: minmax(80px, auto) 1fr minmax(70px, auto);

  p {
    margin: 0;
  }
}

.support-header-wrapper {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaecf0;

  .header-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    margin-right: 5rem;
    margin-left: 5rem;
  }

  .header-logo {
    width: 2rem;
    height: 1.79rem;
    margin-right: 0.5rem;
  }

  .page-title {
    display: flex;
    align-items: center;
    font-size: $fz-18px;
    font-weight: 600;
  }

  .vertical-line {
    border-left: 2px solid #333;
    height: 1.3rem;
    display: inline-block;
    margin: 0 1rem;
  }

  .user-section-wrapper {
    display: flex;

    .header-user-img img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 0.75rem;
      margin-left: 0.75rem;
    }

    .header-user-name :first-child {
      font-weight: 600;
      font-size: $fz-14px;
      line-height: 20px;
      color: #344054;
    }

    .header-user-name :nth-child(2) {
      font-weight: 400;
      font-size: $fz-14px;
      line-height: 20px;
      color: #344054;
    }
  }
}
