.reports-view-wrapers {
  display: block;
  justify-content: flex-start;
  position: relative;
  width: 100%;

  .export-excel {
    text-align: flex-end;
  }

  .filter-section {
    display: flex;
    flex: 1 1 100%;
    justify-content: flex-start;
    justify-items: center;
    position: relative;
    width: 100%;
  }
  .btns-icon.MuiButtonBase-root.theme-solid {
    border-radius: 30%;
  }
  .section-top {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .arrowImage {
    @include rtl {
      transform: rotateY(180deg);
    }
  }
  .ReportEmptyPage {
    font-size: 1rem;
    margin: auto;
    text-align: center;
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: auto;
  }

  .excelPhoto {
    border: 1px solid #dedede;
    border-radius: 100%;
    padding: 0.5rem;
    background-color: white;
    .excelImage {
      min-width: 20px;
      height: 20px;
      width: 20px;
    }
  }
}

.PrintRepertDialog-view {
  .Active-btt-menu {
    display: flex;
    justify-content: space-evenly;
    .menu-button {
      width: 180px;
      height: 86px;
      border: 1px solid #c7c7cc;
      border-radius: 10px;
      font-size: $fz-16px;
      flex-direction: column;
      justify-content: space-evenly;
      &.is-active {
        border: 1px solid var(--c-primary);
        color: var(--c-primary);
        background: #e8f0ff;
      }
    }
  }

  .outer-select-container {
    display: inline-flex;
    justify-content: center;
    width: 100%;
  }
  .outer-left {
    -ms-transform: skew(-30deg, 0deg);
    /* IE 9 */
    -webkit-transform: skew(-30deg, 0deg);
    /* Chrome, Safari, Opera */
    transform: skew(-30deg, 0deg);
    background: #333333;
    width: 200px;
    z-index: 20;
    border-bottom: 3px solid var(--c-primary);
    padding: 10px 30px 10px 75px;
    font-size: $fz-20px;
    color: var(--c-primary);
    position: relative;
    // left:-50px;
  }
  .outer-left:after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 100%;
    background: var(--c-primary);
    float: right;
    right: 0px;
    top: 0px;
    z-index: 10;
  }

  .outer-left::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 100%;
    background: var(--c-primary);
    float: left;
    left: 0px;
    top: 0px;
    z-index: 10;
  }
  .inner-left {
    -ms-transform: skew(30deg, 0deg);
    /* IE 9 */
    -webkit-transform: skew(30deg, 0deg);
    /* Chrome, Safari, Opera */
    transform: skew(30deg, 0deg);
    display: inline-block;
  }
}
.ReportPdfView-PAGE {
  .Report-Fab {
    position: fixed;
    right: 5rem;
    z-index: 10;
    top: 1rem;
  }
  @media print {
    .aReport-Fab:after {
      content: none !important;
    }
    .Report-Fab[href]:after {
      content: none !important;
    }
    .Report-Fab {
      display: none;
    }

    .mdi:before {
      display: none !important;
    }

    .mdi {
      display: none !important;
      content: none !important;
    }
  }
}
