.Card {
  display: flex;
  width: 350px;
  padding: 24px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-xl, 16px);
  color: var(--colors-text-text-primary-900, #101828);
  border-radius: var(--radius-xl, 12px);
  border: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
  background: var(--Colors-Background-bg-primary_alt, #fff);
  cursor: pointer;

  p {
    margin: 0px;
  }

  .Card_ID {
    font-size: 12px;
    font-weight: 700;
    line-height: 17.14px;
    margin-bottom: 8px;
  }

  .Card__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
  }

  .Card__title_icon {}

  .Price {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .Price__currency {
    color: #98a2b3;
    font-size: 12px;
    font-weight: 600;
    line-height: 17.14px;
  }

  .CreatedOn {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .UnitType {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .CreatedOn_title {
    color: var(--colors-text-text-secondary-700, #344054);
  }

  .Outlined_capsule {
    width: fit-content;
    display: flex;
    padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
    margin-top: 8px;
    align-items: center;
    border-radius: var(--radius-full, 9999px);
  }

  .Outlined_capsule_Text {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
  }

  .Outlined_capsule__red {
    @extend .Outlined_capsule;
    border: 1px solid var(--Component-colors-Utility-Error-utility-error-200, #fecdca);
    background: var(--Component-colors-Utility-Error-utility-error-50, #fef3f2);
  }

  .Outlined_capsule__red_Text {
    @extend .Outlined_capsule_Text;
    color: var(--Component-colors-Utility-Error-utility-error-700, #b42318);
  }

  .Outlined_capsule__blue {
    @extend .Outlined_capsule;
    border: 1px solid #719FF5;
    background: #EBF5FF;
  }

  .Outlined_capsule__blue_Text {
    @extend .Outlined_capsule_Text;
    color: #2153B1;
  }
  
}