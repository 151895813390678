.Page_Layout {
  width: 100%;
  display: grid;
  gap: 24px;
  padding-block: 2rem;

  p,
  h5 {
    margin: 0px;
    font-weight: unset;
  }

  .Content_Box {
    position: relative;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    height: fit-content;
    min-height: 220px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
    background: #fcfcfd;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    & > :first-child {
      width: 100%;
      border-bottom: 1px solid #eaecf0;
      padding-bottom: 14px;

      p {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }

  .Overview {
    display: grid;
    grid-template-rows: 3fr 1fr auto;
    gap: 14px;
  }

  .Content,
  .Top_Section {
    display: grid;
    grid-template-columns: 7fr 3fr;
    gap: 24px;
  }

  .Side_Section {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .Card_Buttons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .blueBtnTheme {
    border: 1px solid #3b72d9 !important;
    background: #3b72d9 !important;
  }
}

.Top_Details_Box {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .Title {
    display: flex;
    align-items: center;
    gap: 8px;

    h5 {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
    }

    span {
      color: #3b72d9;
      font-size: 16px;
      font-weight: 600;
    }
    .idRef {
      color: #004eeb !important;
    }
  }
  .TypeWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .Type {
    color: var(--Primary-color, #272963);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
  .UnitNumber {
    color: #004eeb;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}

.Price {
  display: flex;
  flex-direction: column;
  gap: 8px;

  span {
    color: #3b72d9;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
  }

  p {
    color: var(--Primary-color, #272963);
    font-size: 32px;
    font-weight: 600;
    line-height: 30px;
  }
}

.Capsules_container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .Outlined_capsule__gray {
    display: flex;
    padding: var(--spacing-xxs, 2px) var(--spacing-md, 8px);
    align-items: center;
    border-radius: var(--radius-full, 9999px);
    border: 1px solid var(--Component-colors-Utility-Gray-utility-gray-200, #eaecf0);
    background: var(--Component-colors-Utility-Gray-utility-gray-50, #f9fafb);

    span {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: var(--Component-colors-Utility-Gray-utility-gray-700, #344054);
      text-align: center;
    }
  }
}

.Courasel {
  position: relative;
  display: grid;
  grid-template-columns: 4fr 4fr 2fr;
  grid-template-rows: 400px;
  gap: 16px;
  height: 400px;

  .Courasel_Image {
    border-radius: 8px;
    background-image: url('../../../../../../../../assets/images/defaults/home_placeholder.jpg');
    background-color: #f5f5f5;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center;

    img {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .Image_Wrap {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-rows: 192px 192px;
    gap: 16px;
  }

  .Courasel_button {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 10px !important;
  }
}
