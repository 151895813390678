@import "../../../assets/theme-style/master.scss";

.mobileCalenderWrapper {
  font-family: Inter;
  .activity-events-wrapper {
    position: absolute;
    max-width: unset;
    right: unset;
  }
  .mobileCalenderContainer {
    display: flex;
    gap: 12px;
    align-items: center;

    .title {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .text {
    margin: 16px 0 4px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
}
