.custom-table {
    .table-item {
        margin: .5rem;
      border: 1px solid #e0e0e0;
      padding: 10px;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .left-side-table{
        display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 4rem;
        .unit-type,
        .num-ads{
            display: flex;
          flex-direction: row;
          align-items: center;
          gap:7px;
          width: 11rem;
            
        }
        span{
            color: #8E8E93;
            font-size: 15px;
            font-weight: 400;
            line-height: 22.5px;
        }
        h5{
            font-size: 19px;
            font-weight: 600;
            color: #000000;
            line-height: 31.5px;
        }
    
        .details-container {
            background-color: #F0F0F0;
            border: 1px solid #e0e0e0;
            border-radius: 4rem;
            gap: 16.5px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .sub-details-container {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 0 10px;
              gap: 4px;
              svg {
                width: 16.22px;            
                height: 16.22px; 
              }
            }
            h5 {
                font-size: 15px;
                font-weight: 400;
                color: #000000;
                line-height: 22.5px;
              }
          }
      }
      .right-side-table{
        .price-info{
          display: flex;
          flex-direction: row;
          align-items: baseline;
          gap:7px;
          width: 20.2rem;

        }
        span{
            color: #8E8E93;
            font-size: 15px;
            font-weight: 400;
            line-height: 22.5px;
        }
        h5{
            font-size: 19px;
            font-weight: 600;
            color: #000000;
            line-height: 31.5px;
        }
        p {
            font-size: 15px;
            font-weight: 400;
            color: #000000;
            line-height: 22.5px;
          }

      }
    }
  
 
  
 
  }
  
  