.editOwnersDialog-view {
  .label-wrapper {
    font-size: 15px;
    // font-size: 0.9rem;
    // font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: #000000;
  }
}
.boxes{
  margin: 0.7rem 0 0.5rem 0;
  display: flex;
  justify-content: space-between;
  width: 26rem;
}
