.menu-wrapper {
  flex: 1 1 auto;
  position: relative;
  z-index: 999;
  ::-webkit-scrollbar {
    width: 0 !important;
    height: 8px;
  }
  .MuiSvgIcon-root {
    @include rtl {
      transform: scale(1);
    }
  }
  .side-menu-hover-wrapper {
    z-index: 999;
    position: absolute;
    padding: 0.5;
    padding-bottom: 1rem;
    height: auto;
    width: 14rem;
    opacity: 0;
    background-color: $c-white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    left: 4.5rem;
    overflow-y: auto;
    max-height: calc(100vh - 65px);
    transition-property: opacity !important;
    transition-duration: 1.18s !important;
    transition-timing-function: linear;
    direction: rtl;
    @include rtl {
      direction: ltr;
    }
    &.is-hover {
      opacity: 1;
    }
    &.not-hover {
      opacity: 0;
    }
    @include rtl {
      left: initial;
      right: 4.5rem;
    }
    .btns-submenu {
      color: $c-gray-dark;
      padding-left: 1.5rem;
      display: flex;
      justify-content: flex-start;
      flex-direction: row-reverse;
      @include rtl {
        padding-left: 0;
        padding-right: 1.5rem;
      }
    }
    .mdi::before {
      color: $c-gray-dark;
    }
    .menu-hover-name {
      padding: 1.2rem 1rem 1rem 2rem;
      border-radius: 0 15px 0 0;
      text-align: left;
      position: sticky;
      top: 0;
      @include rtl {
        padding: 1rem 1.2rem 1rem 2rem;
        border-radius: 15px 0 0 0;
        text-align: right;
      }
      color: $c-white;
      background-color: $c-primary;
      font-weight: bold;
      font-size: 14px;
    }
    .hover-menu-active {
      background-color: #f2f2f7;
      .sub-menu-hover-item-name {
        color: var(--c-primary);
        font-weight: bold;
      }
      .mdi::before {
        color: var(--c-primary);
      }
      &::after {
        content: ' ';
        height: 3.4rem;
        width: 5px;
        border-radius: 0.5rem 0 0 0.5rem;
        background-color: $c-primary;
        position: absolute;
        right: 0;

        @include rtl {
          right: initial;
          left: 0;
          border-radius: 0 0.5rem 0.5rem 0;
        }
      }
    }
  }

  .animated-open-close {
    padding-top: 2rem;
    position: sticky;
    top: 4.5rem;
    min-height: calc(100vh - 205px);
    height: 90vh;
    overflow-y: scroll;
    z-index: 99999;
    ::-webkit-scrollbar {
      width: 5px !important;
      height: 8px;
    }
    @media only screen and (max-width: 1200px) {
      height: 400px;
      overflow-y: auto;
    }
    border-radius: 0px 0px 20px 0px;
    box-shadow: 1.8px 0 0.9px 0 rgba(0, 0, 0, 0.1);
    transition-property: max-width, min-width, width !important;
    transition-duration: 0.18s !important;
    transition-timing-function: linear;
    @include rtl {
      border-radius: 0px 0px 0px 20px;
    }

    .mdi::before {
      font-size: 16px !important;
    }
  }

  .submenu-wrapper {
    min-height: 566px;
    .submenu {
      margin-top: 1rem;
    }
  }

  .menu {
    background-image: $bg-gradient-primary-bottom;
    box-shadow: $bs-primary;
    border-radius: 0 0 2rem 0;
    @include rtl {
      border-radius: 0 0 0 2rem;
    }
    height: 95%;
    max-width: 57.5px;
    padding-top: 1rem;
    z-index: 1;
    .menu-content {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      position: sticky;
      top: 15px;

      .btns-menu {
        color: $c-blue-lighter;

        &:hover {
          color: $c-white;
        }

        &.active {
          .mdi {
            color: $c-white;
          }

          &::after {
            content: ' ';
            height: 100%;
            width: 4px;
            border-radius: 0.5rem 0 0 0.5rem;
            background-color: $c-white;
            position: absolute;
            right: 0;

            @include rtl {
              right: initial;
              left: 0;
              border-radius: 0 0.5rem 0.5rem 0;
            }
          }
        }
      }
    }
  }

  .btns-submenu {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    min-width: 70px;
    min-height: 53px;
    font-size: 14px;

    color: $c-gray-lightest;

    &:hover {
      color: $c-primary;
    }

    .icons,
    .mdi {
      margin: 0.5rem;
    }

    &.active {
      position: relative;
      color: $c-white;

      &:after {
        content: ' ';
        height: 100%;
        background-color: $c-white;
        position: absolute;
        width: 5px;
        top: 0;
        right: 0;
        border-radius: 0.5rem 0 0 0.5rem;

        @include rtl {
          right: initial;
          left: 0;
          border-radius: 0 0.5rem 0.5rem 0;
        }
      }
    }
  }
  .animated-open-close {
    display: inline-flex;
    flex: 1 1 auto;
    flex-direction: column;
    min-width: 60px;
    max-width: 60px;
    background-color: $c-primary-dark;
    box-shadow: 3px 3px 8px #00000033;
    transition-property: max-width, min-width;
    transition-duration: 0.18s;
    transition-timing-function: linear;
    border-radius: 0px 0px 20px 0px;
    @include rtl {
      box-shadow: -3px 3px 8px #00000033;
    }

    .side-menu-item-wrapper {
      width: 100%;
      padding-left: 0.1rem;

      @include rtl {
        padding-left: 0;
        padding-right: 0.1rem;
      }
      .menu-item-name {
        width: 100%;
        font-size: 14px;
        color: $c-gray-light;
        text-align: left;
        padding-left: 1.5rem;

        @include rtl {
          text-align: right;
          padding-left: 0;
          padding-right: 1.5rem;
        }
      }
    }
    .MuiAccordionSummary-root {
      max-height: 3.7rem;
    }
    .MuiPaper-rounded {
      background-color: transparent !important;
    }
    .MuiSvgIcon-root {
      color: $c-gray-light;
    }
    .MuiPaper-elevation1 {
      overflow: hidden;
      box-shadow: 0 0 0 0 transparent;
      display: grid;
    }
    .MuiAccordion-root:before {
      background-color: transparent;
    }
    .MuiCollapse-container {
      background-color: $c-secondary;
      color: $c-gray-light;
    }
    .MuiCollapse-wrapper {
      width: 100%;
    }
    .MuiAccordionDetails-root {
      padding: 0;
      overflow-y: auto;
      max-height: 13.5rem;
      direction: rtl;
      @include rtl {
        direction: ltr;
      }
    }
    .menu-item-wrapper {
      width: 100%;

      .menu-content-item {
        ::-webkit-scrollbar {
          width: 8px !important;
          height: 8px;
        }
        color: $c-gray-light;
        padding-left: 1.5rem;
        font-size: 14px !important;
        display: flex;
        justify-content: flex-start;
        flex-direction: row-reverse;
        @include rtl {
          padding-left: 0;
          padding-right: 1.5rem;
        }
        .mdi::before {
          color: $c-gray-light;
        }
        &:hover {
          .sub-menu-item-name {
            color: $c-white;
          }
          .mdi::before {
            color: $c-white;
          }
        }
        &.menu-active {
          background-color: #3b72d9;
          color: $c-white;
          .mdi::before {
            color: $c-white;
          }
        }
      }
    }
    .active-item-name {
      width: 100%;
      background-color: $c-secondary;
      font-weight: bold;
      .menu-item-name {
        color: $c-white;
      }
      .mdi::before {
        color: $c-white;
      }
    }
    .inactive-item-name {
      width: 100%;
    }

    .search-item-wrapper {
      margin-left: 0.7rem;
      @include rtl {
        margin-left: 0;
        margin-right: 0.7rem;
      }
      .inputs {
        height: 37px;
      }
      max-width: 37px;
      display: flex;
      transition-property: max-width, min-width;
      transition-duration: 0.18s;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.5rem;
      .mdi::before {
        color: $c-primary;
        font-size: 24px !important;
        cursor: pointer;
        // margin-left: -1px;
        // @include rtl {
        // margin-left: 0;
        // margin-bottom: 1rem;
        // }
      }
      .inputs.theme-form-builder .MuiInputBase-root {
        border-radius: 20rem !important;
        min-height: 37px !important;
      }
      .inputs.theme-form-builder .MuiInput-underline:before {
        border-bottom: 0 solid transparent;
      }
      .MuiInput-underline:after {
        border-bottom: 0 solid transparent;
      }
    }

    .open-close-content {
      overflow: hidden;
      position: sticky;
      top: 0;
      padding: 1rem 0 0.5rem;
      width: 100%;
      max-width: 0;
      opacity: 0;
    }

    .open-button {
      width: 24px;
      height: 24px;
      border: 1px solid #c7c7cc;
      border-radius: 100%;
      position: fixed;
      top: 5rem;
      margin-top: 1rem;
      display: flex;
      align-self: flex-end;
      z-index: 1;
      transform: translateX(50%);
      background-color: $c-white;
      @include rtl {
        transform: translateX(-50%);
      }
      &:hover {
        background-color: $c-gray-lightest;
        .mdi::before {
          color: $c-gray-dark;
        }
      }
    }
    .side-menu-item-wrapper {
      .mdi::before {
        font-size: 22px !important;
      }
      &:hover {
        .menu-item-name {
          color: $c-white !important;
          font-size: bold;
        }
      }
    }
    .side-menu-item {
      &:hover {
        .menu-item-name {
          color: $c-white !important;
          font-size: bold;
        }
      }
      width: 15rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.is-open {
      .search-item-wrapper {
        max-width: 90%;
      }
      .menu-item-summary {
        color: $c-white;
        &:after {
          content: ' ';
          height: 100%;
          background-color: $c-white;
          position: absolute;
          max-width: 0px;
          min-width: 0px;
          top: 0;
          right: 0;
          border-radius: 4px 0 0 4px;
          transition-property: right;
          transition-duration: 0.18s;
          transition-timing-function: linear;

          @include rtl {
            right: initial;
            left: 0;
            border-radius: 0 4px 4px 0;
          }
        }
      }
    }
    transition-property: border-radius;
    transition-duration: 0.18s;
    &.is-close {
      .content-wrapper {
        max-width: 100rem !important;
        transition-property: max-width, min-width;
        transition-duration: 0.18s;
      }
      .MuiCollapse-container {
        display: none;
      }
      .input-wrapper .text-field-wrapper .inputs .MuiInputBase-root {
        border-radius: 3rem;
      }
      .menu-item-summary {
        color: $c-white;
        &:after {
          content: ' ';
          height: 3.4rem;
          background-color: $c-white;
          position: absolute;
          max-width: 4px;
          min-width: 4px;
          top: 2.5px;
          right: 0;
          transition-property: right;
          transition-duration: 0.18s;
          transition-timing-function: linear;
          border-radius: 0.5rem 0 0 0.5rem;

          @include rtl {
            right: initial;
            left: 0;
            border-radius: 0 0.5rem 0.5rem 0;
          }
        }
      }
    }

    &.on-left {
      .open-button {
        align-self: flex-start;
        transform: translateX(-50%);

        @include rtl {
          transform: translateX(50%);
        }
      }
    }
    &.collabse-vertical {
      min-width: 55px;
      max-width: 55px;
      transition-property: max-width, min-width;
      box-shadow: initial;
      .MuiButtonBase-root:not(.Mui-selected):not(.open-button) {
        color: $c-blue-lighter;
      }

      .MuiTab-root {
        max-width: initial;
        transition: height 0.1s linear;
        min-width: 100px !important;
        .MuiTab-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          .mdi {
            min-width: 35px;
          }
        }
      }
      .open-close-content {
        overflow: visible;
        width: 100%;
        max-width: 170px;
        opacity: 1;
        transition-property: max-width, min-width;
      }
    }
  }
}
