.View_layout {
  margin: 3rem;
  position: relative;
}

.Cards_Container {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  width: 95%;
  gap: 16px;
  padding-block: 32px 24px;
  justify-content: center;
  align-items: center;
}

.Flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.Flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.Fw500 {
  font-weight: 500;
}

.Fw600 {
  font-weight: 600;
}

.Fs14 {
  font-size: 14px;
}

.Fs16 {
  font-size: 16px;
}

.Fs18 {
  font-size: 18px;
}

.Solid_capsule {
  display: flex;
  padding: 1.6px 8px;
  align-items: center;
  border-radius: 16px;
  background: #f2f2f7;
  font-weight: 500;
  gap: 4px;
  margin-inline: 4px;
}

.Heading_box {
  .Heading{
    display: flex;
    align-items: center;
  }

  h2,
  h3 {
    margin: 0px;
    margin-right: 8px;
    color: #3468C5;
    font-weight: 600;
    font-size: 22px;
    cursor: pointer;
  }

  h3 {
    font-size: 20px;
  }

  p {
    color: var(--colors-text-text-tertiary-600, #475467);
    margin: 0;
  }
  h4 {
    color: var(--colors-text-text-tertiary-600, #475467);
    font-weight: 500;
    font-size: 14px;
  }
}

.Button_add {
  position: absolute;
  top: 0;
  right: 0;
}