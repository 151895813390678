$border: #ddd;
$border-hover: #aaa;
$bg-hover: #eee;
$text: #888;
$text-hover: #000;
$ident: 20px;
$left: -($ident);
$right: -(-$ident);
$border-color: #8e8e93;

.bussines-groups-wrapper {
  overflow: auto;
  margin: 1rem;
  max-height: calc(100vh - 250px);
  min-height: 40rem;
  background-image: linear-gradient(263deg, #b0abab1f 10%, #00000000 10%),
    linear-gradient(#00000003 10%, #00000000 10%);
  background-size: 17px 12px;
}
.businessGroupsTreeView .genealogy-tree .wtree li.isParent:last-child:before {
  border: 0 !important;
  z-index: -5;
}
.businessGroupsTreeView .genealogy-tree .wtree li.isParent:first-child:after {
  border: 0 !important;
}
.businessGroupsTreeView .genealogy-tree .wtree li.isParent:last-child:after {
  width: 25% !important;
  border-right: 1px solid $border-color;
  height: 14px;
  z-index: -5;
  border-top-right-radius: 7px;
  @include rtl {
    width: 25% !important;
    border-left: 1px solid $border-color;
    border-right: 0px solid $border-color;
    height: 28px;
    border-top-right-radius: 0px;
    border-top-left-radius: 18px;
  }
}
.businessGroupsTreeView .genealogy-tree .wtree li.isParent:first-child:before {
  border-top-left-radius: 7px;
  @include rtl {
    border-top-left-radius: 0px;
    border-top-right-radius: 7px;
  }
}

.businessGroupsTreeView .genealogy-tree .wtree li.isParent.filetree:first-child:before {
  right: auto;
  left: -41px;
  border-left: 1px solid #8e8e93;
  content: '';
  position: absolute;
  top: 22px;
  right: 32%;
  border-bottom: 0;
  border-top: 1px solid #8e8e93;
  width: 46%;
  height: 126%;
  @include rtl {
    right: auto;
    left: -9px;
    border-left: 1px solid #8e8e93;
    content: '';
    position: absolute;
    top: 26px;
    right: 0%;
    border-bottom: 0;
    border-top: 1px solid #8e8e93;
    width: 46%;
    height: 122%;
  }
}

.businessGroupsTreeView .genealogy-tree .wtree li.isParent.filetree:last-child:after {
  width: 47px !important;
  border-bottom: 1px solid #8e8e93 !important;
  border-left: 1px solid #8e8e93 !important;
  height: 39px;
  left: -41px;
  top: 0px;
  border-bottom-left-radius: 7px;
  border-right: 0px solid #8e8e93;

  @include rtl {
    width: 20px !important;
    border-right: 1px solid #8e8e93 !important;
    border-left: 0px solid #8e8e93 !important;
    height: 29px;
    top: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 7px;
  }
}

.businessGroupsTreeView {
  $linecolor: #c7c7cc;
  .genealogy-tree {
    &.filetree {
      display: grid;
      justify-content: center;
      width: 100%;
    }
    ul:first-child {
      display: flex;
      flex: 1 1 100%;
      &.filetree {
        display: block;
      }
    }
    ul {
      &.more {
        justify-content: flex-start;
      }
      margin-left: 35px;
      @include rtl {
        margin-left: 0;
        margin-right: 0;
      }
    }
    .wtree {
      li {
        list-style-type: none;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: -30px;
          left: -20px;
          border-left: 1px solid $border-color;
          border-bottom: 1px solid $border-color;
          width: 21px;
          height: 57px;
          z-index: -1;
          @include rtl {
            left: 20px;
            right: -20px;
            border-left: 0px solid $border-color;
            border-right: 1px solid $border-color;
          }
        }
        &:after {
          position: absolute;
          content: '';
          top: 21px;
          left: $left;
          border-left: 1px solid $border-color;
          //  border-top: 1px solid $border-color;
          width: 20px;
          height: 100%;

          @include rtl {
            left: 12px;
            right: 0;
            border-left: 0px solid $border-color;
            border-right: 1px solid $border-color;
          }
        }

        &.isParent :last-child:after {
          z-index: -5;
          border-left: 0px solid $border-color !important;
          position: absolute;
          content: '';
          top: -1px;
          left: 0;
          border-left: 1px solid $border-color;
          border-top: 0px solid $border-color;
          // width: 50%;
          // height: 100%;
          @include rtl {
            left: 0px;
            right: 0px;
            border-left: 0px solid $border-color;
            border-right: 0px solid $border-color;
          }
        }

        &.isParent :last-child :before {
          z-index: -5;
          border-bottom-left-radius: 8px;

          @include rtl {
            left: 20px;
            right: 0px;
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 0px;
          }
        }

        &.isParent:after {
          border-left: 0px solid $border-color !important;
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          border-left: 1px solid $border-color;
          border-top: 1px solid $border-color;
          width: 50%;
          height: 0%;

          @include rtl {
            right: 0;
            border-left: 0px solid $border-color;
            border-right: 0px solid $border-color;
          }
        }
        &.filetree:after {
          border-top: 0px solid $border-color !important;
        }
        &.isParent:before {
          right: auto;
          left: 40%;
          border-left: 1px solid $border-color;
          content: '';
          position: absolute;
          top: 0;
          right: 50%;
          border-bottom: 0;
          border-top: 1px solid $border-color;
          width: 60%;
          height: 18px;
          @include rtl {
            left: auto;
            right: 50%;
            border-left: 0px solid $border-color;
            border-right: 1px solid $border-color;
          }
        }

        &.filetree:before {
          right: auto;
          left: 50%;
          border-left: 1px solid #8e8e93 !important;
          position: absolute;
          content: '';
          top: 19px;
          left: -41px;
          border-left: 1px solid #8e8e93;
          border-top: 1px solid #8e8e93;
          width: 40px;
          height: 124%;
          border-bottom: 4;
          @include rtl {
            left: auto;
            right: 0%;
            top: 12px;
            border-left: 0px solid $border-color !important;
            border-right: 1px solid $border-color;
          }
        }
      }
    }
    .wtree {
      display: flex;
      justify-content: center;
    }
  }
  .team-view-tree-wrapper {
    .member-view-box {
      margin-top: 1rem;
    }

    .MuiGrid-spacing-xs-3 {
      width: calc(100% + 24px) !important;
      min-height: 48px !important;
      min-width: 17rem !important;
      max-width: 17rem !important;
      margin: 0 !important;
    }
    .MuiPaper-elevation1 {
      box-shadow: none;
    }
    .MuiAccordionSummary-root.Mui-expanded {
      border-bottom: 2px solid #c4c4c4 !important;
    }
    margin-top: 13px;
    .team-item-detailes {
      position: relative;
      min-height: 48px;
      text-align: center;
      justify-content: center;
      border-radius: 11px;
      display: flex;
      justify-content: center;
      color: $c-black;
      border: 1px solid $border-color;
      position: relative;
      display: flex;
      margin-right: 20px;
      flex: 1 1 auto;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      min-width: 10rem;
      max-width: 20rem;
      &.is-open {
        border: 2px solid $c-gray-dark;
      }
      .team-name-wrapper {
        .team-name {
          font-size: $fz-14px;
        }
      }

      .item-actions {
        display: flex;
        .MuiIconButton-root {
          width: 2rem;
          height: 2rem;
        }
      }
      .MuiAccordionDetails-root {
        padding: 0;
        padding-bottom: 1rem;
      }
      .tree-item {
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
        background-color: #dfdfdf47;
        border-bottom: 1px solid #36363617;
        &.searched-node {
          margin-top: 2px;
          background-color: #e2e2e2fa;
          border-radius: 4px;
        }
        // &.active {
        //
        // }
        .MuiButtonBase-root .MuiAccordionSummary-root .Mui-expanded {
          border-bottom: 2px solid red !important;
        }
      }

      .delete-icon {
        .mdi {
          color: $c-danger;
        }
      }
      .item-name {
        min-width: 9rem;
        display: flex;
        width: 52%;
        text-align: left;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
      }
      .MuiAccordionDetails-root {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .treePaper {
  }
  .Teamgenealogy-tree {
  }
  .genealogy-tree {
  }
}
.BusinessGroupsView {
  .searchedCard {
    .triangleDown {
      border-top-color: $c-secondary;
    }

    .MuiCard-root {
      background-color: #ebf1fb;
      background-image: initial;
    }

    .genealogy-tree li div + ul li::after,
    .genealogy-tree li div + ul li::before,
    .genealogy-tree li div + ul::before,
    .genealogy-tree li div + ul ul::before {
      border-width: 1px;
      border-color: $c-secondary;
    }

    .member-card .MuiCard-root {
      border: 1px solid #758fcd !important ;
    }
    .add-bbt {
      .MuiFab-root {
        border: 1.2px solid #c7c7cc !important ;
      }
    }
  }
  .member-card {
    display: inline-block;
    justify-content: center;
    align-items: flex-end;
    z-index: 2;
  }
  .tree-node-actions {
    z-index: 5;
    position: inherit;
    margin-top: -14px;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 17rem;
    .expand-action {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      .MuiIconButton-root {
        width: 1.5rem;
        height: 1.5rem;
        background-color: #34c464;
        color: white;
      }
    }
    .add-action {
      z-index: 4;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      .MuiIconButton-root {
        width: 1.5rem;
        height: 1.5rem;
        border: 1px solid #c7c7cc;
        background-color: #ebf1fb;
      }

      &.Deleted {
        .MuiIconButton-root {
          width: 1.5rem;
          height: 1.5rem;
          background-color: #eb1515;
          color: $c-white;
        }
      }

      &.Edit {
        .MuiIconButton-root {
          width: 1.5rem;
          height: 1.5rem;
          background-color: $c-white;
          color: #1c74ef;
        }
      }
    }
  }
}
