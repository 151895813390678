.ActivityStageView {

    .opacity-5 {
        opacity: .5;
    }

    .stage-view-container {
        align-self: stretch;
        border-radius: 12px;
        border: 1px solid #EAECF0;

        &.utility-gray-50 {
            background: #F9FAFB;
        }

        &.utility-white {
            background: #FFF !important;
        }
    }

    .stage-button {
        cursor: pointer;
        display: flex;
        padding: 10px 16px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        border: 1px solid #EAECF0;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        span {
            color: #344054;
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
        }

        &.active-stage-btn {
            border: 1px solid #7F6944;
            background: #7F6944;

            span {
                color: #FFFFFF;
            }
        }
    }


    .ActivityTypeCard {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 16px;
        min-height: 50px;
        border-radius: 12px;
        border: 1px solid #EAECF0;
        background: #FFF;

        &.active-card {
            border: 1px solid #7F6944;
        }
    }

    .activity-card-container {
        max-height: 35vh;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
        }
    }

}

.custom-dialog-content {
    display: flex;
    flex-direction: column;

    .activity-details {
        margin: auto;
        padding: .5rem 1rem;
        margin: 0;
        border-radius: 12px;
        border: 1px solid gainsboro;
        gap: 1rem;

        .w-20 {
            width: 20%;
        }

        .no-break {
            white-space: nowrap;
        }


    }

    @mixin lighter-font-color {
        color: #737b89;
    }
    .lighter-font-color {
        @include lighter-font-color;
        margin-bottom: .5rem;
    }


    .gap-c-1 {
        column-gap: 2rem;
    }

    .bolder {
        font-weight: 600;
    }

    .activity-type-card__label-with-element {
        display: flex;
        flex-direction: row;
        padding: 0 10px;

        .activity-type-card__label {
            width: 30%;
        }

        .element-container {
            width: 70%;
        }

    }

    .hr {
        background-color: black;
        color: black;
        width: 100%;
        margin: 0;
    }

    .input-label {
        font-size: .8rem;
        font-weight: bold;
        @include lighter-font-color

    }
}


  