.tox-statusbar__branding {
  display: none !important;
}

.bbt-templates {
  display: flex;
  justify-content: center;
  align-items: center;
}
.templates-management-dialog-wrapper {
  @include d-flex;
  width: 100%;
  flex-wrap: wrap;
  //   align-items: flex-start;

  .dialog-item-one {
    padding: 5px;

    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    padding: 0 0.5rem;
    width: 29.3333%;
    @include tablets-max {
      width: 50%;
    }
    @include phones-max {
      width: 100%;
    }
  }
  .dialog-item,
  .form-item {
    @include d-inline-flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    padding: 0 0.5rem;
    width: 33.3333%;
    @include tablets-max {
      width: 50%;
    }
    @include phones-max {
      width: 100%;
    }
  }
  .form-item {
    flex-wrap: nowrap;
  }
  .token-table-wrapper {
    @include d-flex;
    flex-wrap: wrap;
  }
  .childs-wrapper {
    width: 100%;
  }
}
.dialog-item-one-wrper {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
}

.dialog-item-one {
  padding: 5px;
  &.top-lab {
    margin-top: -10px;
  }
  width: 25rem;
}
.sms-template-contenar {
  .email--sms {
    display: inline-flex;
    width: 100%;
    align-items: flex-start;
  }
  &.view {
    display: inline-flex;
    flex: 0 1 auto;
    width: 100%;

    @include phones-max {
      flex-wrap: wrap;
    }
  }
}
.OpationTemplateComponent {
  display: inline-block;

  .MuiTypography-body1 {
    font-size: $fz-11px;
    font-weight: 900;
    line-height: 1.5;
    letter-spacing: 0;
  }

  .MuiSwitch-root {
    width: 53px;
    height: 32px;
    display: inline-flex;
    padding: 12px;
    z-index: 0;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    flex-shrink: 0;
    vertical-align: middle;
  }
  .MuiSwitch-thumb {
    width: 12px;
    height: 13px;
    border-radius: 50%;
    background-color: currentColor;
  }

  label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 0px;
    font-weight: 700;
  }
  .MuiFormControlLabel-root {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
  }
  .OpationTemplateComponent .MuiFormControlLabel-root {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: -11px !important;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
  }
  label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: -12px !important;
    font-weight: 700;
  }
}

.box_sc_conteraner {
  flex: none;
  flex-wrap: wrap;
  max-width: 58rem;
  background: repeating-linear-gradient(28deg, var(--c-primary) 05, #d7e0f3c9 2px, #ffffff 2px, #dbdbfa40 10px);
  border-radius: 10px;
  height: 6rem;
  padding: 11px;
  .makeStyles-root-14 {
    height: auto;
  }

  .classesSwitch {
    .MuiSwitch-colorSecondary.Mui-checked {
      color: #0597ff;
    }
    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
      background-color: #2092b0;
    }
    .MuiTypography-body1 {
      color: #0072ff;
    }
  }

  .MainclassesSwitchc {
    .MuiSwitch-colorSecondary.Mui-checked {
      color: #00ff2f;
    }
    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
      background-color: #2ed88c;
    }
  }
}

.JoditEditorComp {
  .sun-editor .se-wrapper .se-wrapper-inner {
    min-height: 30rem !important;
  }
  .sun-editor .se-toolbar.se-toolbar-sticky {
    position: absolute !important;
    top: 0px !important;
  }
}
.sun-editor .se-wrapper .se-wrapper-wysiwyg {
  min-height: 30rem;
}
.sun-editor .se-wrapper .se-wrapper-code {
  min-height: 30rem;
}

.UnitType-AutocompleteComponent-Templet-Bulider {
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 10px;
    // max-width: 25rem;
  }
}
.display-display-3 {
  display: inline-flex;
  flex-flow: column;
  margin-top: 26px;
  align-items: center;
  justify-content: center;
}
.ExtraCheckboxes {
  width: 35rem;
}
.btns-off {
  .btns.MuiButtonBase-root.theme-solid {
    background-color: #0000007c !important;
    color: #ffffff !important;
  }
}

.templates-Tables-Search-Key {
  max-height: 20rem;
  overflow: auto;
}
