
.advance-seacrh-second-version-wrapper {
    padding: 0rem;
    .body-title-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .body-title {
        font-size: 16px;
        font-weight: bold;
        padding: .7rem;
        color: var(--c-primary);
      }
      hr {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    }
  
    .action-wrapper {
      border-radius: 20px 20px 0px 0px;
      box-shadow: 0px -2px 6px #00000029;
      width: 100%;
      padding: 1rem;
      background-color: #ffffff;
     // z-index: 99;
    }
    .advance-search-btns {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .upper-btns {
        display: flex;
        justify-content: center;
        .btns.MuiButtonBase-root {
          min-width : 65px ; 

        }
        .is-active-filter {
          background-color  : #368750e3 !important  ;
          margin-top: 2%;
          .btns.MuiButtonBase-root {
            min-width: 80px;
  
          }
        }
        width: 100%;
        .mdi:before {
          font-size: 25px;
        }
        .delete {
          color: #db504a;
        }
      }
      .clear-all-btn {
        min-width: 50%;
        margin-top: 2%;
      }
    }
    .search-area {
      overflow-y: scroll;
      overflow-x: hidden;
      height: calc(100vh - 350px);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .operatoerArea 
      {
        display: inline-flex;
        justify-content: center;

      }
      margin-left: 0.5rem;
  
      .date-picker-wrapper {
        margin-bottom: 10px;
        .MuiInputBase-input {
          color: #000000;
          font-size: 14px;
          width: 100%;
          padding: 0.25rem 0.5rem !important;
        }
      }
      .start-adorment-icon {
        display: none;
      }
      .filter
      { 
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
         margin: .3rem ;
        .search-field-wrapper {
          width: 100%;
          display: flex;
          .search-field {
  
            width: 100%;
            .MuiInputBase-root {
              border-radius: 0.5rem 0 0 0.5rem;
            }
            .autocomplete-wrapper 
            {
              min-width: 240px 

            }
            .input-wrapper.theme-default .text-field-wrapper .inputs .MuiInputBase-root
            {
              width: 100%;
  
            }
  
          }
          .operatoer-btn {
            border-radius: 0 0.5rem 0.5rem 0;
            height: 32px;
            width: 32px;
            max-width: 32px;
            background-color: #e9e7e7;
            color: var(--c-primary);
            margin-right: 6px;
            align-items: center;
            justify-content: center;
          }
          .is-active-filter {
            background-color: #368750e3 !important;
            color: #ffffff !important;
          }
        }

      }
     
    }
  }
  
  .advance-delete-dialog-Classes {
    max-height: 700px;
  }
  .delete-wrapper-content {
    border: 1px solid #d5cfcf;
    width: 90%;
    height: 90%;
    margin: auto;
    border-radius: 15px;
    .select-all {
      display: flex;
      align-items: center;
    }
    .item-wrapper {
      display: flex;
      align-items: center;
      padding: 0 15px;
      .item {
        overflow: hidden;
        padding: 0 2px;
        width: 90%;
      }
    }
  }
  .no-content-wrapper {
    display: flex;
    justify-content: center;
    .no-content-title {
      color: #db504a;
      text-align: left;
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: bolder;
      font-family: Poppins;
      letter-spacing: 0px;
    }
    .no-content-img {
      width: 300px;
      height: 200px;
    }
  }
  