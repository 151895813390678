.table-filter-row-wrapper {
  .MuiTableCell-root {
    padding: 0.25rem 0.5rem;
  }
  .control-wrapper {
    @include d-flex-center;
    .input-wrapper,
    .date-picker-input-wrapper {
      margin-bottom: 0;
      min-width: 85px;
      .text-field-wrapper .inputs .MuiInputBase-root,
      .MuiInputBase-root {
        border-radius: 0.5rem 0 0 0.5rem;
        @include rtl {
          border-radius: 0 0.5rem 0.5rem 0;
        }
      }
    }
    &.table-filter-operators-wrapper {
      max-width: 32px;
      .is-active-filter {
        background-color: #368750e3 !important;
      }
      .table-filter-operators-btn {
        border-radius: 0 0.5rem 0.5rem 0;
        height: 32px;
        width: 32px;
        max-width: 32px;
        @include rtl {
          border-radius: 0.5rem 0 0 0.5rem;
        }
      }
    }
  }
}
.advanceSearch-popOver {
  .mdi:before {
    font-size: $fz-19px;
    border-radius: 50%;
    background-color: #f0f0f0;
    padding: 1px;
    margin: 0 10px;
    color: var(--c-primary);
  }
}

.table-filter-operator-popover {
  .MuiPaper-root {
    border-radius: 0.5rem;
    max-height: 300px;
    overflow-y: auto;
    background-color: #fafafa;
    z-index: 1;
    border: 1px solid #f0f0f0;
    transition: opacity 1ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      transform 1ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    box-shadow: none !important;
    max-width: 250px;
    padding: 0 1%;
    display: flex;
    justify-content: center;
   //  min-height: 325px;
     min-height: 100px; ;
    .table-filter-operator-wrapper {
      display: flex;
     // justify-content: space-between;
      justify-content: center;
      min-height: 35px;
      border-radius: 0.5rem;
      margin: 0 20%;
      width: 90%;
      min-width: 100px !important;
      max-width: 155px !important;
      color: #020311 !important;
      .mdi:before {
        font-size: $fz-19px;
        border-radius: 50%;
        background-color: #f0f0f0;
        padding: 1px;
        margin: 0 10px;
      }
      min-height: 35px;
      border-radius: 0.5rem;
      margin: 0;
      width: 100%;
      min-width: 250px;
      max-width: 250px;
      color: $c-black-dark !important;
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
      &.active-filter-operator {
        color: $c-primary !important;
        font-weight: $fw-bold;
      }
    }
  }
}
.units-portals-popOver {
  
  // padding: 10% 10%;
  // MuiPaper-root MuiPopover-paper MuiPaper-elevation8 MuiPaper-rounded 
  .MuiPopover-paper{
    box-shadow: none !important;
    min-height: 10px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 448px;
    // top: 343px !important;
    // left: 443px !important;

    .MuiButtonBase-root {
      justify-content: flex-start;
      min-width: 120%;
    }
  }
}
.reminder-select {
  .select-wrapper.MuiFormControl-root.theme-action-buttons .selects .MuiSelect-select {
    border: 1px solid #f0f0f0;
    min-height: 32px;
    width: 100%;
    background-color: $c-white;
    border: 1px solid #f0f0f0;
    color: $c-black;
    border-radius: 0.5rem;
    display: flex;
    flex: 1 1 100%;
    align-items: center;
    min-width: 101px;
    justify-content: center;
    flex-wrap: nowrap;
  }
}
