.agent-course-qualifications {
    display: flex;
    flex-direction: column;

    .agent-courses-qualifications-filters {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 1;
        flex-wrap: wrap;
    }

    .agent-cards-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1rem;
        margin: 3rem 0;

        .agent-card {
            position: relative;
            padding: 10px;
            border-radius: 10px;
            border: 2px #f2f2f7 solid;
            width: 300px;
            aspect-ratio: .9;
            box-shadow: 5px 5px 10px rgba(0, 0, 0, .5);

            hr {
                margin-top: 10px;
                border-width: 2px;
                margin-bottom: 0;
            }

            .agent-card-header {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                img {
                    width: 40px !important;
                    aspect-ratio: 1;

                }

                h6 {
                    margin: 0;
                }

            }

            .agent-card-body {
                padding: 10px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 100%;

                >div {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    gap: 10px;
                    width: 85%
                }
            }

            .agent-courses-container {
                max-height: 70px;
                overflow-y: auto;

                ol {
                    padding: 0 1rem;

                    div {
                        margin-bottom: 0.5rem;
                        margin-top: 0;
                    }
                }
            }

        }
    }

    .agent-card-footer {
        position: absolute;
        bottom: 10px;

        .agent-chips-container {
            margin-top: 7px;
            display: flex;
            gap: 10px;
            justify-content: center;
        }
    }
}