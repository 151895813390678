@import "../../../../../../../../../../../assets/theme-style/Helpers/Variables/Utilities/ColorsVariables.Style.scss";

.newLayoutClass {
  .KycForm {
    .Form_Table {
      font-family: inter;
      width: 100%;
      table-layout: fixed;
      margin-inline: unset;
      border-collapse: collapse;
      margin-bottom: 32px;
    }

    .Nested_Table,
    .Dynamic_Table {
      width: 100%;
      table-layout: fixed;

      &.tow-col-table {
        table-layout: auto;
        td:nth-of-type(2) {
          width: 65%;
          vertical-align: top;
          padding: 0;
        }
      }
    }

    th,
    td {
      border: 1px solid var(--colors-border-border-secondary);
    }

    td {
      padding: 0;
      font-size: 16px;
      font-weight: 400;
      color: var(--colors-foreground-fg-primary-900);
      line-height: 24px;
      overflow: hidden;

      @media (max-width: 425px) {
        font-size: 12px;
        padding: 0px 4px;
      }
    }

    .Spaced_Table_Header {
      th {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 12px;
        background-color: var(--colors-background-secondaryColor_bg_hover);
        color: unset;
        border-radius: 4px;
        border-color: transparent;
      }

      .Header_Title {
        display: block;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        color: var(--colors-background-bg-brand-section-subtle);

        @media (max-width: 425px) {
          font-size: 16px;
        }
      }
      .Header_Sub_Title {
        display: block;
        font-size: 16px;
        font-weight: 400;
        color: var(--colors-foreground-fg-brand-tertiary-600);

        @media (max-width: 425px) {
          font-size: 12px;
        }
      }
      .Header_Button {
        display: flex;
        padding: 5px 15px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 9999px;
        border: 1px solid #3b72d9;
        background: #fff;
        color: #3b72d9;
        font-weight: 400;
      }
    }

    .Space_After {
      &::after {
        content: "";
        display: block;
        height: 16px;
        background-color: transparent;
      }
    }
    .Row_Text {
      border: unset;
      padding: 15px 0px;
    }

    .Dynamic_Table {
      th {
        padding: 8px 20px;
        font-weight: 400;
        font-size: 16px;
        color: var(--colors-foreground-fg-primary-900);

        @media (max-width: 425px) {
          font-size: 12px;
        }
      }
    }

    //Components_Style_Override
    .Cell_Value {
      padding: 12px;

      .autocomplete-wrapper,
      .text-field-wrapper {
        height: 18px;
      }
      .MuiInputBase-root {
        min-height: 20px !important;
      }
      .MuiFormControl-root {
        margin: 0px !important;
        padding: 0px !important;
        margin-bottom: 0px !important;
      }

      .checkbox-wrapper {
        &.Mui-checked,
        &.MuiCheckbox-indeterminate {
          .mdi {
            background-color: var(--colors-background-brand-solid) !important;
          }
        }

        .mdi {
          height: 20px;
          width: 20px;
          border-radius: 6px !important;
        }
        .i-unchecked {
          height: 20px;
          width: 20px;
          border: 1px solid var(--colors-border-border-disabled);
          border-radius: 6px !important;
          background-color: transparent;
        }
      }
      &.theme-default {
        .checkbox-wrapper {
          .i-unchecked {
            border-color: var(--colors-background-brand-solid);
          }
          &.Mui-checked,
          &.MuiCheckbox-indeterminate {
            .mdi {
              border-radius: 6px !important;
              background-color: var(--colors-background-brand-solid);
            }
          }
        }
      }

      .radio-group-wrapper {
        .MuiFormControlLabel-label {
          font-size: 14px;
          color: var(--colors-text-secondary) !important;

          @media (max-width: 425px) {
            font-size: 10px;
          }
        }
        &.Mui-checked {
          .radio-icon,
          + .MuiTypography-root {
            color: var(--colors-background-brand-solid);
          }
        }
        .Mui-checked .radio-icon {
          border-color: var(--colors-background-brand-solid) !important;
          color: var(--colors-background-brand-solid) !important;
        }
      }

      .checkbox-groups-wrapper .MuiFormControlLabel-label {
        font-size: 16px !important;
        padding: 4px;
        color: var(--colors-foreground-fg-secondary-700);

        @media (max-width: 425px) {
          font-size: 12px;
        }
      }
    }

    .signature_input {
      border: none;
      position: relative;
      color: #8c8c8c;
      background-color: #f2f4f7;
      font-weight: 400;
    }

    .signature_input::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 0.717px solid transparent;
      border-image: repeating-linear-gradient(
        140deg,
        #98a2b3 0,
        #98a2b3 5px,
        transparent 5px,
        transparent 10px
      );
      border-image-slice: 1;
      pointer-events: none;
    }
  }

  .tow-col-table {
    td {
      padding: 16px 20px;
    }
  }

  .MandatoryInformation {
    .Row_Text {
      p {
        font-size: 18px;
        font-weight: 700;

        @media (max-width: 425px) {
          font-size: 14px;
        }
      }

      li {
        font-weight: 400;
        font-size: 16px;

        @media (max-width: 425px) {
          font-size: 12px;
        }
      }
    }
  }

  .Confirmation,
  .ForOfficeUseOnly {
    .Row_Text {
      p {
        font-size: 16px;
        font-weight: 400;

        @media (max-width: 425px) {
          font-size: 12px;
        }
      }
    }
  }

  .Gray_Cell_Value {
    background-color: var(--component-colors-utility-gray-utility-gray-100);
    margin: 12px;
    height: 40px;
    border-radius: 8px;
  }

  .individualKycForm {
    td {
      padding: 10px;
    }
  }

  .Dynamic_Table {
    td {
      padding: 0 12px;
    }

    .Cell_Value {
      padding: 12px 0;
    }

    .Gray_Cell_Value {
      margin: 0px;
    }
  }

  .selectedCount {
    color: var(--colors-background-bg-brand-section-subtle);
    font-weight: 600;
    font-size: 16px;

    @media (max-width: 425px) {
      font-size: 12px;
    }
  }

  .add_Record {
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
  }

  .padding_12 {
    padding: 12px !important;
  }
}
