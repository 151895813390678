.About-Unit-Card {

  &.horizantal {
    display: grid;
    grid-template-columns: auto 50%;
    column-gap: 16px;
    height: 230px;

    .thumbnails,
    .unit-inspect {
      height: 100%;
    }

  }


  &.vertical {
    display: grid;
    grid-template-rows: auto 50%;
    width: 300px;
    height: 450px;
    padding-bottom: 32px;
    
    .thumbnails,
    .unit-inspect {
      width: 100%;
    }

    .thumbnails {
      height: 220px;
    }

    .unit-inspect {
      height: 230px;
      margin-block: 16px;
    }

  }

  .unit-inspect {
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    p{
      margin-bottom: 4px;
    }
  }

  .thumbnails {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    .col-2 {
      display: grid;
      grid-template-rows: 1fr 1fr;
      gap: 8px;
    }
  }

  .bg-thumbnail {
    background: rgb(236, 236, 236);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-width: 75px;
    border-radius: 8px;
  }

  .copy-container{
    padding-right: 16px;
    margin-inline: 0px !important;
    overflow: unset;
    align-items: unset;
   
    .icon-container {
      position: unset;
      width: 0;
      height: 0;
    }
  }
}