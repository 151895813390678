.information-open-file-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.3rem;
    color: var(--c-primary);
  } .pending-field{
    color: #FFA500; 
    background-color: #FFF4E5 !important; 
    padding: 5px !important; 
    font-size: 16px !important; 
    border-radius: 8px !important;
  }