.Images_Grid {

  ul.gallery {
    --numcolumns: 4;
    --gap: 0.75em;
    --size: calc(100vw / var(--numcolumns));
    display: grid;
    grid-template-columns: repeat(var(--numcolumns), 1fr);

    grid-template-rows: auto;
    gap: var(--gap);
    align-items: stretch;
    padding: 0;
    list-style: none;
  }

  ul.gallery>li {
    display: block;
    height: calc(var(--size) - var(--gap));
    position: relative;
  }

  ul.gallery>li.wide {
    grid-column: span 2;
  }

  ul.gallery>li.wider {
    grid-column: span 3;
  }

  ul.gallery>li.high {
    grid-row: span 2;
    height: auto;
  }

  ul.gallery>li>a.zoomout {
    display: none;
  }

  ul.gallery>li>a,
  ul.gallery>li>a>img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border: 1px solid #f5f5f5;
    border-radius: 10px;
  }

  ul.gallery>li>a.zoomin:hover::after,
  ul.gallery>li>a.zoomin:focus::after {
    content: '';
    display: block;
    background: rgba(255, 255, 255, 0.2) url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZmlsbD0ibm9uZSIgaGVpZ2h0PSIyNCIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIxMSIgY3k9IjExIiByPSI4Ii8+PGxpbmUgeDE9IjIxIiB4Mj0iMTYuNjUiIHkxPSIyMSIgeTI9IjE2LjY1Ii8+PGxpbmUgeDE9IjExIiB4Mj0iMTEiIHkxPSI4IiB5Mj0iMTQiLz48bGluZSB4MT0iOCIgeDI9IjE0IiB5MT0iMTEiIHkyPSIxMSIvPjwvc3ZnPg==) no-repeat 50% 50%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  ul.gallery>li:focus-within,
  ul.gallery>li:target {
    overflow: visible;
    z-index: 1;
  }

  ul.gallery>li:target {
    z-index: 2;
  }

  ul.gallery>li:target>a.zoomin {
    display: none;
  }

  ul.gallery>li:target>a.zoomout {
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 12;
  }

  ul.gallery>li>a.zoomout>img {
    object-fit: contain;
    padding-right: 10vw;
    padding-left: 15vw;
    padding-top: 8vw;
    padding-bottom: 5vw;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 10000;
    background: rgba(0, 0, 0, 0.8);
  }


  // const styleClassesArray = [
  //   "wide", 1
  //   "basic", 2
  //   "high", 3
  //   "wider", 4
  //   "basic", 5
  //   "basic", 6
  //   "wide", 7
  //   "wide", 8
  //   "basic", 9
  //   "basic", 10
  //   "basic", 11
  //   "basic", 12
  //   "wide", 13
  // ];

  ul li:nth-child(13n + 1),
  ul li:nth-child(13n + 7),
  ul li:nth-child(13n + 8),
  ul li:nth-child(13n + 13) {
    @extend .wide,
  }

  ul li:nth-child(13n + 4) {
    @extend .wider,
  }

  ul li:nth-child(13n + 3) {
    @extend .high,
  }

}