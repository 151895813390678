.doc-file-card{
    border: 1px solid gray;
    border-radius: .2rem;
    padding: 0.5rem;
    width: 200px;
    margin-top: 10px;
    margin-bottom: 10px;
  .doc-image{ 
      width: 50px;
  }
  .doc-info{
      span{
          white-space: nowrap;
          max-width: 115px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          margin-bottom: 5px;
      }
  }
  
  .file-icons-container span{
      cursor: pointer;
  }
  }
