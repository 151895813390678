.contact-merge-view-wrapper {
  padding: 2rem;
  padding-left: 4rem;
  padding-top: 0;
  position: relative;
  @include laptops-max {
    padding-left: 2rem;
  }
  .original-contact-btn {
    cursor: pointer;
    margin-top: 0.4rem;
    display: block;
    background: #e9e9e9;
    border-radius: 50%;
    width: 1.8rem;
    height: 1.8rem;
    box-shadow: 0px 0px 2px 0px rgb(189, 189, 189);

    span:before {
      font-size: $fz-20px;
      margin: 3px 4px;
    }
    .is-original {
      color: #6aad48;
    }
    .is-not-original {
      color: #707070;
    }
  }

  .contacts-merge-content {
    height: calc(100vh - 240px);
    border: 1px solid #aeaeb2;
    overflow: hidden;
    border-radius: 1.3rem;
    .content-header {
      width: 100%;
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid $c-gray-light;
      @include rtl {
        padding-left: 0;
        padding-right: 5rem;
      }
      @include laptops-max {
        padding-left: 2rem;
        padding-right: 2rem;
      }
      .header-item-postion {
        width: 100%;
        display: flex;
        align-items: center;
        padding-right: 8px;
        &.reverse {
          flex-direction: row-reverse;
        }
      }
      .header-item {
        width: 25%;
        height: 100px;
        display: flex;
        align-items: center;
        font-size: $fz-14px;
        padding: 0px 50px;
        border-left: 1px solid $c-gray-light;
        .item-name {
          min-width: 6rem;
          font-weight: bold;
        }
        .details-img {
          width: 4rem;
          height: 4rem;
          border-radius: 11px;
          border: 1px solid $c-gray-light;
          background-color: $c-white;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .obsolete-item {
          color: $c-danger;
          font-weight: bold;
        }
        .final-item {
          color: green;
          font-weight: bold;
        }
        .original-item {
          color: $c-primary;
          font-weight: bold;
        }
        .item-info {
          padding-top: 0.5rem;
          padding-left: 1.5rem;
          padding-right: 1.5rem;
        }
      }
    }
    .content-body {
      position: relative;
      width: 100%;
      height: calc(100vh - 360px);
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      .content-container {
        width: 100%;
        .radio-groups-wrapper.MuiFormControl-root {
          width: 100%;
          background-color: $c-white;
          border-bottom: 0.5px solid $c-gray-light;
        }
        &.is-gray {
          .radio-groups-wrapper.MuiFormControl-root {
            background-color: #fafafa;
          }
        }
        .MuiFormControlLabel-root {
          width: 25%;
          margin: 0;
          min-height: 4rem;
          @include rtl {
            padding-left: 0;
            padding-right: 4rem;
          }
          @include laptops-max {
            padding-left: 0;
            padding-right: 0;
          }
        }
        .MuiTypography-body1 {
          font-size: $fz-14px;
          padding-left: 2rem;
          padding-right: 2rem;
          overflow: hidden;
        }
        .radio-groups-wrapper.MuiFormControl-root
          .radio-group-wrapper
          .radio-wrapper.Mui-checked
          .radio-icon {
          color: $c-primary;
          border-color: $c-primary;
        }
        .radio-groups-wrapper.MuiFormControl-root .radio-group-wrapper .radio-wrapper .radio-icon {
          width: 1rem;
          height: 1rem;
        }
        .radio-groups-wrapper.MuiFormControl-root .radio-group-wrapper {
          width: 100%;
          display: flex;
        }
        &.reverse {
          .radio-groups-wrapper.MuiFormControl-root .radio-group-wrapper {
            flex-direction: row-reverse;
          }
        }
        .radio-groups-wrapper.MuiFormControl-root::before {
          border: 0 solid transparent;
        }
      }

      .radio-groups-wrapper.theme-default.MuiFormControl-root {
        padding: unset;
      }
      .radio-group-wrapper label {
        padding-left: 25px;
      }
      .radio-group-wrapper label:first-child {
        border-right: 2px solid #bdbdbd;
        padding: 0 2rem;
      }
      .radio-group-wrapper label:first-child .radio-wrapper {
        display: none;
      }
      .radio-group-wrapper label:first-child .radio-wrapper {
        display: none;
      }

      .radio-group-wrapper label:nth-child(2) {
        background-color: #f8fdff;
      }
      .radio-group-wrapper label:nth-child(3) {
        background-color: #eefbf5;
      }
      .radio-group-wrapper label:nth-child(4) {
        background-color: #e5f1ed;
      }

      .radio-group-wrapper label:first-child {
        cursor: default;
        color: #4e4e4e;
        font-weight: 600;
        background-color: #dbedf3;
        padding: 7px 12px;
        font-size: $fz-14px;
        line-height: 1.42857143;
        border-radius: 0 8px 8px 0px;
        border-bottom: 3px solid #c2d5dd;
      }
      .radio-group-wrapper label:first-child span:nth-child(2).MuiTypography-body1 {
        cursor: default;
        color: #4e4e4e;
        font-weight: 600;
        background-color: #daf0f7;
        padding: 7px 12px;
        font-size: $fz-14px;
        line-height: 1.42857143;
        border-radius: 4px;
      }
    }
  }
  .header-button-container {
    display: flex;
    justify-content: center;
    height: 35px;
    align-items: center;
    width: 150px;
    margin: auto;
    border-radius: 0 0 1rem 1rem;
    border-top: 0;
    background: #95c8dd;
    box-shadow: 0px 3px 6px #00000036;

    button {
      height: 30px;
      color: white;
      cursor: pointer;
    }
    button:hover {
      background-color: unset;
      color: #d0edf7;
    }

    .mdi:before {
      font-size: $fz-28px !important;
    }
  }
}
.bottom-box-merge {
  display: flex;
  justify-content: center;
  align-items: center;
  .merge-bottom {
    background-color: $c-white;
    border-radius: 20px 20px 0 0;
    box-shadow: 0px -2px 6px #00000029;
    position: fixed;
    bottom: 0;
    padding: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    z-index: 10;
    @include rtl {
      border-radius: 0 0 20px 20px;
    }
    @include laptops-xxl-max {
      width: 80%;
    }
    @include laptops-l-max {
      width: 70%;
    }
    @include laptops-max {
      width: 65%;
    }
    .contacts-merge-bottom {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @include rtl {
        justify-content: flex-start;
      }
    }
  }
}
