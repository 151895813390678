.templates-cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  --foundation-orangeorange-50: rgba(252, 238, 234, 1);
  --foundation-orangeorange-100: rgba(245, 204, 189, 1);
  --foundation-orangeorange-200: rgba(241, 179, 157, 1);
  --foundation-orangeorange-300: rgba(234, 144, 112, 1);
  --foundation-orangeorange-400: rgba(230, 122, 85, 1);
  --foundation-orangeorange-500: rgba(224, 89, 42, 1);
  --foundation-orangeorange-600: rgba(204, 81, 38, 1);
  --foundation-orangeorange-700: rgba(159, 63, 30, 1);
  --foundation-orangeorange-800: rgba(123, 49, 23, 1);
  --foundation-orangeorange-900: rgba(94, 37, 18, 1);
  --foundation-blueblue-50: rgba(235, 241, 251, 1);
  --foundation-bluelight: rgba(235, 241, 251, 1);
  --foundation-blueblue-100: rgba(194, 211, 243, 1);
  --foundation-bluelight-hover: rgba(226, 234, 249, 1);
  --foundation-blueblue-200: rgba(165, 190, 238, 1);
  --foundation-bluelight-active: rgba(194, 211, 243, 1);
  --foundation-blueblue-300: rgba(124, 161, 230, 1);
  --foundation-bluenormal: rgba(59, 114, 217, 1);
  --foundation-blueblue-400: rgba(98, 142, 225, 1);
  --foundation-bluenormal-hover: rgba(53, 103, 195, 1);
  --foundation-blueblue-500: rgba(59, 114, 217, 1);
  --foundation-bluenormal-active: rgba(47, 91, 174, 1);
  --foundation-blueblue-600: rgba(54, 104, 197, 1);
  --foundation-bluedark: rgba(44, 86, 163, 1);
  --foundation-blueblue-700: rgba(42, 81, 154, 1);
  --foundation-bluedark-hover: rgba(35, 68, 130, 1);
  --foundation-blueblue-800: rgba(32, 63, 119, 1);
  --foundation-bluedark-active: rgba(27, 51, 98, 1);
  --foundation-blueblue-900: rgba(25, 48, 91, 1);
  --foundation-bluedarker: rgba(21, 40, 76, 1);
  --gray-100: rgba(242, 244, 247, 1);
  --gray-900: rgba(16, 24, 40, 1);
  --blue-700: rgba(23, 92, 211, 1);
  --blue-50: rgba(239, 248, 255, 1);
  --gray-600: rgba(71, 84, 103, 1);
  --gray-200: rgba(234, 236, 240, 1);
  --basewhite: rgba(255, 255, 255, 1);
  --gray-700: rgba(52, 64, 84, 1);
  --primary-700: rgba(105, 65, 198, 1);
  --primary-50: rgba(249, 245, 255, 1);
  --primary-600: rgba(127, 86, 217, 1);
  --warning-700: rgba(181, 71, 8, 1);
  --warning-50: rgba(255, 250, 235, 1);
  --blue-100: rgba(209, 233, 255, 1);
  --warning-600: rgba(220, 104, 3, 1);
  --success-700: rgba(2, 122, 72, 1);
  --success-50: rgba(236, 253, 243, 1);
  --success-600: rgba(3, 152, 85, 1);
  --gray-blue700: rgba(54, 63, 114, 1);
  --gray-blue50: rgba(248, 249, 252, 1);
  --gray-blue600: rgba(62, 71, 132, 1);
  --blue-light700: rgba(2, 106, 162, 1);
  --blue-light50: rgba(240, 249, 255, 1);
  --blue-light600: rgba(0, 134, 201, 1);
  --indigo-700: rgba(53, 56, 205, 1);
  --indigo-50: rgba(238, 244, 255, 1);
  --indigo-600: rgba(68, 76, 231, 1);
  --purple-700: rgba(89, 37, 220, 1);
  --purple-50: rgba(244, 243, 255, 1);
  --purple-600: rgba(105, 56, 239, 1);
  --blue-600: rgba(21, 112, 239, 1);
  --pink-700: rgba(193, 21, 116, 1);
  --pink-50: rgba(253, 242, 250, 1);
  --pink-600: rgba(221, 37, 144, 1);
  --ros-700: rgba(192, 16, 72, 1);
  --ros-50: rgba(255, 241, 243, 1);
  --ros-600: rgba(227, 27, 84, 1);
  --orange-700: rgba(185, 56, 21, 1);
  --orange-dark-50: rgba(255, 244, 237, 1);
  --orange-dark-600: rgba(230, 46, 5, 1);
  --error-700: rgba(180, 35, 24, 1);
  --blue-100: hsba(209, 18%, 100%, 1);
  --error-50: rgba(254, 243, 242, 1);
  --error-600: rgba(217, 45, 32, 1);
  --primary-500: rgba(158, 119, 237, 1);
  --warning-500: rgba(247, 144, 9, 1);
  --success-500: rgba(18, 183, 106, 1);
  --gray-blue500: rgba(78, 91, 166, 1);
  --blue-light500: rgba(11, 165, 236, 1);
  --indigo-500: rgba(97, 114, 243, 1);
  --purple-500: rgba(122, 90, 248, 1);
  --blue-500: rgba(46, 144, 250, 1);
  --pink-500: rgba(238, 70, 188, 1);
  --ros-500: rgba(246, 61, 104, 1);
  --orange-500: rgba(239, 104, 32, 1);
  --error-500: rgba(240, 68, 56, 1);
  --gray-500: rgba(102, 112, 133, 1);
  --primary-100: rgba(244, 235, 255, 1);
  --primary-800: rgba(83, 56, 158, 1);
  --gray-50: rgba(249, 250, 251, 1);
  --error-100: rgba(254, 228, 226, 1);
  --warning-100: rgba(254, 240, 199, 1);
  --success-100: rgba(209, 250, 223, 1);
  --text-lg-medium-font-family: 'Inter-Medium', Helvetica;
  --text-lg-medium-font-weight: 500;
  --text-lg-medium-font-size: 18px;
  --text-lg-medium-letter-spacing: 0px;
  --text-lg-medium-line-height: 28px;
  --text-lg-medium-font-style: normal;
  --text-xs-medium-font-family: 'Roboto-Medium', Helvetica;
  --text-xs-medium-font-weight: 500;
  --text-xs-medium-font-size: 12px;
  --text-xs-medium-letter-spacing: 0px;
  --text-xs-medium-line-height: 18px;
  --text-xs-medium-font-style: normal;
  --text-sm-regular-font-family: 'Inter-Regular', Helvetica;
  --text-sm-regular-font-weight: 400;
  --text-sm-regular-font-size: 14px;
  --text-sm-regular-letter-spacing: 0px;
  --text-sm-regular-line-height: 20px;
  --text-sm-regular-font-style: normal;
  --text-sm-medium-font-family: 'Inter-Medium', Helvetica;
  --text-sm-medium-font-weight: 500;
  --text-sm-medium-font-size: 14px;
  --text-sm-medium-letter-spacing: 0px;
  --text-sm-medium-line-height: 20px;
  --text-sm-medium-font-style: normal;
  --shadow-lg: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  --shadow-xs: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  --background-blur-md: ;

  button:focus-visible {
    outline: 2px solid #4a90e2 !important;
    outline: -webkit-focus-ring-color auto 5px !important;
  }
  a {
    text-decoration: none;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    width: 288.6px;
    align-items: flex-start;
    grid-gap: 7px;
    gap: 7px;
    justify-content: center;
    position: relative;
  }

  .container .metric-group {
    max-width: 288.6px;
    min-width: 288.6px;
    display: flex;
    align-items: center;
    gap: 24px;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
  }

  .container .metric-item {
    position: relative;
    flex: 1;
    flex-grow: 1;
    height: 148px;
    background-color: var(--basewhite);
    border-radius: 12px;
    border: 1px solid;
    border-color: var(--gray-200);
  }

  .container .templates-cards-overlap-group {
    position: absolute;
    width: 290px;
    height: 56px;
    top: 16px;
    left: 0;
  }

  .container .heading-and-icon {
    display: flex;
    width: 290px;
    align-items: flex-start;
    gap: 20px;
    padding: 0px 16px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .container .featured-icon {
    position: relative;
    width: 56px;
    height: 56px;

    background-color: #d1e9ff;
    border-radius: 12px;
    &.html {
      background-color: #ebe9fe !important;
    }
  }

  .container .type {
    position: absolute;
    width: 28px;
    height: 28px;
    top: 14px;
    left: 14px;
  }

  .container .frame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
    position: relative;
    flex: 1;
    flex-grow: 1;
  }

  .container .heading {
    width: 152px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    font-weight: var(--text-lg-medium-font-weight);
    color: var(--gray-900);
    font-size: 14px;
    letter-spacing: var(--text-lg-medium-letter-spacing);
    line-height: var(--text-lg-medium-line-height);
    font-style: var(--text-lg-medium-font-style);
  }

  .container .table-cell {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
  }

  .container .badge {
    display: inline-flex;
    align-items: center;
    padding: 2px 8px;
    position: relative;
    flex: 0 0 auto;
    background-color: var(--blue-50);
    border-radius: 16px;
    &.unit {
      background-color: #fdf2fa;
    }
  }

  .container .text {
    position: relative;
    width: fit-content;
    font-family: var(--text-xs-medium-font-family);
    font-weight: var(--text-xs-medium-font-weight);
    color: var(--blue-700);
    font-size: var(--text-xs-medium-font-size);
    text-align: center;
    letter-spacing: var(--text-xs-medium-letter-spacing);
    line-height: var(--text-xs-medium-line-height);
    white-space: nowrap;
    font-style: var(--text-xs-medium-font-style);
    &.unit {
      color: #fdf2fa;
    }
  }

  .container .dropdown {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 0;
    left: 254px;

    @include rtl {
      right: 254px;
      left: 0;
    }
  }

  .container .dropdown-inquiries {
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    flex: 0 0 auto;
  }

  .container .dots-vertical {
    position: relative;
    width: 20px;
    height: 20px;
  }

  .container .actions {
    display: flex;
    width: 287px;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    padding: 14px 6px 0px;
    position: absolute;
    top: 96px;
    left: 0;
    border-top-width: 1px;
    border-top-style: solid;
    border-color: var(--gray-200);
  }

  .container .location-wrap {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    position: relative;
    flex: 0 0 auto;
  }

  .container .calendar {
    position: relative;
    width: 16px;
    height: 16px;
    background-image: url(https://c.animaapp.com/xfXGoreo/img/calendar.svg);
    background-size: 100% 100%;
  }

  .container .location {
    position: relative;
    width: fit-content;
    margin-top: -1px;
    font-weight: var(--text-sm-regular-font-weight);
    color: var(--gray-600);
    font-size: 10px;
    letter-spacing: var(--text-sm-regular-letter-spacing);
    line-height: var(--text-sm-regular-line-height);
    white-space: nowrap;
    font-style: var(--text-sm-regular-font-style);
  }

  .container .calendar-check {
    position: relative;
    width: 16px;
    height: 16px;
  }
}

.Popap-Option {
  display: inline-block;
  width: 100%;
  max-width: 156px;
  justify-content: center;
  font-size: 15px;
  padding: 0.3rem;
  margin: 0px;
  .Option {
    display: inline-flex;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
    border-radius: 8px;
    justify-content: space-between;
  }

  .Option:hover {
    background-color: rgb(188, 220, 249) !important;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-weight: 900;
    text-decoration: underline;
    .mdi {
      color: #0bace7;
    }
  }
}
