.badge {
  display: inline-flex;
  align-items: center;
  border-radius: 16px;
  padding: 2px 8px;
  height: 32px;
  font-weight: 500;
  font-size: 14px;
  cursor: default;
  background-color: #e0e0e0;
  color: #424242;
  border: 1px solid transparent;

  &.outlined {
    border-color: #424242;
    background-color: transparent;
  }

  &.small {
    height: 22px;
    animation: none !important;
    width: auto;
    font-size: 12px;
    padding: 2px 8px;

    &.leftIcon {
      padding: 2px 8px 2px 6px;
    }

    &.deleteIcon {
      padding: 2px 3px 2px 8px;
    }

    &.rightIcon {
      padding: 2px 6px 2px 8px;
    }

    &.icon {
      padding: 5px;
    }

    &.avatar {
      gap: 4px;
      padding: 2px 8px 2px 3px;
    }
  }

  &.medium {
    height: 24px;
    animation: none !important;
    width: auto;
    font-size: 14px;
    padding: 2px 10px;
    gap: 4px;

    &.leftIcon {
      padding: 2px 10px 2px 8px;
    }

    &.deleteIcon {
      padding: 2px 8px 2px 10px;
    }

    &.rightIcon {
      padding: 2px 8px 2px 10px;
    }

    &.icon {
      padding: 6px;
    }

    &.avatar {
      gap: 6px;
      padding: 2px 10px 2px 4px;
    }

    &.filter {
      gap: 5px;
      padding: 7px 6px 7px 12px;
      line-height: 24px;
      font-size: 14px;
      border-radius: 50px;
      height: auto !important;
    }
  }

  &.large {
    height: 28px;
    animation: none !important;
    width: auto;
    font-size: 14px;
    gap: 4px;
    padding: 4px 12px;

    &.leftIcon {
      padding: 4px 12px 4px 10px;
    }

    &.deleteIcon {
      padding: 4px 6px 4px 12px;
    }

    &.rightIcon {
      padding: 4px 10px 4px 12px;
    }

    &.icon {
      padding: 8px;
    }

    &.avatar {
      gap: 6px;
      padding: 4px 12px 4px 6px;
    }
  }

  .badge-avatar {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .badge-icon {
    align-items: center;
    display: flex;
    // width: 12px;
    // height: 12px;
  }

  .badge-delete-icon {
    display: flex;
    width: 12px;
    height: 12px;
    cursor: pointer;
    &:hover {
      color: red;
    }
  }

  .filterBadgeText {
    display: flex;
    gap: 6px;
    justify-content: center;
    flex-flow: wrap;
  }
}
